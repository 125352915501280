<template>
  <ui-modal class="modal-video" :show="modal_video_show">
    <div class="modal-mask" @click="closeVideoModal"></div>
    <div class="video-container">
      <xd-video
        ref="xdvideo"
        :options="modal_video_options"
        @after-create="handleCreated"
      />
    </div>
  </ui-modal>
</template>

<script>
import UiModal from "@/components/ui-modal.vue";
import XdVideo from "@/components/xdvideo.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ModalVideo",
  components: { UiModal, XdVideo },
  computed: {
    ...mapState(["modal_video_options", "modal_video_show", "modal_video_tip"]),
  },
  methods: {
    ...mapMutations(["toggleModal"]),
    closeVideoModal() {
      let htmlStyle = document.getElementsByTagName('html')[0].style;
      htmlStyle.overflow = '';

      this.toggleModal({
        key: "modal_video_show",
        bool: false,
      });

      if (this.modal_video_tip == "pc_banner") {
        window["banner_video"] && window["banner_video"].play();
      }
    },
    handleCreated(player) {
      const playPromise = player.play();
      if (playPromise !== null) {
        playPromise.catch(() => {
          player.play();
        });
      }

      let htmlStyle = document.getElementsByTagName('html')[0].style;
      htmlStyle.overflow = 'hidden';
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-video {
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video-container {
    width: 828px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 750px) {
.modal-video {
  .video-container {
    width: 100%;
  }
}
}
</style>