<template>
  <ul v-if="navsValid.length != 0" class="list-nav">
    <li
      v-for="(nav, i) in navsValid"
      :key="i"
      :class="{ active: nav.id == subNav, isHide: !$t(`nav_${nav.id}`) || nav.hide }"
      @click="jumpTo(nav)"
    >
      {{ $t(`nav_${nav.id}`) }}
      <a v-if="nav.link" :href="getHref(nav)" target="_blank"></a>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { localeMap } from '@/utils/constant';

export default {
  name: "ListNav",
  props: {
    navs: {
      type: Array,
      default: () => []
    },
    navID: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      subNav: "",
      storeMap: localeMap,
      wikiMap: {
        "en": "en",
        "zh-cn": "cn",
        "zh-tw": "tw",
        "kr": "ko",
        "jp": "ja",
        "ru": "ru",
        "de": "de",
        "es": "es",
        "fr": "fr",
        "id": "id",
        "pt": "pt",
        "th": "th",
        "vn": "vi"
      }
    };
  },
  watch: {
    navsValid: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal.length != 0) {
          this.subNav = this.$lg(newVal[0], "id");
        }
      },
      deep: true,
      immediate: true,
    },
    navID(newVal, oldVal) {
      if(newVal != oldVal) {
        this.subNav = newVal
      }
    }
  },
  computed: {
    ...mapState([ "seasonMap"]),
    navsValid() {
      return this.navs.filter((nav) => nav.name) || [];
    },
  },
  methods: {
    jumpTo(nav) {
      if (nav.link) return;

      this.subNav = nav.id;
      window.scrollTo({
        top: nav.fromTop,
        behavior: "smooth",
      });
    },
    getHref(nav) {
      if(nav.id == 'store') {
        const lang = this.$i18n.locale;
        return `${nav.link}?lang=${this.storeMap[lang] || 'en_US'}`
      }else if(nav.id == 'season') {
        const preLink = 'https://torchlight.xd.com';
        const lang = this.$i18n.locale;
        return `${preLink}${this.seasonMap[lang] || this.seasonMap['en']}`;
      }else if(nav.id == 'wiki') {
        const preLink = 'https://tlidb.com';
        const lang = this.$i18n.locale;
        return `${preLink}/${this.wikiMap[lang] || 'en'}/`;
      }else if(nav.id == 'home') {
        const preLink = 'https://torchlight.xd.com';
        const lang = this.$i18n.locale;
        return `${preLink}/${lang}/`;
      }else if(nav.id == 'news') {
        const preLink = 'https://torchlight.xd.com';
        const lang = this.$i18n.locale;
        return `${preLink}/${lang}/news/list/`;
      }else {
        return nav.link;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hover-color-text: #ffffff;

.list-nav {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: #ababab;
  height: 100%;
  font-weight: 700;
  // font-style: italic;
  li {
    padding: 0 0.10417vw;
    height: 100%;
    line-height: 3.125vw;
    margin: 0 vwPc(15px);
    text-align: center;
    color: #ababab;
    letter-spacing: 0.1px;
    transition: all ease-in-out 200ms;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    a {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #ffb833;
      position: absolute;
      left: 0;
      bottom: 0.625vw;
      opacity: 0;
      transition: all ease-in-out 200ms;
    }

    &:hover {
      color: $hover-color-text;
    }
    &.active {
      color: #ffb833;
      &::before {
        opacity: 1;
      }
    }
    &.isHide {
      display: none;
    }
  }
}

@media screen and (max-width: 750px) {
  .list-nav {
    color: #ababab;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    padding-right: vwApp(40px);
    li {
      height: vwApp(64px);
      line-height: vwApp(64px);
      text-align: right;
      &::before {
        display: none;
      }
    }
  }
}
</style>