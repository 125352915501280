<template>
  <ui-button-slide
    v-if="mainBtn.show || behavior == 'jump'"
    :type="type"
    :size="size"
    @click.native="operate"
  >
    <template v-if="mainBtn.type == 'register'">
      <template v-if="loginInfo !== null && registerInfo !== null">
        {{ $t("sign_up_success") }}
      </template>
      <template v-else>{{ $t("btn_register") }}</template>
      <a
        v-if="mainBtn.link && behavior != 'jump'"
        :href="mainBtn.link"
        target="_blank"
        @click="countGa(mainBtn.store)"
      ></a>
    </template>
    <template v-if="mainBtn.type == 'download'">
      {{ $t("btn_download") }}
      <a
        v-if="mainBtn.link && behavior != 'jump'"
        :href="mainBtn.link"
        target="_blank"
        download
        @click="countGa(mainBtn.store)"
      ></a>
    </template>
  </ui-button-slide>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import { os } from "@/assets/libs/os.js";
import UiButtonSlide from "@/components/ui-button-slide.vue";

export default {
  name: "BtnEntry",
  components: { UiButtonSlide },
  props: {
    behavior: {
      typeof: String,
      default: () => "normal", // normal 正常预约按钮 jump 跳转register按钮
    },
    type: {
      type: String,
      default: () => "normal", // normal 橙色, main 渐变橙色
    },
    size: {
      type: String,
      default: () => "normal", // normal 正常大小, mini 导航栏小按钮
    },
  },
  data() {
    return {
      os: os,
      label: "",
    };
  },
  computed: {
    ...mapState(["loginInfo", "registerInfo", "navs"]),
    ...mapGetters(["stage", "mainBtn"]),
  },
  methods: {
    ...mapMutations(["setRegisterShow", "setInTargetCountry"]),
    handleRegister() {
      if (this.loginInfo === null || this.registerInfo === null) {
        this.setRegisterShow(true);

        // this.countGa("signUp");
      }
    },
    operate() {
      if (this.behavior == "jump") {
        const navRegister = this.navs.find((item) => item.id == "register");
        window.scrollTo({
          top: navRegister.fromTop,
          behavior: "smooth",
        });

        return;
      } else {
        if (this.mainBtn.type == "register" && !this.mainBtn.link) {
          this.handleRegister();
        } else if (
          this.mainBtn.link &&
          this.mainBtn.store == "AppStore" &&
          os.isWeChart
        ) {
          alert(this.$t("tip_WeChart"));
        }
      }
    },
    countGa(type) {
      window.ga("send", "event", "torchlight", "click", type);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}
</style>