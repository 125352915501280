<template>
  <transition name="fadeOut">
    <div v-if="show" class="media__container" :style="style">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UiModal",
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      style: {},
    };
  },
  computed: {
    ...mapState(["data_size_listen"]),
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.resetHeight();
        }
      },
    },
    data_size_listen: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && this.show) {
          this.resetHeight();
        }
      },
      deep: true,
    },
  },
  methods: {
    resetHeight() {
      this.style = { height: `${window.innerHeight}px` };
    },
  },
};
</script>

<style lang="scss" scoped>
.fadeOut-enter-active {
  transition: opacity 0.1s linear;
}
.fadeOut-leave-active {
  transition: opacity 0.3s linear;
}
.fadeOut-enter,
.fadeOut-leave-to {
  opacity: 0;
}

.media__container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 60;
}
</style>