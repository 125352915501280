const languages = {
  'zh-cn': { name: "简体中文", code: "zh_CN", region: "简体中文" },
  'zh-tw': { name: "繁體中文", code: "zh_TW", region: "繁体中文" },
  en: { name: "English", code: "en_WW", region: "English" },
  // fi: { name: "suomi", code: "fi_FI", region: "芬兰语" },
  // da: { name: "dansk", code: "da_DK", region: "丹麦语" },
  // he: { name: "עִבְרִית", code: "he_IL", region: "希伯来语" },
  kr: { name: "한국어", code: "ko_KR", region: "韩文" },
  jp: { name: "日本語", code: "ja_JP", region: "日语" },
  // nl: { name: "Nederlands", code: "nl_NL", region: "荷兰语" },
  pt: { name: "Portugal", code: "pt_PT", region: "葡萄牙语" },
  fr: { name: "Français", code: "fr_FR", region: "法语" },
  es: { name: "español", code: "es_ES", region: "西班牙语" },
  de: { name: "Deutsch", code: "de_DE", region: "德语" },
  ru: { name: "Русский", code: "ru_RU", region: "俄语" },
  // it: { name: "Italiano", code: "it_IT", region: "意大利语" },
  // el: { name: "Ελληνικά", code: "el_GR", region: "希腊语" },
  // nb: { name: "norsk", code: "nb_NO", region: "挪威语" },
  // hu: { name: "magyar", code: "hu_HU", region: "匈牙利语" },
  // tr: { name: "Türkçe", code: "tr_TR", region: "土耳其语" },
  // cs: { name: "čeština", code: "cs_CZ", region: "捷克语" },
  // sl: { name: "slovenščina", code: "sl_SL", region: "斯洛文尼亚语" },
  // pl: { name: "język polski", code: "pl_PL", region: "波兰语" },
  // sv: { name: "svenska", code: "sv_SE", region: "瑞典语" },
  vn: { name: "Tiếng Việt", code: "vi_VN", region: "越南语" },
  th: { name: "ภาษาไทย", code: "th_TH", region: "泰语" },
  id: { name: "Bahasa", code: "id_ID", region: "印尼语" },
  // ar: { name: "اَلْعَرَبِيَّةُ", code: "ar", region: "阿拉伯语" },
}

export default languages;