<template>
  <div id="single-container">
    <div class="bg"></div>
    <the-header-demestic site="news">
      <template v-slot:nav>
        <list-nav :options="navOptions" />
      </template>
    </the-header-demestic>
    <div class="wrapper">
      <div class="article" v-html="article"></div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/index';
import { getRegion } from "@/utils/getLocale";
import TheHeaderDemestic from "@/components/domestic/the-header.vue";
import TheFooter from "@/components/the-footer.vue";
import TheFooterTW from "@/components/the-footer-tw.vue";
import ListNav from "@/components/domestic/list-nav.vue";
// import _ajax from '@/api/ajax';


export default {
  components: {
    TheHeaderDemestic,
    TheFooter,
    TheFooterTW,
    ListNav
  },
  data() {
    return {
      domain: 'https://xd-website.oss-accelerate-overseas.aliyuncs.com',
      region: getRegion(),
      article: '',
      tempLang: '',
      supportLocales: [],
      navOptions: {
        navs: [],
        defaultID: 'news'
      }
    }
  },
  created() {
    this.init();
    this.initNav();
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
    init() {
      let id = this.$route.query?.id || '';
      if(!id) return;

      this.reqArticle(id);
    },
    async reqArticle(id) {
      this.article = 'loading...';
      let res = await api.getNewSingleCn({
        id,
        t: new Date().getTime()
      });
      if(res.status == 200) {
        this.article = this.$lg(res, 'data.content', '');
        this.supportLocales = this.$lg(res, 'data.locales', []);
        localStorage.setItem(`${id}-locales`, JSON.stringify(this.supportLocales));
      }else {
        this.article = this.$t('tip_news_not_found');
      }
    },
    initNav() {
      let navs = [
        { id: "home", name: "HOME", fromTop: 0, link: '/' },
        { id: "news", name: "NEWS", fromTop: 0, link: '/news/list' }
      ]
      this.navOptions['navs'] = navs;
    }
  }
}
</script>

<style lang="scss" scoped>
#single-container {
  position: relative;
  width: 100%;
  background-color: #071D3F;
  overflow: hidden;
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include section_bg_single("overseas_pc_bg");
    width: 100vw;
    height: 100vh;
  }
  .wrapper {
    position: relative;
    width: 1100px;
    min-height: 100vh;
    padding: 40px;
    background: rgba(20, 72, 129, 0.5);
    border: vwPc(4px) solid #59AEE7;
    margin: 6vw auto;
    box-sizing: border-box;
    .article {
      width: 100%;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #e0dede;

      div {
        line-height: 24px;
      }
      ::v-deep table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        tr {
          th, td {
            border:1px solid #484849;
            padding: 5px 0;
            text-align: center;
          }
        }
      }

      ::v-deep a {
        text-decoration: underline;
        color: #ff6600;
      }
      ::v-deep p {
        line-height: 24px;
        margin: 5px 0;
      }
      ::v-deep li {
        margin-bottom: 10px;
      }
      ::v-deep ul {
        padding-left: 20px;
        li {
          list-style: disc;
        }
      }
      ::v-deep ol {
        padding-left: 20px;
        li {
          list-style: decimal;
        }
      }
      ::v-deep img {
        max-width: 100%;
      }
      ::v-deep iframe {
        max-width: 100%;
        max-height: 47.8vw;
      }
    }
  }
}

@media screen and (max-width: 1120px) {  
  #single-container {
    .wrapper {
      width: 95%;
      padding: 4vw 5vw;
      .article {
        min-height: 110vh;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  #single-container {
    .bg {
      @include section_bg_single("overseas_mobile_bg");
      width: 100vw;
      height: 100vh;
    }
    .wrapper {
      margin: 13vw auto 6vw;
    }
  }
}
</style>