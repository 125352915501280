<template>
  <listen-distance-from-top nav="roles" :class="`in-${screen}`">
    <component
      v-if="thisComponent"
      v-bind:is="thisComponent"
      :heroes="heroes"
      :subHeroName="subHeroName"
      :subHeroID="subHeroID"
      @switch-hero="switchHero"
      @init-transitions-end="initTransitionsEnd"
    ></component>
  </listen-distance-from-top>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ListenDistanceFromTop from "@/components/listen-distance-from-top.vue";

export default {
  name: "SectionRoles",
  components: {
    ListenDistanceFromTop,
    BlockPc: () => import("./roles/roles-pc"),
    BlockApp: () => import("./roles/roles-app"),
  },
  data() {
    return {
      screen: "",
      videoList: [],
      subHeroName: 'erika',
      subHeroID: 'erika_1',
      transitions: {},
      heroes: [
        {
          name: 'erika',
          name_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/erika/bg/name_white.png',
          subHeroID: 'erika_1',
          specialty: [
            {
              id: 'erika_1',
              lie: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/erika/role.png',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/erika/role.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/erika/bg/role.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/erika/bg_role.png',
              spine: {
                appendEleID: "erikaSpine",
                size: {
                  width: 1400,
                  height: 970,
                },
                jsonUrl:
                  "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Erika/maoyan11.json",
                position: {
                  x: 700,
                  y: 1040,
                },
                scale: 1.2,
                animation: "idle",
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/erika/skill/1.png',
              skill: 'erika_skill_1',
              video: 'https://website.xdcdn.net/poster/227017/epicycle/ep-2/hero_erika.mp4',
              switch_btn_default: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/default/erika.png',
              switch_btn_active: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/active/erika.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/default/erika.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/active/erika.png'
            }
          ]
        },
        {
          name: 'thea',
          name_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/thea/bg/name_white.png',
          subHeroID: 'thea_1',
          specialty: [
            {
              id: 'thea_1',
              lie: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/thea/role.png',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/thea/role.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_bg_thea.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/thea/bg_role.png',
              spine: {
                appendEleID: "theaSpine",
                size: {
                  width: 1400,
                  height: 970,
                },
                jsonUrl:
                  "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Thea/shenyuzhe1.json",
                position: {
                  x: 700,
                  y: 1400,
                },
                scale: 1.1,
                animation: "idle",
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/thea/skill/1.png',
              skill: 'thea_skill_1',
              video: 'https://video-pt.xdcdn.net/video/2022/10/12/f392cb75887c1b2b7a10244dd6756645/3f1767b79c6f4f01-1080p.mp4',
              switch_btn_default: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/default/thea.png',
              switch_btn_active: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/active/thea.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/default/thea.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/active/thea.png'
            }
          ]
        },
        {
          name: 'moto',
          name_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/moto/bg/name_white.png',
          subHeroID: 'moto_1',
          specialty: [
            {
              id: 'moto_1',
              lie: '',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/moto/role.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_bg_moto.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/moto/bg_role.png',
              spine: {
                appendEleID: "motoSpine",
                size: {
                  width: 1400,
                  height: 970,
                },
                jsonUrl: "https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/spine/Moto/airen.json",
                position: {
                  x: 700,
                  y: 1040,
                },
                scale: 1.13,
                animation: "idle"
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/moto/skill/1.png',
              skill: 'moto_skill_1',
              video: 'https://video-pt.xdcdn.net/video/2022/04/12/62569070e15aec95bc6d5dd95c6845a9/fb4b29f4f6e346e8-1080p.mp4',
              switch_btn_default: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/default/moto.png',
              switch_btn_active: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/active/moto.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/default/moto.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/active/moto.png'
            }, {
              id: 'moto_2',
              lie: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/moto/role.png',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_moto_2.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_bg_moto_2.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_bg_moto_2.png',
              spine: {
                appendEleID: "moto2Spine",
                size: {
                  width: 1400,
                  height: 970,
                },
                jsonUrl:
                  "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Moto/Moto02.json",
                position: {
                  x: 650,
                  y: 1060,
                },
                scale: 1.4,
                animation: "IdleAnim_Loop",
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/skill_moto_2.png',
              skill: 'moto_skill_2',
              video: 'https://website.xdcdn.net/poster/227017/epicycle/ep-2/hero_moto.mp4',
              switch_btn_default: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_default_moto_2.png',
              switch_btn_active: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_active_moto_2.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_default_moto_2.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_active_moto_2.png'
            }
          ]
        },
        {
          name: 'carino',
          name_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/carino/bg/name_white.png',
          subHeroID: 'carino_1',
          specialty: [
            {
              id: 'carino_1',
              lie: '',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/carino/role.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/carino/bg/role.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/carino/bg_role.png',
              spine: {
                appendEleID: "carinoSpine",
                size: {
                  width: 1600,
                  height: 970,
                },
                jsonUrl: "https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/spine/Carino/Carino.json",
                position: {
                  x: 1080,
                  y: 1270,
                },
                scale: 0.97,
                animation: "CarinoAnim_alone"
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/carino/skill/2.png',
              skill: 'carino_skill_2',
              video: 'https://video-pt.xdcdn.net/video/2021/12/14/0566781c0863dd26f43e67202d096f23/8dd70929356a4a86-1080p.mp4',
              switch_btn_default: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/default/carino.png',
              switch_btn_active: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/active/carino.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/default/carino.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/active/carino.png'
            }, {
              id: 'carino_2',
              lie: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_carino_2.png',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_carino_2.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_bg_carino_2.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_bg_carino_2.png',
              spine: {
                appendEleID: "carino2Spine",
                size: {
                  width: 1600,
                  height: 970,
                },
                jsonUrl:
                  "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Carino/UI_Spine_Carino03.json",
                position: {
                  x: 520,
                  y: 1260,
                },
                scale: 1.15,
                animation: "IdleAnim_Loop",
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/carino/skill/1.png',
              skill: 'carino_skill_1',
              video: 'https://video-pt.xdcdn.net/video/2022/10/12/4a3b92d6f91eb5c3eac8ecfaa39c7bee/c28579b2bae854e6-1080p.mp4',
              switch_btn_default: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_default_carino_2.png',
              switch_btn_active: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_active_carino_2.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_default_carino_2.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_active_carino_2.png'
            }
          ]
        },
        {
          name: 'rehan',
          name_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/rehan/bg/name_white.png',
          subHeroID: 'rehan_1',
          specialty: [
            {
              id: 'rehan_1',
              lie: '',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/rehan/role.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/rehan/bg/role.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/rehan/bg_role.png',
              spine: {
                appendEleID: "rehanSpine",
                size: {
                  width: 1300,
                  height: 970,
                },
                jsonUrl: "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Rehan/skeleton.json",
                position: {
                  x: 540,
                  y: 1000,
                },
                scale: 0.95,
                animation: "RehanAnim_alone"
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/rehan/skill/1.png',
              skill: 'rehan_skill_1',
              video: 'https://video-pt.xdcdn.net/video/2021/12/14/786004cfb5a6064c4decb92edc548e07/106e68c9e2036e88-1080p.mp4',
              switch_btn_default: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/default/rehan.png',
              switch_btn_active: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/active/rehan.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/default/rehan.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/active/rehan.png'
            },
            {
              id: 'rehan_2',
              lie: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/rehan/role.png',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_rehan_2.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_bg_rehan_2.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_bg_rehan_2.png',
              spine: {
                appendEleID: "rehan2Spine",
                size: {
                  width: 1300,
                  height: 970,
                },
                jsonUrl:
                  "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Rehan/Rehan02.json",
                position: {
                  x: 810,
                  y: 1080,
                },
                scale: 1.2,
                animation: "IdleAnim_Loop",
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/rehan/skill/2.png',
              skill: 'rehan_skill_2',
              video: 'https://website.xdcdn.net/poster/227017/epicycle/ep-2/hero_rehan.mp4',
              switch_btn_default: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_default_rehan_2.png',
              switch_btn_active: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_active_rehan_2.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_default_rehan_2.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_active_rehan_2.png'
            }
          ]
        },
        {
          name: 'gemma',
          name_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/gemma/bg/name_white.png',
          subHeroID: 'gemma_1',
          specialty: [
            {
              id: 'gemma_1',
              lie: '',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/gemma/role.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/gemma/bg/role.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/gemma/bg_role.png',
              spine: {
                appendEleID: "gemmaSpine",
                size: {
                  width: 1400,
                  height: 970,
                },
                jsonUrl: "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Gemma/skeleton.json",
                position: {
                  x: 550,
                  y: 1200,
                },
                scale: 1,
                animation: "GemmaAnim_together"
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/gemma/skill/3.png',
              skill: 'gemma_skill_3',
              video: 'https://video-pt.xdcdn.net/video/2021/06/17/08b9a1abdc64026a360707c1ef513dc0/2120466751dd0058-1080p.mp4',
              switch_btn_default: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/default/gemma.png',
              switch_btn_active: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/active/gemma.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/default/gemma.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/active/gemma.png'
            },
            {
              id: 'gemma_2',
              lie: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_gemma_2.png',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_gemma_2.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_bg_gemma_2.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_bg_gemma_2.png',
              spine: {
                appendEleID: "gemma2Spine",
                size: {
                  width: 1400,
                  height: 970,
                },
                jsonUrl:
                  "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Gemma/Gemma03.json",
                position: {
                  x: 570,
                  y: 1100,
                },
                scale: 1.2,
                animation: "IdleAnim_Loop",
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/gemma/skill/2.png',
              skill: 'gemma_skill_2',
              video: 'https://video-pt.xdcdn.net/video/2022/10/12/5fcb8b159f0b33ade63c757ba2c23325/684130dfdfb5b5b2-1080p.mp4',
              switch_btn_default: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_default_gemma_2.png',
              switch_btn_active: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_active_gemma_2.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_default_gemma_2.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_active_gemma_2.png'
            }
          ]
        },
        {
          name: 'youga',
          name_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/youga/bg/name_white.png',
          subHeroID: 'youga_1',
          specialty: [
            {
              id: 'youga_1',
              lie: '',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/youga/role.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/youga/bg/role.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/youga/bg_role.png',
              spine: {
                appendEleID: "yougaSpine",
                size: {
                  width: 1400,
                  height: 970,
                },
                jsonUrl: "https://website.xdcdn.net/homepage/torchlight/imgs/spine/Youga/youjia.json",
                position: {
                  x: 600,
                  y: 1100,
                },
                scale: 1.3,
                animation: "idle"
              },
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/youga/skill/1.png',
              skill: 'youga_skill_1',
              video: 'https://video-pt.xdcdn.net/video/2022/04/12/6788cd46d46cafe801d71ddacd0486db/f6899ac026d5a4e6-1080p.mp4',
              switch_btn_default: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/default/youga.png',
              switch_btn_active: 'https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/pc/section5/switch/active/youga.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/default/youga.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/switch/active/youga.png'
            },
            {
              id: 'youga_2',
              lie: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_youga_2.png',
              lie_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_youga_2.png',
              lie_bg: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/role_bg_youga_2.png',
              lie_bg_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/role_bg_youga_2.png',
              spine: '',
              icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/youga/skill/3.png',
              skill: 'youga_skill_3',
              video: 'https://video-pt.xdcdn.net/video/2022/10/12/1c2b28e8bf59b2b9666cf47644d88eac/b6e3040cc7db4edd-1080p.mp4',
              switch_btn_default: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_default_youga_2.png',
              switch_btn_active: 'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/btn_active_youga_2.png',
              switch_btn_default_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_default_youga_2.png',
              switch_btn_active_app: 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/hero/btn_active_youga_2.png'
            }
          ]
        }
      ],
      inViewed: false,
      showElementDelay: false,
      thisComponent: ''
    };
  },
  computed: {
    ...mapState([
      "data_detail",
      "data_scrollTop_listen",
      "data_size_listen",
      "navs"
    ]),
    // showElement() {
    //   let thisNav = this.navs.find(item => item.id == 'roles');
    //   if(thisNav['fromTop'] == 0) return false;

    //   let inView = thisNav.fromTop < this.data_scrollTop_listen + this.data_size_listen.screenHeight;
    //   if(inView) {
    //     this.inViewed = true
    //   }

    //   return (inView || this.inViewed);
    // },
  },
  watch: {
    data_size_listen: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.$lg(newVal, "screenWidth", 0) > 750) {
            this.screen = "pc";
            this.thisComponent = "BlockPc";
          } else {
            this.screen = "app";
            this.thisComponent = "BlockApp";
          }
        }
      },
      immediate: true,
      deep: true,
    },
    // showElement: {
    //   handler(newVal, oldVal) {
    //     if(newVal && newVal != oldVal) {
    //       this.showElementDelay = true;
    //     }
    //   },
    //   immediate: true
    // }
  },
  methods: {
    switchHero(obj) {
      this.subHeroName = obj.name;
      let subHero = this.heroes.find(item => item.name == this.subHeroName);
      if(obj.id) {
        this.subHeroID = obj.id;
      }else {
        this.subHeroID = this.$lg(subHero, 'specialty')[0]['id'];
      }
      subHero['subHeroID'] = this.subHeroID;
      this.transitions[obj.name] && this.transitions[obj.name].restart();
    },
    initTransitionsEnd(transitions) {
      this.transitions = transitions;
    },
    countGa() {},
  },
};
</script>

<style lang="scss" scoped>
.in-pc {
  height: vwPc(1057px, $ratio: 1);
}
.in-app {
  height: vwApp(1504px);
}
</style>
