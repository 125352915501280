<template>
  <div class="footer">
    <list-media v-if="showMedia" class="footer-media" site="in-footer"></list-media>
    <div class="level-container" :class="`in-${$i18n.locale}`">
      <div v-if="$i18n.locale == 'en'" class="icon-ESRB"></div>
    </div>
    <div class="xd-footer-logos">
      <a
        class="xd-footer-xdlogo"
        href="//www.xd.com/?lang=en"
        target="_blank"
      ></a>
      <div class="text">{{ `© ${thisYear} LTD. All Rights Reserved` }}</div>
    </div>
    <div v-if="$t('privacy_link') && $t('agreement_link')" class="footer-link">
      <template v-if="$i18n.locale == 'jp'">
        <a href="https://protocol.xd.com/jp/torchlight/fund.html" target="_blank">
          資金決済法に基づく表示
        </a>
        |
      </template>
      <a :href="$t('privacy_link')" target="_blank">
        {{ $t("privacy") }}
      </a>
      |
      <a :href="$t('agreement_link')" target="_blank">
        {{ $t("agreement") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListMedia from "@/components/list-media.vue";
export default {
  name: "TheFooter",
  components: { ListMedia },
  props: {
    showMedia: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      thisYear: ''
    }
  },
  computed: {
    ...mapState(["data_detail"]),
  },
  created() {
    this.thisYear = new Date().getFullYear();
  }
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  z-index: 1;
  height: vwPc(160px);
  background-color: #252525;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 vwPc(40px);

  .footer-media {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .level-container {
    position: absolute;
    top: 50%;
    left: 50%;
    &.in-en {
      width: vwPc(272px);
      height: vwPc(110px);
      margin-top: vwPc(-55px);
      margin-left: vwPc(400px);
      .icon-ESRB {
        width: vwPc(272px);
        height: vwPc(110px);
        background-image: url("https://website.xdcdn.net/homepage/torchlight/imgs/overseas/level/icon-ESRB.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .xd-footer-logos {
    height: 28px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .xd-footer-xdlogo {
      display: inline-block;
      width: 100px;
      height: 100%;
      background-image: url("https://website.xdcdn.net/homepage/torchlight/imgs/overseas/logo.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .text {
      font-size: vwPc(28px);
      margin-top: vwPc(6px);
      margin-left: vwPc(20px);
    }
  }
  .footer-link {
    font-size: vwPc(28px);
    color: #fff;
    letter-spacing: 0;
    white-space: nowrap;
    a {
      color: #fff;
    }
  }
}

@media screen and (max-width: 750px) {
  .footer {
    height: auto;
    padding: vwApp(30px) vwApp(40px);
    display: block;

    .footer-media {
      position: relative;
      top: unset;
      left: unset;
      transform: none;
      justify-content: center;
      margin-bottom: vwApp(20px);
    }

    .level-container {
      position: relative;
      top: unset;
      left: unset;
      &.in-en {
        width: vwApp(136px);
        height: vwApp(55px);
        margin: 0 auto vwApp(20px);
        .icon-ESRB {
          width: vwApp(136px);
          height: vwApp(55px);
        }
      }
    }

    .xd-footer-logos {
      height: vwApp(30px);
      margin: 0 auto vwApp(10px);
      .xd-footer-xdlogo {
        width: vwApp(110px);
      }
      .text {
        font-size: vwApp(16px);
        margin-top: vwApp(3px);
        margin-left: vwApp(10px);
      }
    }
    .footer-link {
      font-size: vwApp(16px);
      text-align: center;
    }
  }
}
</style>