<template>
  <div class="section-roles section-5" ref="roles">
    <div class="bg"></div>
    <div class="hero-introduce">
      <div
        class="hero-container"
        v-for="hero in heroes"
        :key="hero.name"
        :class="hero.name"
        v-show="hero.name == subHeroName"
      >
        <div class="bg-role">
          <div
            class="specialty-box specialty-roleBgBox img-box"
            v-for="(item, key) in $lg(hero, 'specialty', [])"
            :key="key"
          >
            <img v-show="item.id == subHeroID" v-lazy="$lg(item, 'lie_bg_app', '')">
          </div>
        </div>
        <div class="role">
          <div
            class="specialty-box"
            v-for="(item, key) in $lg(hero, 'specialty', [])"
            :key="key"
          >
            <div v-show="item.id == subHeroID" class="vertical img-box" :class="item.id">
              <img v-lazy="$lg(item, 'lie_app', '')">
            </div>
          </div>
        </div>
        <div class="name-box">
          <div class="name-en">{{ $t(hero.name) }}</div>
          <div class="line img-box">
            <img :src="'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/rehan/line.png'" alt="">
          </div>
          <div class="name-ch">{{ $t(`${hero.name}_before`) }}</div>
        </div>
      </div>
    </div>
    <div class="fix-bottom" ref="fixBottom">
      <img
        class="cover"
        v-lazy="'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/mobile/section5/cover.png'"
        key="bottom_cover"
      />
      <!-- <div class="cover"></div> -->
      <div
        class="hero-container"
        v-for="hero in heroes"
        :key="hero.name"
        :class="hero.name"
        v-show="hero.name == subHeroName"
      >
        <!-- video -->
        <div class="video-box">
          <img class="video-poster" v-lazy="posterMap[subHeroID]" @click="openVideoModal" />
        </div>
        <ul class="skill-btns">
          <li
            v-for="(item, key) in $lg(hero, 'specialty', [])"
            :key="key"
            :name="item.id"
            :class="{ active: item.id == subHeroID }"
            @click="switchHero(subHeroName, item.id)"
          >
            <div class="icon-box">
              <div class="icon img-box">
                <img v-lazy="$lg(item, 'icon', '')" />
              </div>
              <img
                class="default"
                src="https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/skill_btn.png"
              />
              <img
                class="active"
                src="https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/skill_active_btn.png"
              />
            </div>
            <p>{{ $t(`${$lg(item, 'skill', '')}`) }}</p>
          </li>
        </ul>
      </div>
      <div class="hero-switch" ref="heroSwitch">
        <ul class="btns" :style="{ width: `${heroes.length * 29}vw` }">
          <li
            class="picture-count"
            v-for="(hero, i) in heroes"
            :key="i"
            :name="hero.name"
            :class="{ active: subHeroName == hero.name }"
          >
            <div
              v-for="(item, key) in $lg(hero, 'specialty', [])"
              :key="key"
              class="specialty-box specialty-switchBox"
            >
              <div v-show="item.id == $lg(hero, 'subHeroID', '')" class="img-box">
                <img class="default" v-lazy="$lg(item, 'switch_btn_default_app', '')"/>
                <img class="active" v-lazy="$lg(item, 'switch_btn_active_app', '')"/>
              </div>
            </div>
            <div class="btn" @click="switchHero(hero.name, $lg(hero, 'subHeroID', ''), i)"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as initAnime from "@/assets/libs/initAnimeApp";

const posterMap = {
  'erika_1': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_erika_1.png',
  'thea_1': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_thea_1.png',
  'moto_1': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_moto_1.png',
  'moto_2': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_moto_2.png',
  'carino_1': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_carino_1.png',
  'carino_2': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_carino_2.png',
  'rehan_1': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_rehan_1.png',
  'rehan_2': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_rehan_2.png',
  'gemma_1': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_gemma_1.png',
  'gemma_2': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_gemma_2.png',
  'youga_1': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_youga_1.png',
  'youga_2': 'https://website.xdcdn.net/homepage/torchlight/imgs/mobile/poster/poster_youga_2.png',
};

export default {
  props: {
    heroes: {
      type: Array,
      default: () => [],
    },
    subHeroName: {
      type: String,
      default: () => "",
    },
    subHeroID: {
      type: String,
      default: () => "",
    }
  },
  data() {
    return {
      initFixBottomTop: 0,
      fixBottomTop: 0,
      fixBottomMax: 0,
      isFix: false,
      unfix: false,
      switchStep: {
        start: 2,
        length: 0,
        total: 3,
        step: 0,
        stepWidth: 29
      },
      switchTimer: '',
      inSwitch: false,
      posterMap: posterMap
    };
  },
  watch: {
    heroes: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal.length != 0) {
          this.$nextTick(() => {
            this.initTransitions();
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState(["data_detail"])
  },
  methods: {
    ...mapActions(["createModalVideo"]),
    switchHero(name, id='', i='') {
      if(!this.inSwitch) {
        this.inSwitch = true;

        this.$emit('switch-hero', { name, id })
        if(typeof i == 'number') {
          this.handleStep(i+1);
        };

        this.switchTimer = setTimeout(() => {
          this.inSwitch = false;
        }, 2000)
      }
    },
    initTransitions() {
      let transitions = {};
      this.heroes.forEach((hero) => {
        transitions[hero.name] = initAnime.introduce('.section-roles', hero);
      });

      // 初始化完成，播放默认英雄动画
      transitions[this.subHeroName] && transitions[this.subHeroName].restart();
      this.$emit("init-transitions-end", transitions);
    },
    openVideoModal() {
      let subHero = this.heroes.find(hero => hero.name == this.subHeroName);
      let specialty = subHero.specialty.find(s => s.id == this.subHeroID);
      if(!specialty.video) return;

      this.createModalVideo({
        autoplay: false,
        video: specialty.video,
      });
    },
    handleStep(i) {
      if(!this.switchStep['length']) {
        this.switchStep['length'] = this.heroes.length
      }

      let index_start = i - this.switchStep['start'];
      if(index_start > 0) {
        let length_total = this.switchStep['length'] - this.switchStep['total'];
        if(index_start <= length_total) {
          this.switchStep['step'] = index_start;
        }else {
          this.switchStep['step'] = length_total;
        }
      }else {
        this.switchStep['step'] = 0;
      }

      this.$refs.heroSwitch.scrollLeft = this.switchStep['step'] * (this.switchStep['stepWidth'] / 100) * document.documentElement.clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-box {
  display: flex;
  justify-content: center;
  img {
    // width: 100%;
    height: 100%;
  }
}
.section-roles {
  position: relative;
  // @include app_section_bg('mobile_section5_bg');
  width: vwApp(750px);
  height: vwApp(1504px);
  .hero-introduce {
    position: relative;
    width: vwApp(750px);
    height: vwApp(1504px);
    margin: 0 auto;
    overflow: hidden;

    .hero-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      .bg-role {
        width: 137.33333vw;
        height: 217.33333vw;
        .specialty-box {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
      .role {
        transform-origin: left bottom;
        @include gpu-for-transition();
        .specialty-box {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          .vertical {
            width: 100%;
            height: 100%;
          }
        }
      }
      .name-box {
        display: inline-block;
        position: absolute;
        top: vwApp(55px);
        left: vwApp(30px);
        .name-en {
          font-size: vwApp(100px);
          color: #fff;
        }
        .line {
          width: 46.53333vw;
          height: 2.4vw;
          margin-bottom: vwApp(10px);
        }
        .name-ch {
          font-size: vwApp(36px);
          color: #fff;
        }
      }

      &.rehan {
        .role {
          width: 118.66667vw;
          height: 178.13333vw;
          position: absolute;
          top: vwApp(228px);
          left: vwApp(-40px);
        }
        .bg-role {
          position: absolute;
          top: vwApp(-152px);
          right: vwApp(-123px);
        }
      }
      &.carino {
        .role {
          width: 136vw;
          height: 192.26667vw;
          position: absolute;
          top: vwApp(40px);
          right: vwApp(-185px);
        }
        .bg-role {
          position: absolute;
          top: vwApp(-145px);
          right: vwApp(-165px);
        }
      }
      &.gemma {
        .role {
          width: 124vw;
          height: 178.13333vw;
          position: absolute;
          top: vwApp(100px);
          left: vwApp(-100px);
        }
        .bg-role {
          position: absolute;
          top: vwApp(-143px);
          right: vwApp(-144px);
        }
      }
      &.moto {
        .role {
          width: 118.66667vw;
          height: 192.26667vw;
          position: absolute;
          top: vwApp(58px);
          left: vwApp(-40px);
        }
        .bg-role {
          position: absolute;
          top: vwApp(-152px);
          right: vwApp(-123px);
        }
      }
      &.youga {
        .role {
          width: 118.66667vw;
          height: 192.26667vw;
          position: absolute;
          top: vwApp(90px);
          left: vwApp(-40px);
        }
        .bg-role {
          position: absolute;
          top: vwApp(-152px);
          right: vwApp(-123px);
        }
      }
      &.thea,
      &.erika {
        .role {
          width: 118.66667vw;
          height: 192.26667vw;
          position: absolute;
          top: vwApp(90px);
          left: vwApp(-40px);
        }
        .bg-role {
          position: absolute;
          top: vwApp(-152px);
          right: vwApp(-123px);
        }
      }
    }
  }
}
.fix-bottom {
  width: app_get_vw(750px);
  height: app_get_vw(465px);
  position: absolute;
  bottom: 0;
  .cover {
    width: app_get_vw(750px);
    height: app_get_vw(342px);
    @include sprite_absolute($bottom: app_get_vw(120px), $left: 0);
    background-size: cover;
  }
  .hero-container {
    position: absolute;
    bottom: app_get_vw(120px);
    left: 0;
    overflow: hidden;
    width: 100%;
    height: app_get_vw(690px);

    .video-box {
      position: absolute;
      width: app_get_vw(460px);
      height: app_get_vw(345px);
      left: 50%;
      bottom: app_get_vw(250px);
      margin-left: app_get_vw(-230px);
      overflow: hidden;

      .video-poster {
        width: 100%;
        height: 100%;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
    }

    .skill-btns {
      display: -webkit-inline-box;
      position: absolute;
      left: 50%;
      bottom: app_get_vw(62px);
      padding-top: app_get_vw(20px);
      overflow: hidden;
      transform: translate3d(-50%, 0, 0);

      li {
        float: left;
        width: app_get_vw(215px);
        margin: 0 app_get_vw(5px);
        -webkit-tap-highlight-color: transparent;
        .icon-box {
          position: relative;
          width: app_get_vw(160px);
          height: app_get_vw(160px);
          cursor: pointer;
          transform: scale(0.7);
          margin: 0 auto;
          >.default {
            width: app_get_vw(161px);
            height: app_get_vw(161px);
            position: absolute;
            top: app_get_vw(-8px);
            left: 0px;
            transition: all ease-in-out 200ms;
          }
          >.active {
            width: app_get_vw(165px);
            height: app_get_vw(172px);
            position: absolute;
            top: app_get_vw(-16px);
            left: 0px;
            opacity: 0;
            transition: all ease-in-out 200ms;
          }
          .icon {
            position: absolute;
            left: 50%;
            margin-left: app_get_vw(-60px);
            top: app_get_vw(15px);
            width: app_get_vw(120px);
            height: app_get_vw(120px);
            .default {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
            .active {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              transition: all ease-in-out 200ms;
            }
          }
        }

        p {
          text-align: center;
          color: #fff;
          font-size: app_get_vw(28px);
          margin: 0;
        }

        &.active {
          .icon-box {
            .active {
              opacity: 1;
            }
            .default {
              opacity: 0;
            }
          }

          &:hover {
            .icon {
              .active {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .hero-switch {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: app_get_vw(122px);
    background-color: #000;
    overflow-x: scroll;
    overflow-y: hidden;
    .btns {
      display: flex;
      margin: 0 auto;
      padding: 0 app_get_vw(16px);
      margin-top: app_get_vw(19px);
      overflow: hidden;
      -webkit-tap-highlight-color: transparent;

      li {
        position: relative;
        float: left;
        cursor: pointer;
        width: app_get_vw(239px);
        height: app_get_vw(83px);
        .specialty-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .img-box {
            width: 100%;
            height: 100%;
          }
        }

        .default, .active {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .active {
          opacity: 0;
        }
        margin: 0 app_get_vw(-11px);

        .btn {
          position: relative;
          width: app_get_vw(200px);
          height: 100%;
          margin: 0 auto;
        }

        &.active {
          .active {
            opacity: 1;
          }
        }

        transition: all ease-in-out 300ms;
      }
    }
  }
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: #171717;
  }

  /*定义滚动条轨道 内阴影+圆角*/

  ::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #000;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #353333;
  }

  &.fix {
    position: fixed;
    bottom: 0;
  }
  &.unfix {
    bottom: app_get_vw(1600px);
    // bottom: app_get_vw(200px);
  }
}
</style>