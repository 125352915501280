import anime from 'animejs'

// const $el = '.section-5'

// 角色介绍动画
const introduce = ($el, hero) => {
    let animate = anime.timeline({
        autoplay: false
    })

    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .name-white`,
        opacity: {
            value: [0, 1],
            duration: 200
        },
        translateX: ['-5vw', 0],
        easing: 'easeInOutSine',
        duration: 1200
    }, 0)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .bg-role`,
        opacity: {
            value: [0, 1],
            duration: 300
        },
        translateX: {
            value: ['-30vw', 0],
            duration: 800,
        },
        translateY: {
            value: ['40vw', 0],
            duration: 800,
        },
        scale: {
            value: [1.1, 1],
            easing: 'linear',
            duration: 2500
        }
    }, 200)
    // animate.add({
    //     targets: `${$el} .hero-introduce .${hero.name} .other`,
    //     opacity: {
    //         value: [0, 1],
    //         duration: 300
    //     },
    //     translateX: ['5vw', 0],
    //     translateY: ['-7vw', 0],
    //     duration: 800
    // }, 200)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .role`,
        opacity: [0, 1],
        translateX: {
            value: ['-20vw', 0],
            duration: 500
        }
    }, 700)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .name-box .name-en`,
        opacity: [0, 1],
        translateX: ['10vw', 0],
        easing: 'easeInOutQuint'
    }, 1200)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .name-box .line`,
        opacity: [0, 1],
        translateX: ['10vw', 0],
        easing: 'easeInOutQuint'
    }, 1300)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .name-box .name-ch`,
        opacity: [0, 1],
        translateX: ['10vw', 0],
        easing: 'easeInOutQuint'
    }, 1500)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .skill .video-box`,
        opacity: [0, 1],
        scale: [0, 1],
        easing: 'easeInOutQuint'
    }, 1600)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .specialty-skillBox`,
        opacity: [0, 1],
        scale: [0, 1],
        easing: 'easeInOutQuint',
        delay: anime.stagger(80)
    }, 1600)
    // animate.add({
    //     targets: `${$el} .hero-introduce .${hero.name} .decorate-line[name="1"]`,
    //     opacity: [0, 1],
    //     easing: 'linear',
    //     duration: 200,
    //     begin: anime => {
    //         let $target = anime.animatables[0].target;
    //         $target.classList.remove('animete');
    //         $target.classList.add('animete');
    //     }
    // }, 100)
    // animate.add({
    //     targets: `${$el} .hero-introduce .${hero.name} .decorate-line[name="2"]`,
    //     opacity: [0, 1],
    //     begin: anime => {
    //         let $target = anime.animatables[0].target;
    //         $target.classList.remove('animete');
    //         $target.classList.add('animete');
    //     }
    // }, 100)


    return animate
}

export {
    introduce
}