<template>
  <div class="switch-locale">
    <div class="holder" @mouseenter="activeIcon" @mouseleave="deactiveIcon" @click="handleLocales">
      <div :class="['icon', iconActive && 'icon-active']"></div>
      <div class="icon-active img-preload"></div>
      <!-- <span>{{ $lg(languages, myLocale, "English") }}</span> -->
    </div>
    <ul class="locales-list" v-show="showLanguageList">
      <li
        v-for="(name, locale) in languages"
        :key="locale"
        :data-locale="locale"
        :class="{ active: locale == myLocale }"
        @click="setLanguage(locale)"
      >
        {{ name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { getLocale } from "@/utils/getLocale";

export default {
  data() {
    return {
      showLanguageList: false,
      languages: {
        "zh-cn": "中文 (简体)",
        "zh-tw": "中文 (繁體)",
        en: "English",
        kr: "한국어", // kr	朝鲜语
        jp: "日本語", // jp	日语
        th: "ภาษาไทย", // th	泰语
        id: "Bahasa", // id	印度尼西亚语 // Bahasa Indonesia
        vn: "Tiếng Việt", // vn	越南语
        ru: "Русский", // ru	俄语
        pt: "Portugal", // pt	葡萄牙语
        fr: "Français", // fr	法语
        es: "español", // es	西班牙语
        de: "Deutsch", // de	德语
      },
      myLocale: "en",
      iconActive: false,
    };
  },
  created() {
    this.autoLanguage();
  },
  methods: {
    autoLanguage() {
      let locale = this.$route.name || '';
      // 查看用户配置语言
      // let locale = this.$localStorage.get("torchligth:locale");
      if (!locale || (locale && !this.languages[locale])) {
        locale = getLocale();
      }

      if (!this.languages[locale]) {
        // 没有配置当地语言
        locale = "en";
      }
      this.$i18n.locale = locale;
      this.myLocale = locale;
      // this.updateRoute(locale);
    },
    setLanguage(locale) {
      // this.$localStorage.set("torchligth:locale", locale);
      this.$i18n.locale = locale;
      this.myLocale = locale;
      this.updateRoute(locale);

      // this.showLanguageList = false;
      this.handleLocales();
    },
    handleLocales() {
      this.showLanguageList = !this.showLanguageList;

      this.$emit("localesState", this.showLanguageList);
    },
    hide() {
      this.showLanguageList = false;
    },
    updateRoute(lang) {
      const q_lang = this.$route.name;
      if(q_lang) {
        if(this.$route.fullPath == '/' || this.$route.path == '/index') {
          this.$router.push({
            path: '/'+lang
          })

          return;
        }

        if(q_lang == lang) return;
        let path_ = this.$route.fullPath.replace(q_lang, lang);
        this.$router.push({
          path: path_
        })
      }else {
        this.$router.push({
          path: '/'+lang+this.$route.fullPath
        })
      }
    },
    activeIcon() {
      this.iconActive = true;
    },
    deactiveIcon() {
      this.iconActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-preload {
  width: 0;
  height: 0;
  pointer-events: none;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
}
.switch-locale {
  position: relative;
  margin-left: vwPc(70px);
  .holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .icon {
      @include bg("overseas_add2022_icon_locale");
      margin-right: vwPc(10px);
    }
    .icon-active {
      background-image: url("https://website.xdcdn.net/homepage/torchlight/imgs/overseas/add2022/icon_locale_active.png");
    }
    span {
      font-size: vwPc(28px);
      line-height: vwPc(40px);
      color: #ababab;
    }
    &:hover {
      span {
        color: #ffffff;
      }
    }
  }
  .locales-list {
    position: absolute;
    background-color: rgba(8, 15, 26, 0.9);
    border-radius: vwPc(6px);
    top: vwPc(120px);
    left: 50%;
    margin-left: vwPc(-100px);
    font-size: vwPc(28px);
    padding: vwPc(20px) 0;
    list-style: none !important;
    li {
      height: vwPc(62px);
      line-height: vwPc(62px);
      color: #ABABAB;
      padding-left: vwPc(30px);
      padding-right: vwPc(30px);
      white-space: nowrap;
      cursor: pointer;
      transition: all ease-in-out 200ms;
      list-style: none !important;
      &:hover {
        color: #fff;
      }

      &.active {
        color: #ffc221;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .switch-locale {
    position: relative;
    margin: vwApp(22px) vwApp(30px) 0 0;
    .holder {
      .icon {
        @include bg("overseas_add2022_icon_locale");
        width: vwApp(68px);
        height: vwApp(50px);
        margin-right: vwApp(10px);
      }
      .icon-active {
        background-image: url("https://website.xdcdn.net/homepage/torchlight/imgs/overseas/add2022/icon_locale_active_2x.png");
      }
      span {
        font-size: vwApp(24px);
        line-height: vwApp(34px);
      }
    }
    .locales-list {
      position: absolute;
      width: vwApp(300px);
      background-color: #000;
      top: 8vw;
      left: 50%;
      margin-left: -23vw;
      font-size: vwApp(28px);
      padding: vwApp(20px) 0;
      max-height: 70vh;
      overflow-x: hidden;
      overflow-y: scroll;
      li {
        height: vwApp(62px);
        line-height: vwApp(62px);
        color: #fff;
        padding-left: vwApp(30px);
        white-space: nowrap;
        cursor: pointer;
        transition: all ease-in-out 200ms;
        &:hover {
          background-color: #ffc221;
        }

        &.active {
          background-color: #ffc221;
          color: #ffffff;
        }
      }
    }
  }
}
</style>