<template>
  <div class="slide__btn" :class="[`size__btn--${size}`, `type__btn--${type}`]">
    <div class="slide__btn--before"></div>
    <div class="slide__btn--text">
      <slot></slot>
    </div>
    <div class="slide__btn--after"></div>
  </div>
</template>

<script>
export default {
  name: "UiButtonSlide",
  props: {
    size: {
      type: String,
      default: () => "normal", // normal, mini
    },
    type: {
      type: String,
      default: () => "normal", // normal, main
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin hover {
  &:hover {
    .slide__btn--before {
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 1;
      }
    }
    .slide__btn--text {
      background: #fcff54;
    }
    .slide__btn--after {
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 1;
      }
    }
  }
}

@mixin labelSet {
  white-space: nowrap;
  font-weight: 700;
  color: #543818;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
}

// 公共
.slide__btn {
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  .slide__btn--before,
  .slide__btn--after {
    position: relative;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      @include bgSet(cover);
    }
  }
  .slide__btn--before::after {
    background-image: map-get(
      $global-var,
      "overseas_add2022_slidebtn_before_hover-o"
    );
    opacity: 0;
  }
  .slide__btn--after::after {
    background-image: map-get(
      $global-var,
      "overseas_add2022_slidebtn_after_hover-o"
    );
    opacity: 0;
  }

  .slide__btn--text {
    min-width: vwPc(210px);
    flex: 1;
    position: relative;
    margin: 0 -1px;
    height: 100%;
    @include labelSet;
    a {
      display: block;
      width: 100%;
      height: 100%;
      @include labelSet;
    }
  }
}

// type: normal main
// 普通类型 橙色按钮
.type__btn--normal {
  .slide__btn--before {
    &::before {
      background-image: map-get(
        $global-var,
        "overseas_add2022_slidebtn_before-o"
      );
    }
  }
  .slide__btn--text {
    background: #ffc221;
  }
  .slide__btn--after {
    &::before {
      background-image: map-get(
        $global-var,
        "overseas_add2022_slidebtn_after-o"
      );
    }
  }

  @include hover;
}

// 重点类型 橙色渐变按钮
.type__btn--main {
  .slide__btn--before {
    &::before {
      background-image: map-get(
        $global-var,
        "overseas_add2022_slidebtn_before_main-o"
      );
    }
  }
  .slide__btn--text {
    background: linear-gradient(180deg, #fda117 0%, #ffc220 66.61%);
  }
  .slide__btn--after {
    &::before {
      background-image: map-get(
        $global-var,
        "overseas_add2022_slidebtn_after_main-o"
      );
    }
  }

  @include hover;
}

// size: normal mini
// 普通类型 大尺寸按钮
.size__btn--normal {
  height: vwPc(126px);
  .slide__btn--before {
    width: vwPc(107px);
    margin-right: vwPc(-6px);
  }
  .slide__btn--text {
    font-size: vwPc(48px);
  }
  .slide__btn--after {
    width: vwPc(107px);
    margin-left: vwPc(-10px);
  }
}

// mini类型 小尺寸按钮
.size__btn--mini {
  height: vwPc(60px);
  .slide__btn--before {
    width: vwPc(36.5px);
    margin-right: vwPc(-2px);
  }
  .slide__btn--text {
    font-size: vwPc(28px);
  }
  .slide__btn--after {
    width: vwPc(36.5px);
    margin-left: vwPc(-3px);
  }
}

@media screen and (max-width: 750px) {
  // size: normal mini
  .size__btn--normal {
    height: vwApp(106px);
    .slide__btn--before {
      width: vwApp(77px);
      margin-right: vwApp(-6px);
    }
    .slide__btn--text {
      font-size: vwApp(34px);
    }
    .slide__btn--after {
      width: vwApp(77px);
      margin-left: vwApp(-10px);
    }
  }

  .size__btn--mini {
    height: vwApp(43px);
    .slide__btn--before {
      width: vwApp(36.5px);
      margin-right: vwApp(-2px);
    }
    .slide__btn--text {
      font-size: vwApp(20px);
    }
    .slide__btn--after {
      width: vwApp(36.5px);
      margin-left: vwApp(-3px);
    }
  }
}
</style>