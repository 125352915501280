<template>
  <ul v-if="mediaList.length != 0" class="media" :class="site">
    <template v-for="(media, key) in mediaList">
      <li v-if="formatMediaLink(media)" :key="key" :name="media">
        <div v-if="media == 'WeChat'" class="QR-code-box">
          <div class="QR-code" :style="{ 'background-image': `url(${formatMediaLink(media)})` }"></div>
        </div>
        <a v-else :href="formatMediaLink(media)" target="_blank"></a>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  props: {
    site: {
      typeof: String,
      default: () => "",
    },
  },
  data() {
    return {};
  },
  computed: {
    mediaList() {
      return this.$t("media_list").split(",") || [];
    },
  },
  methods: {
    formatMediaLink(media) {
      const _t = this.$t(`media_${media}`);
      if (_t == `media_${media}`) {
        return "";
      }
      return _t;
    },
  },
};
</script>

<style lang="scss" scoped>
.media {
  display: flex;
  li {
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    $mediaList: (
      "TapTap",
      "Discord",
      "Facebook",
      "YouTube",
      "Twitter",
      "TikTok",
      "Reddit",
      "Twitch"
    );
    @each $media in $mediaList {
      &[name="#{$media}"] {
        @include bg("overseas_media_#{$media}");
      }
    }
    &[name="Bahamut"] {
      @include bg("overseas_media_Bahamut_2x");
    }
    &[name="Naver"] {
      @include bg("overseas_media_Naver_2x");
    }

    &[name="Weibo"] {
      background-image: url("https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/media/Weibo_2x.png");
    }
    &[name="Bilibili"] {
      background-image: url("https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/media/Bilibili_2x.png");
    }
    &[name="WeChat"] {
      background-image: url("https://poster.xdcdn.net/xd-website/homepage/torchlight/imgs/overseas/media/WeChat_2x.png");
      &:hover {
        .QR-code-box {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &[name="QQ"] {
      background-image: url('https://website.xdcdn.net/homepage/torchlight/imgs/overseas/media/QQ.png');
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    .QR-code-box {
      position: absolute;
      width: vwPc(240px);
      height: vwPc(240px);
      background-color: rgba(8, 15, 26, 0.9);
      border-radius: vwPc(6px);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      .QR-code {
        width: vwPc(178px);
        height: vwPc(178px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  &.in-nav {
    li {
      margin: 0 0.2vw;
      width: 1.66667vw;
      height: 1.66667vw;
      .QR-code-box {
        left: 50%;
        margin-left: vwPc(-120px);
        top: 1.7vw;
      }
    }
  }
  &.in-footer {
    li {
      margin: 0 0.3vw;
      width: 2.5vw;
      height: 2.5vw;
      .QR-code-box {
        left: 50%;
        margin-left: vwPc(-120px);
        bottom: 2.5vw;
      }
    }
    @media screen and (max-width: 750px) {
      li {
        margin: 0 vwApp(6px);
        width: vwApp(62px);
        height: vwApp(62px);
        .QR-code-box {
          width: vwApp(240px);
          height: vwApp(240px);
          border-radius: vwApp(12px);
          margin-left: vwApp(-120px);
          bottom: vwApp(62px);
          .QR-code {
            width: vwApp(178px);
            height: vwApp(178px);
          }
        }
      }
    }
  }
  &.in-footer-tw {
    width: 40px;
    height: 40px;
    margin: 0 5px;
  }
}
</style>