<template>
  <div
    ref="footerView"
    class="fremoteConfigurableFooter_Complex"
    :class="{
      'layout-normal': adaptiveType != 'rem',
      'layout-rem': layoutRem
    }"
  >
    <div class="center-box">
      <div class="footer-row row-1">
        <!-- 媒体 -->
        <template v-if="media && media.length != 0">
          <div class="media-box">
            <a v-for="(item, key) in media" :key="key" :href="item.link" target="_blank">
              <img v-if="item.icon" :src="item.icon" :style="{'height': item.height || '100%'}" alt="">
              <span v-else class="iconfont" :class="item.media"></span>
            </a>
          </div>
          <div class="line"></div>
        </template>
        <template v-else>
          <div class="holder"></div>
        </template>
        <!-- logo -->
        <div class="icon-box">
          <img src="https://website.xdcdn.net/remote-components/icons/xd.svg" alt="">
          <template v-if="footer['logo_platform'] && footer['logo_platform'].length != 0">
            <img
              v-for="(item, i) in footer['logo_platform']"
              :key="i"
              :src="item.icon"
              :style="{ height: item.height || '100%' }"
            />
          </template>
        </div>
      </div>
      <div class="footer-row row-2">
        <!-- 主要信息 -->
        <div class="info-box">
          <!-- 版权 -->
          <table class="copyright-box" v-if="copyright && copyright.length != 0">
            <tr v-for="(item, i) in copyright" :key="i">
              <td>{{ item.key }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </table>
          <!-- 邮箱 -->
          <table class="email-box" v-if="footer['email'] && footer['email'].length != 0">
            <tr v-for="(item, i) in footer['email']" :key="i">
              <td>{{ item.key }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </table>
          <!-- 协议 -->
          <div class="link-box" v-if="footer['list_link'] && footer['list_link'].length != 0">
            <template v-for="(item, i) in footer['list_link']">
              <a
                v-if="true"
                :href="item.link ? item.link : 'javascript:;'"
                :key="`a_${i}`"
                target="_blank"
                class="text"
              >{{ item.label }}</a>
              <span v-if="i != footer['list_link'].length - 1" class="line" :key="`line_${i}`">&</span>
            </template>
          </div>
        </div>
        <!-- 提示信息 -->
        <div class="prompt-box">
          <img
            class="icon_grading"
            v-if="footer['icon_grading'] && footer['icon_grading']['pc']"
            :src="footer['icon_grading']['pc']"
          />
          <ul class="prompt-list">
            <li v-for="(item, i) in footer['list_prompt']" :key="i">{{ item.text }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    footer: {
      type: Object,
      default: () => {}
    },
    media: {
      type: Array,
      default: () => []
    },
    adaptiveType: {
      type: String,
      default: () => "px"
    }
  },
  data() {
    return {
      temp_time: 0,
      layoutRem: false,
      d_footer: null
    }
  },
  // head() {
  //   return {
  //     link: [
  //       {
  //         rel: 'stylesheet',
  //         href: 'https://website.xdcdn.net/form/website/common-config/iconfont/iconfont.css',
  //       },
  //     ]
  //   };
  // },
  computed: {
    copyright() {
      let copyright_new = '';
      let thisYear = new Date().getFullYear();

      if(this.footer['copyright'] && this.footer['copyright'].length != 0) {
        copyright_new = this.footer['copyright'].map(item => {
          let copyright_temp = item['value'];
          let pre_i = copyright_temp.indexOf('{{');
          let pre_str = copyright_temp.slice(0, pre_i);
          let last_i = copyright_temp.indexOf('}}');
          let last_str = copyright_temp.slice(last_i+2);

          return {
            key: item["key"],
            value: `${pre_str}${thisYear}${last_str}`
          }
        })
      }else {
        copyright_new = [
          {
            key: "",
            value: `© ${thisYear} XD . All Rights Reserved`
          }
        ];
      }

      return copyright_new;
    }
  },
  watch: {
    adaptiveType: {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          if(newVal == 'rem') {
            // rem模式，根据页脚组件父元素，调整布局
            this.$nextTick(() => {
              this.listenResize();
            })
          }else {
            // px或vw模式，根据screen-width，调整布局
            this.$nextTick(() => {
              this.removeListenr();
            })
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    listenResize() {
      window.addEventListener('resize', this.handleResize);
      this.$nextTick(() => {
        this.handleResize();
      })
    },
    removeListenr() {
      window.removeEventListener('resize', this.handleResize);
    },
    handleResize() {
      let this_time = new Date().getTime();
      if(this_time - this.temp_time >= 100) {
        if(!this.d_footer) {
          this.d_footer = this.$refs.footerView;
        }

        let d_footer_width = this.d_footer.offsetWidth;
        if(d_footer_width <= 1199) {
          this.layoutRem = true;
        }else {
          this.layoutRem = false;
        }

        this.temp_time = this_time;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.fremoteConfigurableFooter_Complex {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 54px 0;
  font-size: 14px;
  background-color: #2D2E2E;
  overflow: hidden;
  .center-box {
    width: 92%;
    .footer-row {
      display: flex;
      justify-content: space-between;
    }
    .row-1 {
      margin-bottom: 20px;
      .media-box {
        display: inline-flex;
        align-items: center;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background-color: #ABABAB;
          border-radius: 50%;
          margin-right: 10px;
          text-decoration: none;
          img {
            height: 100%;
          }
          span {
            color: #0E0C0C;
            &.icon-discord {
              margin-left: 1px;
            }
            &.icon-instagram {
              margin-left: 4px;
            }
          }
        }
      }
      .line {
        display: none;
      }
      .icon-box {
        display: inline-flex;
        height: 55px;
        justify-content: flex-end;
        align-items: center;
        img {
          height: 100%;
          margin-left: 10px;
        }
      }
    }
    .row-2 {
      color: #878787;
      a {
        color: #878787;
      }
      .info-box {
        font-weight: 700;
        td {
          white-space: nowrap;
        }
        .link-box {
          padding-left: 2px;
          a {
            text-decoration: underline;
            text-align: center;
          }
          .line {
            margin: 0 4px 0 2px;
          }
        }
      }
      .prompt-box {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 700;
        .icon_grading {
          width: 90px;
        }
        .prompt-list {
          width: 375px;
          margin-left: 20px;
          li {
            margin: 8px 0;
          }
        }
      }
    }
  }
}

// 移动端布局
@mixin layout-app {
  padding: 50px 0 44px;
  font-size: 12px;
  .center-box {
    width: 310px;
    min-width: 310px;
    .footer-row {
      justify-content: center;
      align-items: center;
    }
    .row-1 {
      flex-direction: column;
      margin-bottom: 20px;
      .media-box {
        a {
          // width: 33px;
          // height: 33px;
          margin: 0 3px;
        }
      }
      .line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #434343;
        margin: 28px auto 20px;
      }
      .icon-box {
        height: 33px;
        justify-content: center;
        img {
          margin: 0 5px;
        }
      }
    }
    .row-2 {
      flex-direction: column-reverse;
      .info-box {
        .link-box {
          margin-top: 25px;
          display: flex;
          justify-content: center;
          font-size: 14px;
          padding-left: 0;
          .line {
            margin: 0 3px 0 2px;
          }
        }
      }
      .prompt-box {
        width: 100%;
        justify-content: space-between;
        .icon_grading {
          width: 72.5px;
        }
        .prompt-list {
          width: 218px;
          margin-left: 0;
        }
      }
    }
  }
}

// px或vw模式下，通过screen-width，调整布局
.layout-normal {
  @media screen and (max-width: 1199px) {
    @include layout-app;
  }
}

// rem模式下，通过resize判断父元素宽度，调整布局
.layout-rem {
  @include layout-app;
}

</style>