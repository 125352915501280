<template>
  <div id="news-container">
    <div class="bg"></div>
    <div class="header">
      <div class="logo"></div>
      <!-- 多语言选择 -->
      <the-locale class="the-locale"/>
    </div>
    <div class="wrapper">
      <div class="article" v-html="article"></div>
    </div>
    <the-footerTW v-if="region == 'TW' || $i18n.locale == 'zh-tw'" />
    <the-footer v-else :showMedia="false" />
  </div>
</template>

<script>
import { getRegion } from "@/utils/getLocale";
import TheLocale from "@/components/the-locale-2.vue";
import TheFooter from "@/components/the-footer.vue";
import TheFooterTW from "@/components/the-footer-tw.vue";
import _ajax from '@/api/ajax';

export default {
  components: {
    TheLocale,
    TheFooter,
    TheFooterTW
  },
  data() {
    return {
      region: getRegion(),
      article: '',
      langMap: {
        'zh-tw': 'tw',
        'zh-cn': 'cn',
        'en': 'en',
        'kr': 'kr'
      },
      tempLang: ''
    }
  },
  watch: {
    '$i18n.locale': {
      handler(newVal, oldVal) {
        let id = this.$route.query?.id || '';
        let lang = this.langMap[newVal] || 'en';
        this.reqArticle(lang, id);
      }
    }
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    if(!this.tempLang) {
      let id = this.$route.query?.id || '';
      if(!id) return;

      let lang = this.langMap[this.$i18n.locale] || 'en';
      this.reqArticle(lang, id);
    }
  },
  methods: {
    async reqArticle(lang, id) {
      if(lang == this.tempLang) return;

      this.tempLang = lang;
      let res = await _ajax(`https://protocol.xd.com/${lang}/torchlight/inline-${id}.html`);
      if(res.status == 200) {
        this.article = res.data.replace('width: 95%;', '');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#news-container {
  position: relative;
  width: 100%;
  background-color: #071D3F;
  overflow: hidden;
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('https://website.xdcdn.net/poster/227017/epicycle/ep-2/6gEU9MKm.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .header {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 3.125vw;
    font-size: 0.9375vw;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #040a14;
    .logo {
      width: 2.47396vw;
      height: 2.26563vw;
      background-image: map-get($global-var, "pc_header_logo_2x-o");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      margin-left: 2vw;
    }
    .the-locale {
      margin-right: 2vw;
    }
  }
  .wrapper {
    position: relative;
    width: 1060px;
    padding: 40px;
    background-color: transparent;
    background-image: linear-gradient(280deg, #FFFFFF00 0%, #131313 100%);
    margin: 6vw auto;
    .article {
      width: 100%;
      min-height: 2000px;
      color: #fff;
    }
  }


  ::v-deep #main {
    color: #e0dede !important;
    ul, ol, li { list-style: inside; margin-bottom: 10px;} 
  }
  ::v-deep p {
    margin: 5px 0 !important;
  }
}

@media screen and (max-width: 1080px) {  
  #news-container {
    .wrapper {
      width: 95%;
      padding: 4vw 5vw;
      .article {
        min-height: 110vh;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  #news-container {
    .header {
      height: 10vw;
      .logo {
        width: 7.8vw;
        height: 7.66667vw;

        margin-left: 4vw;
      }
      .the-locale {
        margin-right: 4vw;
      }
    }
    .wrapper {
      margin: 13vw auto 6vw;
    }
  }
}
</style>