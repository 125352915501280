<template>
  <div
    ref="footerView"
    class="remoteConfigurableFooter_Simple"
    :class="{
      'layout-normal': adaptiveType != 'rem',
      'layout-rem': layoutRem
    }"
  >
    <div class="footer-top" v-if="media && media.length != 0">
      <div class="media-box">
        <a v-for="(item, key) in media" :key="key" :href="item.link || 'javascript:;'" :target="item.link ? '_blank': '_self'">
          <img v-if="item.icon" :src="item.icon" :style="{'height': item.height || '100%'}" alt="">
          <span v-else class="iconfont" :class="item.media"></span>

          <div v-if="item.media_qrCode" class="media-popup">
            <img :src="item.media_qrCode">
          </div>
        </a>
      </div>
    </div>
    <div class="main-box">
      <div class="footer-row row-1">
        <div class="icon-box">
          <div class="footer-logo">
            <img
              src="https://website.xdcdn.net/remote-components/icons/xd-sea.png"
              alt=""
            />
            <template v-if="footer['logo_platform'] && footer['logo_platform'].length != 0">
              <img v-for="(item, i) in footer['logo_platform']" :key="i" :src="item.icon" :style="{height: item.height || '100%'}"/>
            </template>
          </div>
        </div>
        <div class="text">{{ copyright }}</div>
      </div>
      <div class="footer-row row-2">
        <template v-for="(item, i) in footer['list_link']">
          <a
            v-if="true"
            :href="item.link ? item.link : 'javascript:;'"
            :key="`a_${i}`"
            target="_blank"
            class="text"
          >{{ item.label }}</a>
          <span v-if="i != footer['list_link'].length - 1" class="line" :key="`line_${i}`">|</span>
        </template>
      </div>
      <img
        class="icon_grading in-pc"
        v-if="footer['icon_grading'] && footer['icon_grading']['pc']"
        :src="footer['icon_grading']['pc']"
        :style="{ 
          height: footer['icon_grading']['pc_height'] || '56px',
          'max-height': footer['icon_grading']['pc_height'] || '56px'
        }"
      />
      <img
        class="icon_grading in-app"
        v-if="footer['icon_grading'] && footer['icon_grading']['app']"
        :src="footer['icon_grading']['app']"
        :style="{ 
          height: footer['icon_grading']['app_height'] || '56px',
          'max-height': footer['icon_grading']['app_height'] || '56px'
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    footer: {
      type: Object,
      default: () => {}
    },
    media: {
      type: Array,
      default: () => []
    },
    adaptiveType: {
      type: String,
      default: () => "px"
    }
  },
  data() {
    return {
      temp_time: 0,
      layoutRem: false,
      d_footer: null
    }
  },
  // head() {
  //   return {
  //     link: [
  //       {
  //         rel: 'stylesheet',
  //         href: 'https://website.xdcdn.net/form/website/common-config/iconfont/iconfont.css',
  //       },
  //     ]
  //   };
  // },
  computed: {
    copyright() {
      let copyright_new = '';
      let thisYear = new Date().getFullYear();

      if(this.footer['copyright'] && this.footer['copyright'].length != 0) {
        let copyright_temp = this.footer['copyright'][0]['value'];
        let pre_i = copyright_temp.indexOf('{{');
        let pre_str = copyright_temp.slice(0, pre_i);
        let last_i = copyright_temp.indexOf('}}');
        let last_str = copyright_temp.slice(last_i+2);

        copyright_new = `${pre_str}${thisYear}${last_str}`;
      }else {
        copyright_new = `© ${thisYear} XD . All Rights Reserved`;
      }

      return copyright_new;
    }
  },
  watch: {
    adaptiveType: {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          if(newVal == 'rem') {
            // rem模式，根据页脚组件父元素，调整布局
            this.$nextTick(() => {
              this.listenResize();
            })
          }else {
            // px或vw模式，根据screen-width，调整布局
            this.$nextTick(() => {
              this.removeListenr();
            })
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    listenResize() {
      window.addEventListener('resize', this.handleResize);
      this.$nextTick(() => {
        this.handleResize();
      })
    },
    removeListenr() {
      window.removeEventListener('resize', this.handleResize);
    },
    handleResize() {
      let this_time = new Date().getTime();
      if(this_time - this.temp_time >= 100) {
        if(!this.d_footer) {
          this.d_footer = this.$refs.footerView;
        }

        let d_footer_width = this.d_footer.offsetWidth;
        if(d_footer_width <= 828) {
          this.layoutRem = true;
        }else {
          this.layoutRem = false;
        }

        this.temp_time = this_time;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.remoteConfigurableFooter_Simple {
  position: relative;
  z-index: 1;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footer-top {
    width: 100%;
    // height: 60px;
    padding: 14px 0 12px;
    background-color: #181818;
    display: flex;
    justify-content: center;
    align-items: center;
    .media-box {
      width: 1080px;
      max-width: 1080px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        margin-left: 20px;
        text-decoration: none;
        span {
          color: #999;
          transition: color ease-in-out 100ms;
          // font-size: 28px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .media-popup {
          position: absolute;
          width: 120px;
          height: 120px;
          padding: 10px;
          border-radius: 5px;
          overflow: hidden;
          background-color: #212121;
          top: 33px;
          z-index: 1;
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          span {
            color: #fff;
          }
          .media-popup {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  .main-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 0 auto;
    padding: 30px 0;
    background: #000;
    color: #fff;

    .row-1 {
      width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .icon-box {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        .footer-logo {
          width: 100%;
          display: flex;
          height: 28px;
          align-items: center;
          justify-content: center;
          img {
            height: 100%;
            margin: 0 3px;
          }
        }
      }
      .text {
        width: 100%;
        margin-bottom: 4px;
        color: #7e7e7e;
        text-align: center;
        font-weight: 500;
      }
    }
    .row-2 {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 700;
        letter-spacing: 0.4px;
        padding: 4px 10px 3px;
        border-radius: 5px;
        text-decoration: none;
        transition: all ease-in-out 200ms;
        &:hover {
          background-color: #232525;
        }
      }
      .line {
        color: #fff;
        margin: 0 10px;
        font-weight: 700;
      }
    }
    .icon_grading {
      height: 56px;
      margin-top: 15px;
      margin-bottom: 5px;
      &.in-pc {
        display: block;
      }
      &.in-app {
        display: none;
      }
    }
  }
}

// 移动端布局
@mixin layout-app {
  .footer-top {
    width: 100%;
    .media-box {
      max-width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      a {
        width: 33px;
        height: 33px;
        margin: 0 5px;
      }
    }
  }
  .main-box {
    padding: 30px 0 40px;
    .row-1 {
      margin-bottom: 5px;
      .icon-box {
        .footer-logo {
          // height: 22px;
          img {
            margin: 0 3px;
          }
        }
      }
      .text {
        margin-bottom: 4px;
      }
    }
    .row-2 {
      flex-direction: column;
      .text {
        &:hover {
          background-color: transparent;
        }
      }
      .line {
        display: none;
      }
    }
    .icon_grading {
      height: auto !important;
      max-width: 95%;
      &.in-pc {
        display: none;
      }
      &.in-app {
        display: block;
      }
    }
  }
}

// px或vw模式下，通过screen-width，调整布局
.layout-normal {
  @media screen and (max-width: 828px) {
    @include layout-app;
  }
}

// rem模式下，通过resize判断父元素宽度，调整布局
.layout-rem {
  @include layout-app;
}

</style>