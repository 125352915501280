<template>
  <div class="listen__ele--distance-from-top" ref="listenEle">
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "ListenDistanceFromTop",
  props: {
    nav: {
      type: String,
      default: () => "", // nav的名称，要与导航栏key相匹配
    },
  },
  computed: {
    ...mapState(["data_size_listen"]),
  },
  watch: {
    data_size_listen: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && this.$lg(newVal, "screenWidth", 0)) {
          this.resizeHandler();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeHandler();
    });
  },
  methods: {
    ...mapMutations(["setDistanceFromTop"]),
    resizeHandler() {
      // 页面滚动距离
      const initScrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      // 模块距离窗口顶部距离
      const rectTop = this.$refs.listenEle.getBoundingClientRect().top;

      // 将信息同步至state
      if (!this.nav) return;
      const data = {
        key: this.nav,
        val: Math.ceil(initScrollTop + rectTop),
      };
      this.setDistanceFromTop(data);
    },
  },
};
</script>