<template>
  <ui-modal class="modal-limit" :show="modal_limit_show">
    <div class="modal-mask" @click="closeModal"></div>
    <div class="limit-desc">
      <div class="close" @click="closeModal"></div>
    </div>
  </ui-modal>
</template>

<script>
import UiModal from "@/components/ui-modal.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ModalLimit",
  components: { UiModal },
  computed: {
    ...mapState(["modal_limit_show"]),
  },
  methods: {
    ...mapMutations(["toggleModal"]),
    closeModal() {
      let htmlStyle = document.getElementsByTagName('html')[0].style;
      htmlStyle.overflow = '';

      this.toggleModal({
        key: "modal_limit_show",
        bool: false,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-limit {
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .limit-desc {
    position: relative;
    width: 840px;
    max-width: 840px;
    height: 449px;
    max-height: 449px;
    align-items: flex-end;
    flex-shrink: 0;
    background-image: url(https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7VbJ9wH8.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    .close {
      position: absolute;
      width: 6%;
      min-width: 10px;
      height: 11%;
      min-height: 10px;
      left: 94.05%;
      top: 0;
      align-items: flex-start;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 750px) {
.modal-limit {
  .limit-desc {
    width: 86.66666666666667vw;
    max-width: unset;
    height: 142.13333333333333vw;
    max-height: unset;
    background-image: url(https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7Qm5wsEt.png);
    .close {
      width: 12.31%;
      min-width: 0;
      height: 7.5%;
      min-height: 0;
      left: 87.69%;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
}
</style>