<template>
  <listen-distance-from-top nav="news" class="section-news">
    <div v-if="showElementDelay" class="wrapper">
      <div class="title">{{ $t("title_news") }}</div>
      <div class="news-box">
        <div class="swiper-container news-swiper">
          <div v-if="subNews" class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, key) in subNews.carousel"
              :key="key"
            >
              <a :href="$lg(item, 'link', '')" target="_blank">
                <img class="img" :src="$lg(item, 'img_url', '')" :alt="key"/>
              </a>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="news-tab">
          <div class="nav-box">
            <ul class="tab-nav">
              <li
                v-for="(tab, key) in tabNavList"
                :key="key"
                :class="{active: tab == subTab}"
                @click="subTab = tab"
              >{{ $t(`tab-${tab}`) }}</li>
            </ul>
          </div>
          <ul v-if="subNews[subTab] && subNews[subTab].length != 0" class="article-list">
            <li v-for="(item, key) in subNews[subTab].slice(0, 5)" :key="key">
              <a :href="item.link" target="_blank">
                <div class="title" :class="{ isTop: item.top }">{{ item.title }}</div>
                <div class="time">{{ item.time }}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ui-button-slide class="more" @click="countGa('moreNews')">
        <a :href="`/news/list`" target="_blank">{{
          $t("btn_more")
        }}</a>
      </ui-button-slide>
    </div>
  </listen-distance-from-top>
</template>

<script>
import * as api from '@/api/index';
import { mapActions, mapGetters, mapState } from "vuex";
import ListenDistanceFromTop from "@/components/listen-distance-from-top.vue";
import UiButtonSlide from "@/components/ui-button-slide.vue";
import Swiper from "swiper";

export default {
  name: "SectionNews",
  components: { ListenDistanceFromTop, UiButtonSlide },
  data() {
    return {
      newsData: '',
      imgSwiper: '',
      tabNavList: ['latest'],
      subTab: 'latest',
      newsKey: ['carousel', 'latest', 'log', 'announcement', 'activity'],
      inViewed: false,
      showElementDelay: false
    }
  },
  computed: {
    ...mapState([
      "data_detail",
      "data_scrollTop_listen",
      "data_size_listen",
      "navs"
    ]),
    subNews() {
      let data = '';
      if(this.newsData && Object.keys(this.newsData).length != 0) {
        let firstKey = Object.keys(this.newsData)[0];
        let defaultData = this.newsData['en'] || this.newsData[firstKey];

        if(this.newsData[this.$i18n.locale]) {
          data = this.newsData[this.$i18n.locale]

          // 有对应语言，但详细信息未配置，默认使用英文
          this.newsKey.forEach(tab => {
            if(!data[tab] || data[tab].length == 0) {
              data[tab] = defaultData[tab]
            }
          })
        }else {
          data = defaultData;
        }
      }

      return data;
    },
    showElement() {
      let thisNav = this.navs.find(item => item.id == 'news');
      if(thisNav['fromTop'] == 0) return false;

      let inView = thisNav.fromTop < this.data_scrollTop_listen + this.data_size_listen.screenHeight;
      if(inView) {
        this.inViewed = true
      }

      return (inView || this.inViewed);
    },
  },
  watch: {
    showElement(newVal, oldVal) {
      if(newVal && newVal != oldVal) {
        setTimeout(() => {
          this.showElementDelay = true;
          this.$nextTick(() => {
            this.initSwiper();
          });
        }, 600)
      }
    }
  },
  async created() {
    let res = await api.getNewsCn({
      t: new Date().getTime()
    });
    if(res.status == 200) {
      if(!res.data || Object.keys(res.data).length == 0) return;

      Object.keys(res.data).forEach((key) => {
        let arr_log = res.data[key]['log'] || [],
            arr_announcement = res.data[key]['announcement'] || [],
            arr_activity = res.data[key]['activity'] || [];
        let allNews = [...arr_log, ...arr_announcement, ...arr_activity];

        // 排序 - 1.倒序 2.时间排序
        allNews.sort(function(a, b) {
          let time_a = new Date(a.time);
          let time_b = new Date(b.time);
          return time_b.getTime() - time_a.getTime(); 
        })

        let topNews = [],
            otherNews = [];
        allNews.forEach(item => {
          if(item.top) {
            topNews.push(item)
          }else {
            otherNews.push(item)
          }
        });

        res.data[key]['latest'] = [...topNews, ...otherNews];
      })

      this.newsData = res.data;
    }
  },
  methods: {
    countGa() {},
    initSwiper() {
      this.imgSwiper = new Swiper(".swiper-container.news-swiper", {
        autoplay: true,
        pagination: {
          el: ".swiper-container.news-swiper .swiper-pagination",
          clickable: true,
        },
      });
    },
    sortTime(a, b) {
      if(!a.time || !b.time) return;

      let aTime = new Date(a.time);
      let bTime = new Date(b.time);
      if (!aTime || aTime == 'Invalid Date' || !bTime || bTime == 'Invalid Date') return;

      return bTime.getTime() - aTime.getTime();
    }
  },
};
</script>

<style lang="scss" scoped>
.section-news {
  position: relative;
  height: 57.875vw;
  overflow: hidden;
  z-index: 2;
  .wrapper {
    width: vwPc(2840px);
    margin: 0 auto;
    padding-top: vwPc(468px);
    > .title {
      margin-bottom: vwPc(140px);
      font-size: vwPc(120px);
      color: #fff;
      font-weight: bolder;
    }
    .news-box {
      margin: 0 auto vwPc(150px);
      width: 100%;
      height: vwPc(720px);
      display: flex;
      justify-content: space-between;
      border: vwPc(4px) solid #59AEE7;

      .swiper-container {
        width: vwPc(1280px);
        height: vwPc(720px);
        position: relative;
        .swiper-slide {
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
          .img {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        ::v-deep .swiper-pagination {
          width: auto;
          right: 1vw;
          left: auto;

          .swiper-pagination-bullet {
            width: vwPc(24px);
            height: vwPc(24px);
            border-radius: 0;
            background-color: #FFFFFF;
            opacity: 1;
            margin: 0 vwPc(18px);
            -webkit-tap-highlight-color: transparent;
            &.swiper-pagination-bullet-active {
              width: vwPc(48px);
              background-color: #FFC221;
            }
          }
        }
      }

      .news-tab {
        width: vwPc(1456px);
        padding: 0 vwPc(50px);
        border-left: vwPc(4px) solid #59AEE7;
        background: rgba(20, 72, 129, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .nav-box {
          width: vwPc(1456px);
          height: vwPc(122px);
          border-bottom: vwPc(2px) solid #2C77C9;
          margin-bottom: vwPc(12px);
          overflow-x: auto;
          overflow-y: hidden;
        }
        .tab-nav {
          display: -webkit-inline-box;
          align-items: center;
          height: 100%;
          color: #fff;
          font-weight: bold;
          font-size: vwPc(36px);
          li {
            position: relative;
            height: 100%;
            padding: 0 vwPc(20px);
            margin-right: vwPc(40px);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            &:last-child {
              margin: 0;
            }
            
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: vwPc(8px);
              background-color: #FFCA43;
              opacity: 0;
            }

            &.active {
              color: #FFCA43;
              &:after {
                opacity: 1;
              }
            }
          }
        }
        .article-list {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          li {
            height: vwPc(114px);
            border-bottom: vwPc(2px) solid #2C77C9;
            padding: 0 vwPc(35px);
            -webkit-tap-highlight-color: transparent;
            &:last-child {
              border: none;
            }
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .title {
                font-size: vwPc(32px);
                color: #fff;
                transition: opacity ease-in-out 200ms;
                width: 30vw;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &.isTop {
                  color: #ff4242;
                  font-weight: bolder;
                }
              }
              .time {
                font-size: vwPc(28px);
                color: #59AEE7;
              }

              &:hover {
                .title {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
    .more {
      position: relative;
      margin: 0 auto;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

@media screen and (max-width: 750px) {
  .section-news {
    height: vwApp(1700px);
    .wrapper {
      width: 100%;
      padding-top: vwApp(306px);
      > .title {
        width: vwApp(638px);
        margin: 0 auto vwApp(60px);
        font-size: vwApp(60px);
      }
      .news-box {
        width: 100%;
        width: vwApp(650px);
        height: vwApp(852px);
        flex-direction: column;

        .swiper-container {
          width: 100%;
          height: vwApp(366px);

          ::v-deep .swiper-pagination {
            right: 1vw;

            .swiper-pagination-bullet {
              width: vwApp(24px);
              height: vwApp(24px);
              margin: 0 vwApp(12px);
              &.swiper-pagination-bullet-active {
                width: vwApp(48px);
              }
            }
          }
        }

        .news-tab {
          flex: 1;
          width: vwApp(600px);
          padding: 0 vwApp(25px);
          border-left: none;
          border-top: vwApp(4px) solid #59AEE7;
          .nav-box {
            width: vwApp(600px);
            height: vwApp(83px);
            border-bottom: vwApp(2px) solid #2C77C9;
            margin-bottom: vwApp(8px);
          }
          .tab-nav {
            font-size: 2.6vw;
            li {
              padding: 0 vwApp(10px);
              margin-right: 1vw;
              white-space: nowrap;
              
              &:after {
                content: '';
                height: vwApp(5px);
              }
            }
          }
          .article-list {
            li {
              height: vwApp(77px);
              border-bottom: vwApp(2px) solid #2C77C9;
              padding: 0 vwApp(12px);
              a {
                .title {
                  font-size: 2.6vw;
                  width: 58vw;
                }
                .time {
                  font-size: 2.4vw;
                }
              }
            }
          }
        }
      }
    }

    // 隐藏滚动条
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
}
</style>