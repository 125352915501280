import anime from 'animejs'

// 角色介绍动画
const introduce = ($el, hero) => {
    let animate = anime.timeline({
        autoplay: false
    })

    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .bg-role`,
        opacity: {
            value: [0, 1],
            duration: 300
        },
        translateX: ['-30vw', 0],
        translateY: ['40vw', 0],
        duration: 800,
        scale: {
            value: [1.05, 1],
            easing: 'linear',
            duration: 2400,
            delay: 500
        }
    })
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .role`,
        opacity: [0, 1],
        translateX: {
            value: ['-20vw', 0],
            duration: 500
        },
        scale: {
            value: [0.95, 1],
            easing: 'linear',
            duration: 2500
        }
    }, 500)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .name-box .name-en`,
        opacity: [0, 1],
        translateX: ['10vw', 0],
        easing: 'easeInOutQuint'
    }, 1000)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .name-box .line`,
        opacity: [0, 1],
        translateX: ['10vw', 0],
        easing: 'easeInOutQuint'
    }, 1200)
    animate.add({
        targets: `${$el} .hero-introduce .${hero.name} .name-box .name-ch`,
        opacity: [0, 1],
        translateX: ['10vw', 0],
        easing: 'easeInOutQuint'
    }, 1400)
    animate.add({
        targets: `.fix-bottom .${hero.name} .skill-btns li`,
        opacity: [0, 1],
        scale: [0, 1],
        easing: 'easeInOutQuint',
        delay: anime.stagger(80)
    }, 1600)
    animate.add({
        targets: `.fix-bottom .${hero.name} .video-box`,
        opacity: [0, 1],
        scale: [0, 1],
        easing: 'easeInOutQuint',
        delay: anime.stagger(80)
    }, 1600)


    return animate
}


// 视频展开动画
const bdVideo = (role, videoBoxID, translateX) => {
    let animate = anime.timeline({
        easing: 'easeInOutSine',
        autoplay: false
    })
    animate.add({
        targets: `.role-${role} .pointBox[name="${videoBoxID}"] .point`,
        opacity: {
            value: [0, 1],
            duration: 100
        },
        translateX: {
            value: [translateX, 0],
            duration: 100,
            delay: 50
        },
        width: {
            value: [0, 342],
            duration: 100,
            // delay: 50
        },
        height: {
            value: [0, 188],
            duration: 200,
            delay: 150
        },
    }, 100)
    animate.add({
        targets: `.role-${role} .pointBox[name="${videoBoxID}"] .video .triangle-1`,
        top: [0, -45],
        opacity: {
            value: [0, 1],
            duration: 50
        },
        duration: 200
    })
    animate.add({
        targets: `.role-${role} .pointBox[name="${videoBoxID}"] .video .triangle-2`,
        bottom: [0, -45],
        opacity: {
            value: [0, 1],
            duration: 50
        },
        duration: 200
    }, '-=200')

    return animate
}

// BD树 - rehan
const rehanBD = pointList => {
    let stepPxDuration = 3
    let animate = anime.timeline({
        easing: 'linear',
        autoplay: false
    })

    pointList.forEach(item => {
        animate.add({
            targets: `.role-1 .pointBox[name="${item.id}"] .line`,
            height: [0, `${item.lineHeight}px`],
            duration: item.lineHeight * stepPxDuration,
        }, item.offset * stepPxDuration)
    })

    return animate
}


// BD树 - carino
const carinoBD = pointList => {
    let stepPxDuration = 3
    let animate = anime.timeline({
        easing: 'linear',
        autoplay: false
    })

    pointList.forEach(item => {
        animate.add({
            targets: `.role-2 .pointBox[name="${item.id}"] .line`,
            height: [0, `${item.lineHeight}px`],
            duration: item.lineHeight * stepPxDuration,
        }, item.offset * stepPxDuration)
    })

    return animate
}

// BD树 - gemma
const gemmaBD = pointList => {
    let stepPxDuration = 3
    let animate = anime.timeline({
        easing: 'linear',
        autoplay: false
    })

    pointList.forEach(item => {
        animate.add({
            targets: `.role-3 .pointBox[name="${item.id}"] .line`,
            height: [0, `${item.lineHeight}px`],
            duration: item.lineHeight * stepPxDuration,
        }, item.offset * stepPxDuration)
    })

    return animate
}


export {
    introduce,
    bdVideo,
    rehanBD,
    carinoBD,
    gemmaBD
}