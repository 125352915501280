<template>
  <div class="decorate-box">
    <div class="decorate1"></div>
    <div class="decorate2"></div>
  </div>
</template>

<script>
export default {
  name: "TheDecorate",
};
</script>

<style lang="scss" scoped>
.decorate-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  .decorate1 {
    @include section_bg("overseas_pc_decorate1");
    width: get_vw(513px);
    height: get_vw(601px);
    position: absolute;
    top: 3.125vw;
    left: 0;
    z-index: 1;
    pointer-events: none;
    transition: all ease-out 500ms;
    backface-visibility: hidden;
  }
  .decorate2 {
    @include section_bg("overseas_pc_decorate2");
    width: get_vw(646px);
    height: get_vw(738px);
    position: absolute;
    bottom: -5vw;
    right: 0;
    z-index: 1;
    pointer-events: none;
    transition: all ease-out 500ms;
  }
}

@media screen and (max-width: 750px) {
  .decorate-box {
    display: none;
  }
}
</style>