<template>
  <ul class="list-nav">
    <li
      v-for="(nav, i) in navs"
      :key="i"
      :class="{ active: nav.id == subNav }"
      @click="jumpTo(nav)"
    >
      {{ $t(`nav_${nav.id}`) }}
      <a v-if="nav.link" :href="nav.link" target="_blank"></a>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ListNav",
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          navs: [],
          listen: false,
          defaultID: ''
        }
      }
    }
  },
  data() {
    return {
      subNav: '',
      navs: [],
      listen: false
    };
  },
  computed: {
    ...mapState(["data_scrollTop_listen"])
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if(newVal != oldVal) {
          this.navs = newVal['navs'] || [];
          this.listen = newVal['listen'] || false;
          // 默认导航高亮
          if(newVal.defaultID) {
            this.subNav = newVal.defaultID
          }else {
            if(this.navs && this.navs.length != 0) {
              this.subNav = this.navs[0].id;
            }
          }
          // 设置监听
          if(this.listen) {
            this.initListen();
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    jumpTo(nav) {
      if (nav.link) return;

      this.subNav = nav.id;
      window.scrollTo({
        top: nav.fromTop,
        behavior: "smooth",
      });
    },
    initListen() {
      this.$watch('data_scrollTop_listen', (scrollTop) => {
        if(scrollTop >= this.navs[0].fromTop && scrollTop < this.navs[1].fromTop * 0.5) {
          this.subNav = this.navs[0].id
        }else if(scrollTop >= this.navs[1].fromTop * 0.5 && scrollTop < this.navs[1].fromTop + (this.navs[2].fromTop - this.navs[1].fromTop) * 0.5) {
          this.subNav = this.navs[1].id
        }else if(scrollTop >= this.navs[2].fromTop * 0.5 && scrollTop < this.navs[2].fromTop + (this.navs[3].fromTop - this.navs[2].fromTop) * 0.5) {
          this.subNav = this.navs[2].id
        }else {
          this.subNav = this.navs[3].id
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
$hover-color-text: #ffffff;

.list-nav {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: #ababab;
  height: 100%;
  font-weight: 700;
  // font-style: italic;
  li {
    padding: 0 0.10417vw;
    height: 100%;
    line-height: 3.125vw;
    margin: 0 vwPc(15px);
    text-align: center;
    color: #ababab;
    letter-spacing: 0.1px;
    transition: all ease-in-out 200ms;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    a {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #ffb833;
      position: absolute;
      left: 0;
      bottom: 0.625vw;
      opacity: 0;
      transition: all ease-in-out 200ms;
    }

    &:hover {
      color: $hover-color-text;
    }
    &.active {
      color: #ffb833;
      &::before {
        opacity: 1;
      }
    }
    &.isHide {
      display: none;
    }
  }
}

@media screen and (max-width: 750px) {
  .list-nav {
    color: #ababab;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    padding-right: vwApp(40px);
    li {
      height: vwApp(64px);
      line-height: vwApp(64px);
      text-align: right;
      &::before {
        display: none;
      }
    }
  }
}
</style>