<template>
  <div id="single-container">
    <div class="bg"></div>
    <the-header site="news" />
    <div class="wrapper">
      <div class="article" v-html="article"></div>
    </div>
    <the-footerTW v-if="region == 'TW' || $i18n.locale == 'zh-tw'" />
    <the-footer v-else :showMedia="false" />
  </div>
</template>

<script>
import * as api from '@/api/index';
import { getRegion } from "@/utils/getLocale";
import TheHeader from "@/components/the-header.vue";
import TheFooter from "@/components/the-footer.vue";
import TheFooterTW from "@/components/the-footer-tw.vue";
// import _ajax from '@/api/ajax';


export default {
  components: {
    TheHeader,
    TheFooter,
    TheFooterTW
  },
  data() {
    return {
      domain: 'https://xd-website.oss-accelerate-overseas.aliyuncs.com',
      region: getRegion(),
      article: '',
      tempLang: '',
      supportLocales: []
    }
  },
  watch: {
    '$i18n.locale': {
      handler(newVal, oldVal) {
        this.init(newVal);
      }
    }
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))

    if(!this.tempLang) {
      this.init(this.$i18n.locale);
    }
  },
  methods: {
    init(lang_) {
      let id = this.$route.query?.id || '';
      if(!id) return;

      this.supportLocales = JSON.parse(localStorage.getItem(`${id}-locales`)) || [];
      let lang = lang_;
      if(this.supportLocales.length != 0 && !this.supportLocales.includes(lang)) {
        lang = this.supportLocales.includes('en') ? 'en' : this.supportLocales[0];
      }
      this.reqArticle(lang, id);
    },
    async reqArticle(lang, id) {
      if(lang == this.tempLang) return;
      this.tempLang = lang;

      this.article = 'loading...';
      let res = await api.getNewSingle({
        lang,
        id,
        t: new Date().getTime()
      });
      if(res.status == 200) {
        this.article = this.$lg(res, 'data.content', '');
        this.supportLocales = this.$lg(res, 'data.locales', []);
        localStorage.setItem(`${id}-locales`, JSON.stringify(this.supportLocales));
      }else {
        if(this.tempLang == 'en') {
          this.article = this.$t('tip_news_not_found');
          return;
        };

        // 如请求失败，可能是由于该语言未配置的原因，默认再请求一次英文
        let resAgain = await api.getNewSingle({
          lang: 'en',
          id,
          t: new Date().getTime()
        });
        if(resAgain.status == 200) {
          this.article = this.$lg(resAgain, 'data.content', '');
          this.supportLocales = this.$lg(resAgain, 'data.locales', []);
          localStorage.setItem(`${id}-locales`, JSON.stringify(this.supportLocales));
        }else {
          this.article = this.$t('tip_news_not_found');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#single-container {
  position: relative;
  width: 100%;
  background-color: #071D3F;
  overflow: hidden;
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include section_bg_single("overseas_pc_bg");
    width: 100vw;
    height: 100vh;
  }
  .wrapper {
    position: relative;
    width: 1100px;
    min-height: 100vh;
    padding: 40px;
    background: rgba(20, 72, 129, 0.5);
    border: vwPc(4px) solid #59AEE7;
    margin: 6vw auto;
    box-sizing: border-box;
    .article {
      width: 100%;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #e0dede;

      div {
        line-height: 24px;
      }
      ::v-deep table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        tr {
          th, td {
            border:1px solid #484849;
            padding: 5px 0;
            text-align: center;
          }
        }
      }

      ::v-deep a {
        text-decoration: underline;
        color: #ff6600;
      }
      ::v-deep p {
        line-height: 24px;
        margin: 5px 0;
      }
      ::v-deep li {
        margin-bottom: 10px;
      }
      ::v-deep ul {
        padding-left: 20px;
        li {
          list-style: disc;
        }
      }
      ::v-deep ol {
        padding-left: 20px;
        li {
          list-style: decimal;
        }
      }
      ::v-deep img {
        max-width: 100%;
      }
      ::v-deep iframe {
        max-width: 100%;
        max-height: 47.8vw;
      }
    }
  }
}

@media screen and (max-width: 1120px) {  
  #single-container {
    .wrapper {
      width: 95%;
      padding: 4vw 5vw;
      .article {
        min-height: 110vh;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  #single-container {
    .bg {
      @include section_bg_single("overseas_mobile_bg");
      width: 100vw;
      height: 100vh;
    }
    .wrapper {
      margin: 13vw auto 6vw;
    }
  }
}
</style>