<template>
  <div v-show="showRegister" class="loginRegister">
    <div class="googleLogin bg">
      <div class="inner" v-show="loginInfo === null">
        <div class="msg-login">{{ $t("sign_up_title") }}</div>
        <div class="btn-login">
          <div class="logo"></div>
          <div class="text">{{ $t("sign_up_login") }}</div>
          <div id="google-signin-button"></div>
        </div>
      </div>
      <!-- <div class="inner" v-show="loginInfo !== null && registerInfo === null">
        <ul class="device-select">
          <li v-for="(id, key) in device" :name="id" :key="key" @click="subDevice = id" :class="{active: subDevice == id}">{{ id }}</li>
        </ul>
        <div class="register-btn" @click="reqRegister"></div>
      </div> -->
      <div class="inner" v-show="loginInfo !== null && registerInfo !== null">
        <div class="success-icon"></div>
        <div class="msg-success">{{ $t("sign_up_success") }}</div>
      </div>
      <div class="loading" v-if="loading">{{ $t("sign_up_loading") }}</div>
      <div class="close" @click="close">×</div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/index";
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["loginInfo", "registerInfo", "showRegister"]),
  },
  data() {
    return {
      id_token: "",
      device: ["ios", "android"],
      subDevice: "",
      info_: null,
      loading: false,
    };
  },
  // mounted() {
    // this.$nextTick(() => {
      // this.initLogin()

      // google 登录
      // gapi.signin2.render("google-signin-button", {
      //   onsuccess: this.onSignIn,
      // });
    // });
  // },
  methods: {
    ...mapMutations(["setLoginInfo", "setRegisterInfo", "setRegisterShow"]),
    onSignIn(googleUser) {
      this.id_token = googleUser.getAuthResponse().id_token;
      let profile = googleUser.getBasicProfile();
      this.info_ = {
        sub: profile.getId(),
        name: profile.getName(),
        picture: profile.getImageUrl(),
        email: profile.getEmail(),
      };
      // 获取用户预约状态
      this.getInfo();
    },
    getInfo() {
      this.loading = true;
      let query = {
        id_token: this.id_token,
        activity_code: "2022-7-23",
        app_id: 227017,
        app_name: "torchlight",
        google_client_id: 1,
      };
      api.getRegisterInfo(query).then((response) => {
        this.loading = false;
        if (response.status == 200) {
          let data = response.data;
          this.setRegisterInfo(data);
          if (!data["pre-register"]) {
            // 未预约
            this.reqRegister();
          }
        } else {
          alert(response.data);
        }
        // 等待判断用户是否预约完毕后 - 写入登录状态（以免请求间隙，误显示预约窗口）
        this.setLoginInfo(this.info_);
      });
    },
    reqRegister() {
      this.loading = true;
      let parmas = {
        id_token: this.id_token,
        activity_code: "2022-7-23",
        app_id: 227017,
        app_name: "torchlight",
        channel: "",
        ua: navigator.userAgent,
        google_client_id: 1,
      };
      api.reqRegister(parmas).then((result) => {
        if (result.status == 200) {
          let data = result.data;
          this.setRegisterInfo(data);

          this.countGa("signed");
        } else {
          alert(result.data);
        }

        this.loading = false;
      });
    },
    // signOut() {
    //   var auth2 = gapi.auth2.getAuthInstance();
    //   auth2.signOut().then(() => {
    //     this.setLoginInfo(null);
    //     this.setRegisterInfo(null);
    //   });
    // },
    close() {
      this.setRegisterShow(false);
    },
    countGa(type) {
      if (window.innerWidth > 750) {
        window.ga("send", "event", "torchlight", "success", `pc_${type}`);
      } else {
        window.ga("send", "event", "torchlight", "success", `mobile_${type}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loginRegister {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .bg {
    @include sprite_bg("overseas_register_bg");
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    @media screen and (max-width: 360px) {
      & {
        transform-origin: center top;
        transform: translate3d(-50%, 0, 0) scale(0.8);
      }
    }
    .inner {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .btn-login {
        position: relative;
        margin: 20px auto 0;
        width: 260px;
        height: 53px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 27px;
        .logo {
          width: 32px;
          height: 32px;
          background-image: map-get(
            $global-var,
            "overseas_add2022_logo_google-o"
          );
          @include bgSet;
          margin: 0 17px;
          flex-shrink: 0;
        }
        .text {
          color: #000;
          font-size: 14px;
          margin-right: 14px;
          flex: 1;
          text-align: center;
        }
        #google-signin-button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // @include sprite_bg("overseas_register_login");
          ::v-deep .abcRioButtonLightBlue {
            width: 259px !important;
            height: 53px !important;
            opacity: 0;
          }
        }
      }
      .success-icon {
        @include sprite_bg("overseas_register_success");
        margin: 57px auto 15px;
      }
      .device-select,
      .msg-login,
      .msg-success {
        width: 100%;
        color: #ffb833;
        font-size: 18px;
        text-align: center;
      }
      .msg-login {
        margin: 58px auto 0;
      }
      .device-select {
        display: flex;
        justify-content: space-around;
        @include sprite_bg("overseas_register_device");
        margin: 61px auto 41px;
        font-size: 14px;
        li {
          width: 150px;
          line-height: 30px;
          text-align: center;
          color: #666;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          &.active {
            color: #ffb833;
          }
        }
      }
      .register-btn {
        @include sprite_bg("overseas_register_signUp");
        margin: 0 auto;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
    }
    .close {
      color: #fff;
      font-weight: bolder;
      font-size: 30px;
      position: absolute;
      top: 0px;
      right: 10px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
</style>