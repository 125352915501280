<template>
  <transition name="fade">
    <div
      class="header clearfix"
      :class="{ fixed: data_scrollTop_listen > 0 || site == 'news' }"
    >
      <div class="logo fl"></div>
      <div
        class="more fr"
        :class="{ active: showNav }"
        @click="handleAppNav"
      ></div>
      <!-- 多语言选择 -->
      <!-- <the-locale
        class="locale-app fr"
        ref="localeApp"
        @localesState="showNav = false"
      /> -->
      <div
        v-show="showNav || $lg(data_size_listen, 'screenWidth', 0) > 750"
        class="header-info fr"
      >
        <!-- 导航列表 -->
        <div class="header-left">
          <slot name="nav"></slot>
          <div class="media-dropdown">
            <div class="holder">
              {{ $t('nav_media') }}
              <div class="icon"></div>
            </div>
            <ul class="dropdown-list">
              <div class="inner">
                <template v-for="(media, key) in mediaList">
                  <li v-if="formatMediaLink(media)" :key="key" :name="media">
                    {{ getMediaLocale(media) }}
                    <div v-if="media == 'WeChat'" class="QR-code-box">
                      <div class="QR-code" :style="{ 'background-image': `url(${formatMediaLink(media)})` }"></div>
                    </div>
                    <a v-else :href="formatMediaLink(media)" target="_blank"></a>
                  </li>
                </template>
              </div>
            </ul>
          </div>
        </div>
        <div class="side-bar">
          <!-- 登陆信息 -->
          <!-- <div class="loginInfo" v-if="loginInfo">
            <div
              class="head"
              :style="{
                'background-image': `url(${$lg(loginInfo, 'picture', '')})`,
              }"
            ></div>
            <div class="logOut" @click="$emit('signOut')">
              {{ $t("sign_up_logout") }}
            </div>
          </div> -->
          <!-- 媒体列表 -->
          <!-- <list-media class="media-nav" site="in-nav"></list-media> -->
          <!-- 多语言选择 -->
          <!-- <the-locale class="locale-pc" /> -->
          <!-- 状态按钮 -->
          <!-- 抽奖页：https://www.taptap.cn/poster-activity/NBfIjQQnS37O -->
          <!-- 游戏详情页：https://www.taptap.cn/app/172664 -->
          <btn-entry
            class="reserve"
            color="yellow"
            :href="getReserveHref()"
          >
            立即预约
          </btn-entry>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
// import ListMedia from "@/components/list-media.vue";
import BtnEntry from "@/components/btn-entry-new.vue";
// import TheLocale from "@/components/the-locale-2.vue";

export default {
  name: "TheHeader",
  components: { BtnEntry },
  props: {
    site: {
      type: String,
      default: () => 'main'
    }
  },
  data() {
    return {
      showNav: false,
      navs_demestic: [],
      navID: ''
    };
  },
  computed: {
    ...mapState([
      "loading",
      "data_scrollTop_listen",
      "data_detail",
      "data_size_listen",
      "navs",
      "navs_news",

      "showRegister",
      "loginInfo",
      "guideShow",
      "inTargetCountry",
    ]),
    mediaList() {
      return this.$t("media_list").split(",") || [];
    },
  },
  created() {
    this.$i18n.locale = 'zh-cn';
  },
  methods: {
    formatMediaLink(media) {
      const _t = this.$t(`media_${media}`);
      if (_t == `media_${media}`) {
        return "";
      }
      return _t;
    },
    getMediaLocale(media) {
      if(this.$i18n.locale == 'zh-cn') {
        let name = media;
        if(media == 'Weibo') {
          name = '微博'
        }else if(media == 'Bilibili') {
          name = '哔哩哔哩' 
        }else if(media == 'WeChat') {
          name = '微信'
        }

        return name;
      }else {
        return media;
      }
    },
    handleAppNav() {
      this.showNav = !this.showNav;
      // this.$refs.localeApp.hide();
    },
    getReserveHref() {
      let os = this.$store.state.os;
      let href = ''
      if(os.isIpad || os.isIPhone || os.isMac) {
        href = 'https://apps.apple.com/cn/app/id1528917194';
      }else {
        href = 'https://l.tapdb.net/q3ze8FEZ';
      }

      return href;
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  height: 3.125vw;
  font-size: 0.9375vw;
  z-index: 10;
  .logo {
    width: vwPc(284px);
    height: vwPc(249px);
    background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/7fSrsQeT.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    margin: 1.2vw 0 0 1vw;
  }
  .more,
  .locale-app {
    display: none;
  }
  .header-info {
    width: vwPc(3100px);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin: vwPc(22px) vwPc(180px) 0 0;
    .header-left {
      display: flex;
    }
    .media-dropdown {
      position: relative;
      margin-left: vwPc(22px);
      &:hover {
        .dropdown-list {
          opacity: 1;
          visibility: visible;
        }
      }
      .holder {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        color: #ababab;
        letter-spacing: 0.1px;
        font-weight: 700;
        // font-style: italic;
        font-size: vwPc(36px);
        display: flex;
        align-items: center;
        height: 100%;
        line-height: 3.125vw;
        transition: all ease-in-out 200ms;
        &:hover {
          color: #fff;
        }
        .icon {
          width: vwPc(24px);
          height: vwPc(11px);
          background-image: url('https://website.xdcdn.net/homepage/torchlight/imgs/overseas/add2022/arrow.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 0.18229vw;
        }
      }
      .dropdown-list {
        position: absolute;
        width: vwPc(328px);
        top: vwPc(120px);
        left: 50%;
        margin-left: vwPc(-0.5 * 328px);
        opacity: 0;
        visibility: hidden;
        padding-top: vwPc(20px);
        .inner {
          background-color: rgba(8, 15, 26, 0.9);
          border-radius: vwPc(6px);
          font-size: vwPc(28px);
          padding: vwPc(20px) 0;
        }
        li {
          position: relative;
          height: vwPc(70px);
          line-height: vwPc(70px);
          color: #ABABAB;
          padding-left: vwPc(30px);
          padding-right: vwPc(30px);
          white-space: nowrap;
          cursor: pointer;
          transition: all ease-in-out 200ms;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          &:hover {
            color: #fff;
          }

          &.active {
            color: #ffc221;
          }

          &[name="WeChat"]:hover {
            .QR-code-box {
              opacity: 1;
              visibility: visible;
            }
          }

          .QR-code-box {
            position: absolute;
            top: vwPc(-20px);
            left: vwPc(335px);
            width: vwPc(240px);
            height: vwPc(240px);
            background-color: rgba(8, 15, 26, 0.9);
            border-radius: vwPc(6px);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            visibility: hidden;
            .QR-code {
              width: vwPc(178px);
              height: vwPc(178px);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
    .side-bar {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .loginInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head {
          width: 1.66667vw;
          height: 1.66667vw;
          background-color: gray;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 1vw;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .logOut {
          color: #ababab;
          line-height: 1.8vw;
          cursor: pointer;
        }
      }
      .media-nav {
        margin-left: vwPc(50px);
      }
      .locale-pc {
        display: block;
      }
      .reserve {
        margin-left: vwPc(50px);
      }
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    background-color: #040a14;
    .logo {
      width: 2.47396vw;
      height: 2.26563vw;
      margin: 0.3625vw 0 0 0.625vw;
    }
    .header-info {
      width: vwPc(3450px);
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 10vw;
    font-size: 4vw;
    z-index: 50;
    .logo {
      width: vwApp(98px);
      height: vwApp(86px);
      margin: 1.2vw 0 0 1.3vw;
    }
    .more {
      display: block;
      @include bg("mobile_header_more");
      margin: 2.1333vw 6.6667vw 0 0;
      position: relative;
      &::before {
        content: "";
        @include sprite_absolute($top: 0px, $left: 0px);
        @include bg("mobile_header_more_active");
        opacity: 0;
        transition: all ease-in-out 100ms;
      }

      &.active {
        &::before {
          opacity: 1;
        }
      }
    }
    .locale-app {
      display: block;
    }

    .header-info {
      width: vwApp(368px);
      flex-direction: column;
      align-items: flex-end;
      margin: 0;

      position: absolute;
      top: 9.6vw;
      right: 0;
      background-color: #040a14;
      .media-dropdown {
        display: none;
      }
      .side-bar {
        .loginInfo {
          flex-direction: row-reverse;
          padding-right: 5.33333vw;
          height: vwApp(64px);
          line-height: vwApp(64px);
          margin-top: vwApp(45px);
          .head {
            width: vwApp(48px);
            height: vwApp(48px);
            background-color: gray;
            margin-right: 0;
            margin-left: vwApp(15px);
          }
          .logOut {
            color: #ababab;
            line-height: 1.8vw;
            cursor: pointer;
          }
        }
        .media-nav,
        .reserve,
        .locale-pc {
          display: none;
        }
      }
    }

    &.fixed {
      position: fixed;
      top: 0;
      background-color: #040a14;
      .logo {
        width: vwApp(55px);
        height: vwApp(55px);
        margin: 1.5vw 0 0 3.4667vw;
      }

      .header-info {
        width: vwApp(368px);
      }
    }
  }
}
</style>