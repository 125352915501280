import * as PIXI from 'pixi.js'
import {Spine} from 'pixi-spine'

const createSpine = (options, callback) => {
    const app = new PIXI.Application({
        width: options.size.width,
        height: options.size.height,
        backgroundAlpha: 0,
        autoStart: false
    });
    app.view.style.width = '100%';
    app.view.style.height = '100%';
    // document.getElementById(options.appendEleID).appendChild(app.view);

    app.loader
        .add('role', options.jsonUrl)
        .load((loader, res) => {
            let role = new Spine(res.role.spineData);
            role.skeleton.setToSetupPose();
            role.update(0);
            role.position.set(options.position.x, options.position.y);
            role.scale.set(options.scale, options.scale);

            app.stage.addChild(role);

            role.state.setAnimation(0, options.animation, true);

            // 开启回调
            callback(app)
        });
}

export default createSpine;