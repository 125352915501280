<template>
  <div class="section-features">
    <div
      class="features"
      v-for="(item, key) in features"
      :key="key"
      :name="item.id"
      :class="{ active: item.id == subFeature }"
    >
      <div class="slogan" v-html="$t(`feature${item.id}_title`)"></div>
      <div class="des" v-html="$t(`feature${item.id}_des`)"></div>
      <img class="img" v-lazy="$t(`feature${item.id}_img`)" :key="item.id" :alt="$t(`feature${item.id}_btn`)">
    </div>
    <ul class="switch-list">
      <li
        v-for="(item, key) in features"
        :key="key"
        :class="{ active: subFeature == item.id }"
        @click="subFeature = item.id"
      >
        {{ $t(`feature${item.id}_btn`) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    features: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      subFeature: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.section-features {
  position: relative;
  width: vwPc(1920px, $ratio: 1);
  height: vwPc(1080px, $ratio: 1);
  .features {
    width: 100%;
    height: vwPc(546px, $ratio: 1);
    position: absolute;
    top: vwPc(265px, $ratio: 1);
    visibility: hidden;
    opacity: 0;
    .slogan {
      position: absolute;
      top: vwPc(123px, $ratio: 1);
      left: vwPc(244px, $ratio: 1);
      opacity: 0;
      transform: translate3d(-80px, 0, 0);
      white-space: pre-wrap;
      font-size: vwPc(100px);
      line-height: vwPc(130px);
      color: #fff;
      font-weight: 700;
    }
    .img {
      position: absolute;
      top: 0;
      right: vwPc(155px, $ratio: 1);
      opacity: 0;
      transform: translate3d(vwPc(80px, $ratio: 1), 0, 0);

      width: 45.98958vw;
      height: 28.4375vw;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .des {
      width: vwPc(662px, $ratio: 1);
      position: absolute;
      top: vwPc(314px, $ratio: 1);
      left: vwPc(250px, $ratio: 1);
      color: #ffc221;
      font-size: vwPc(36px);
      line-height: vwPc(52px);
      opacity: 0;
      transform: translate3d(vwPc(-80px, $ratio: 1), 0, 0);
    }

    // @for $i from 1 through 5 {
    //   &[name="#{$i}"] {
    //     .img {
    //       @include bg("overseas_pc_section6_img#{$i}");
    //     }
    //   }
    // }

    &.active {
      visibility: visible;
      opacity: 1;
      .slogan {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: all ease-out 400ms;
      }
      .des {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: all ease-out 400ms;
      }
      .img {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: all ease-out 400ms;
      }
    }
  }

  .switch-list {
    width: vwPc(670px, $ratio: 1);
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: vwPc(336px, $ratio: 1);
    left: vwPc(244px, $ratio: 1);
    color: #ffc221;
    font-size: vwPc(24px);
    line-height: vwPc(56px);
    letter-spacing: 0.1px;
    li {
      @include bg("overseas_pc_section6_btn");
      position: relative;
      text-align: center;
      line-height: vwPc(30px, $ratio: 1);
      cursor: pointer;
      transition: all ease-in-out 200ms;
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include bg("overseas_pc_section6_btn_hover");
        opacity: 0;
        z-index: -1;
        transition: all ease-in-out 200ms;
      }
      &:hover {
        &::after {
          opacity: 1;
        }
        color: #252525;
      }

      &.active {
        &::after {
          opacity: 1;
        }
        color: #252525;
      }
    }
  }
}
</style>