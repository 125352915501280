<template>
  <div class="section-features">
    <div class="swiper-container features-swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, key) in features"
          :key="key"
          :name="item.id"
        >
          <img class="img" :src="$t(`feature${item.id}_img`)" :alt="$t(`feature${item.id}_btn`)"/>
          <div class="slogan" v-html="$t(`feature${item.id}_title`)"></div>
          <div class="des" v-html="$t(`feature${item.id}_des`)"></div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  props: {
    features: {
      type: Array,
      default: () => [],
    },
    showElement: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      imgSwiper: ''
    }
  },
  watch: {
    showElement: {
      handler(newVal) {
        if(newVal) {
          this.$nextTick(() => {
            this.initSwiper();
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    initSwiper() {
      // let self = this
      this.imgSwiper = new Swiper(".swiper-container.features-swiper", {
        effect: "fade",
        autoplay: false,
        loop: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-container.features-swiper .swiper-pagination",
          clickable: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-features {
  position: relative;
  height: vwApp(1400px);
  overflow: hidden;
  .swiper-container {
    width: 100%;
    height: vwApp(940px); //1140
    position: relative;
    .swiper-slide {
      .img {
        opacity: 0;
        transform: translate3d(vwApp(60px), 0, 0);

        width: 100vw;
        height: 66.66667vw;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .slogan {
        margin: vwApp(20px) 0 0 vwApp(60px);
        opacity: 0;
        transform: translate3d(vwApp(60px), 0, 0);
        white-space: pre-wrap;
        font-size: vwApp(46px);
        color: #fff;
      }
      .des {
        display: none;
        width: vwApp(630px);
        height: vwApp(160px);
        margin: vwApp(80px) auto 0;
        @include fontEn;
        font-size: vwApp(28px);
        color: #ffc271;
        opacity: 0;
        transform: translate3d(vwApp(60px), 0, 0);
      }
      // @for $i from 1 through 5 {
      //   &[name="#{$i}"] {
      //     .img {
      //       @include bg("overseas_mobile_section6_img#{$i}");
      //     }
      //   }
      // }

      &.swiper-slide-active {
        .img {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: all ease-out 0.3s 0.1s;
        }
        .slogan {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: all ease-out 0.3s 0.2s;
        }
        .des {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: all ease-out 0.3s 0.3s;
        }
      }
    }

    ::v-deep .swiper-pagination {
      .swiper-pagination-bullet {
        @include bg("overseas_mobile_section6_nav");
        border-radius: 0;
        background-color: transparent;
        opacity: 1;
        position: relative;
        margin: 0 -2px;
        -webkit-tap-highlight-color: transparent;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          @include bg("overseas_mobile_section6_nav_hover");
          opacity: 0;
        }
        &.swiper-pagination-bullet-active {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>