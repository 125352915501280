<template>
  <div class="btn-entry" :class="color">
    <a :href="href" target="_blank" :download="type == 'download'">
      <slot></slot>
      <div class="icon"></div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => ''
    },
    href: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => 'yellow'
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-entry {
  height: vwPc(78px);
  border-radius: vwPc(16px);
  a {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 vwPc(40px) 0 vwPc(45px);
    font-size: vwPc(32px);
    color: #291A09;
    font-weight: bolder;
    font-family: 'Source Han Sans CN';
    .icon {
      width: vwPc(7px, 1);
      height: vwPc(9px, 1);
      background-image: url('https://website.xdcdn.net/homepage/torchlight/imgs/domestic/arrow.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: vwPc(7px);
    }
  }

  &.red {
    background: linear-gradient(180deg, #EF421C 0%, #EF811C 100%);
    box-shadow: inset 0px vwPc(-4px) 0px #EFA71C;
  }
  &.yellow {
    background: linear-gradient(180deg, #FD7317 -20.38%, #FFC220 42.36%);
    box-shadow: inset 0px vwPc(-4px) 0px #FFDC7D;
  }
  &:hover {
    opacity: 0.9;
  }
}
</style>