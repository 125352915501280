<template>
  <div id="news-list-container">
    <div class="bg"></div>
    <the-decorate />
    <the-header-demestic site="news">
      <template v-slot:nav>
        <list-nav :options="navOptions" />
      </template>
    </the-header-demestic>
    <div class="wrapper">
      <div class="title">{{ $t("title_news") }}</div>
      <div class="news-list">
        <div class="news-tab">
          <div class="nav-box">
            <ul class="tab-nav">
              <li
                v-for="(tab, key) in tabNavList"
                :key="key"
                :class="{active: tab == subTab}"
                @click="subTab = tab"
              >{{ $t(`tab-${tab}`) }}</li>
            </ul>
          </div>
          <ul v-if="subNews[subTab] && subNews[subTab].length != 0" class="article-list">
            <li v-for="(item, key) in subNews[subTab].slice(0, 10*this.subPage[subTab])" :key="key">
              <a :href="item.link" target="_blank">
                <div class="title" :class="{ isTop: item.top }">{{ item.title }}</div>
                <div class="time">{{ item.time }}</div>
              </a>
            </li>
          </ul>
        </div>
        <div v-if="
          subNews[subTab] &&
          subNews[subTab].length != 0 &&
          subPage[subTab] * 10 < subNews[subTab].length
        " class="more-box">
          <div
            class="more"
            @click="moreNews(subTab)"
          >{{ $t("btn_more") }}</div>
        </div>
        <!-- <Paging
          class="paging-container"
          v-if="subNews[subTab] && subNews[subTab].length != 0"
          :total="subNews[subTab].length"
          :subTab="subTab"
          @switchPage="switchPage"
        /> -->
        <div class="toTop" @click="toTop">
          <div class="icon">⌃</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/index';
import { getRegion } from "@/utils/getLocale";
import TheDecorate from "@/components/the-decorate.vue";
import TheHeaderDemestic from "@/components/domestic/the-header.vue";
import TheFooter from "@/components/the-footer.vue";
import TheFooterTW from "@/components/the-footer-tw.vue";
import ListNav from "@/components/domestic/list-nav.vue";
// import Paging from "@/components/paging.vue";

export default {
  components: { TheHeaderDemestic, TheDecorate, TheFooter, TheFooterTW, ListNav },
  data() {
    return {
      region: getRegion(),
      newsData: '',
      subTab: 'latest',
      tabNavList: ['latest', 'log', 'announcement', 'activity'],
      newsKey: ['latest', 'log', 'announcement', 'activity'],
      subPage: {
        latest: 1,
        log: 1,
        announcement: 1,
        activity: 1
      },
      navOptions: {
        navs: [],
        defaultID: 'news'
      }
    }
  },
  computed: {
    subNews() {
      let data = '';
      if(this.newsData && Object.keys(this.newsData).length != 0) {
        let firstKey = Object.keys(this.newsData)[0];
        let defaultData = this.newsData['en'] || this.newsData[firstKey];

        if(this.newsData[this.$i18n.locale]) {
          data = this.newsData[this.$i18n.locale]

          // 有对应语言，但详细信息未配置，默认使用英文
          this.newsKey.forEach(tab => {
            if(!data[tab] || data[tab].length == 0) {
              data[tab] = defaultData[tab]
            }
          })
        }else {
          data = defaultData;
        }
      }

      return data;
    }
  },
  created() {
    this.reqNews();
    this.initNav();
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  methods: {
    reqNews: async function() {
      let res = await api.getNewsCn({
        t: new Date().getTime()
      });
      if(res.status == 200) {
        if(!res.data || Object.keys(res.data).length == 0) return;

        Object.keys(res.data).forEach((key) => {
          let arr_log = res.data[key]['log'] || [],
              arr_announcement = res.data[key]['announcement'] || [],
              arr_activity = res.data[key]['activity'] || [];

          // --- 最新 ---
          let allNews = [...arr_log, ...arr_announcement, ...arr_activity];
          // 排序 - 1.倒序 2.时间排序
          allNews.sort(function(a, b) {
            let time_a = new Date(a.time);
            let time_b = new Date(b.time);
            return time_b.getTime() - time_a.getTime(); 
          })

          let topNews = [],
              otherNews = [];
          allNews.forEach(item => {
            if(item.top) {
              topNews.push(item)
            }else {
              otherNews.push(item)
            }
          });
          res.data[key]['latest'] = [...topNews, ...otherNews];

          // --- 开发者日志 ---
          arr_log.sort(function(a, b) {
            let time_a = new Date(a.time);
            let time_b = new Date(b.time);
            return time_b.getTime() - time_a.getTime(); 
          })
          let top_log = [], other_log = [];
          arr_log.forEach(item => {
            if(item.top) {
              top_log.push(item)
            }else {
              other_log.push(item)
            }
          })
          res.data[key]['log'] = [...top_log, ...other_log];

          // --- 公告 ---
          arr_announcement.sort(function(a, b) {
            let time_a = new Date(a.time);
            let time_b = new Date(b.time);
            return time_b.getTime() - time_a.getTime(); 
          })
          let top_announcement = [], other_announcement = [];
          arr_announcement.forEach(item => {
            if(item.top) {
              top_announcement.push(item)
            }else {
              other_announcement.push(item)
            }
          })
          res.data[key]['announcement'] = [...top_announcement, ...other_announcement];

          // --- 活动 ---
          arr_activity.sort(function(a, b) {
            let time_a = new Date(a.time);
            let time_b = new Date(b.time);
            return time_b.getTime() - time_a.getTime(); 
          })
          let top_activity = [], other_activity = [];
          arr_activity.forEach(item => {
            if(item.top) {
              top_activity.push(item)
            }else {
              other_activity.push(item)
            }
          })
          res.data[key]['activity'] = [...top_activity, ...other_activity];
        })

        this.newsData = res.data;
      }
    },
    moreNews(tab) {
      this.subPage[tab] += 1;
    },
    toTop() {
      window.scrollTo(0, 0)
    },
    initNav() {
      let navs = [
        { id: "home", name: "HOME", fromTop: 0, link: '/' },
        { id: "news", name: "NEWS", fromTop: 0, link: '/news/list' }
      ]
      this.navOptions['navs'] = navs;
    }
    // switchPage(num) {
    //   this.subPage = num
    // }
  }
}
</script>

<style lang="scss" scoped>
#news-list-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #071D3F;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include section_bg_single("overseas_pc_bg");
    width: 100vw;
    height: 100vh;
  }

  ::v-deep .decorate-box .decorate1 {
    top: 0;
  }

  .wrapper {
    position: relative;
    width: 2200px * 0.5;
    margin: 110px auto;
    > .title {
      margin-bottom: 20px * 0.5;
      font-size: 120px * 0.5;
      color: #fff;
      font-weight: bolder;
    }
    .news-list {
      min-height: 1480px * 0.5;
      background: rgba(20, 72, 129, 0.5);
      border: 4px * 0.5 solid #59AEE7;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 50px * 0.5;
      .news-tab {
        width: 2100px * 0.5;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .nav-box {
          width: 100%;
          height: 181px * 0.5;
          border-bottom: 2px * 0.5 solid #2C77C9;
          margin-bottom: 12px * 0.5;
          overflow-x: auto;
          overflow-y: hidden;
        }
        .tab-nav {
          display: flex;
          align-items: center;
          height: 100%;
          color: #fff;
          font-weight: bold;
          font-size: 36px * 0.5;
          margin-left: 73px * 0.5;
          li {
            position: relative;
            min-width: 244px * 0.5;
            height: 78px * 0.5;
            padding: 0 20px * 0.5;
            margin-right: 60px * 0.5;
            border: 4px * 0.5 solid #FFEE35;
            color: #FFEE35;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            &:last-child {
              margin: 0;
            }

            &.active {
              color: #252525;
              background-color: #FFC221;
            }
          }
        }
        .article-list {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          li {
            height: 114px * 0.5;
            border-bottom: 2px * 0.5 solid #2C77C9;
            padding: 0 73px * 0.5;
            -webkit-tap-highlight-color: transparent;
            // &:last-child {
            //   border: none;
            // }
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .title {
                font-size: 32px * 0.5;
                color: #fff;
                transition: opacity ease-in-out 200ms;
                width: 800px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &.isTop {
                  color: #ff4242;
                  font-weight: bolder;
                }
              }
              .time {
                font-size: 28px * 0.5;
                color: #59AEE7;
              }

              &:hover {
                .title {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
      // .paging-container {
      //   margin-bottom: 47px * 0.5;
      // }
      .more-box {
        height: 78px * 0.5;
        margin-top: 32px * 0.5;
        display: flex;
        justify-content: center;
        .more {
          display: inline-block;
          padding: 0 40px * 0.5;
          min-width: 190px * 0.5;
          height: 78px * 0.5;
          line-height: 78px * 0.5;
          white-space: nowrap;
          border: 2px * 0.5 solid #59AEE7;
          color: #59AEE7;
          font-size: 28px * 0.5;
          text-align: center;
          cursor: pointer;
        }
      }
      .toTop {
        position: absolute;
        width: 88px * 0.5;
        height: 88px * 0.5;
        border: 4px * 0.5 solid #59AEE7;
        border-top: none;
        bottom: -92px * 0.5;
        right: 0;
        background: rgba(20, 72, 129, 0.5);
        cursor: pointer;
        .icon {
          font-size: 38px;
          margin: 6px 0 0 7px;
          color: #59AEE7;
        }
      }
    }
  }
}

@function vwMiddle($px) {
  @return calc($px / 2240px) * 100vw;
};

@media screen and (max-width: 1120px) {
  #news-list-container {
    .wrapper {
      width: vwMiddle(2200px);
      margin: vwMiddle(200px) auto;
      > .title {
        margin-bottom: vwMiddle(20px);
        font-size: vwMiddle(120px);
      }
      .news-list {
        min-height: vwMiddle(1480px);
        border: vwMiddle(4px) solid #59AEE7;
        padding-bottom: vwMiddle(50px);
        .news-tab {
          width: vwMiddle(2100px);
          .nav-box {
            height: vwMiddle(181px);
            border-bottom: vwMiddle(2px) solid #2C77C9;
            margin-bottom: vwMiddle(12px);
          }
          .tab-nav {
            font-size: vwMiddle(36px);
            margin-left: vwMiddle(73px);
            li {
              min-width: vwMiddle(244px);
              height: vwMiddle(78px);
              padding: 0 vwMiddle(20px);
              margin-right: vwMiddle(60px);
              border: vwMiddle(4px) solid #FFEE35;
            }
          }
          .article-list {
            li {
              height: vwMiddle(114px);
              border-bottom: vwMiddle(2px) solid #2C77C9;
              padding: 0 vwMiddle(73px);
              a {
                .title {
                  font-size: vwMiddle(32px);
                  width: 70vw;
                }
                .time {
                  font-size: vwMiddle(28px);
                }
              }
            }
          }
        }
        .more-box {
          height: vwMiddle(78px);
          margin-top: vwMiddle(32px);
          .more {
            padding: 0 vwMiddle(40px);
            min-width: vwMiddle(190px);
            height: vwMiddle(78px);
            line-height: vwMiddle(78px);
            border: vwMiddle(2px) solid #59AEE7;
            font-size: vwMiddle(28px);
          }
        }
        .toTop {
          width: vwMiddle(88px);
          height: vwMiddle(88px);
          border: vwMiddle(4px) solid #59AEE7;
          bottom: vwMiddle(-92px);
          .icon {
            font-size: 3vw;
            margin: 0.7vw 0 0 0.8vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  #news-list-container {
    .bg {
      @include section_bg_single("overseas_mobile_bg");
      width: 100vw;
      height: 100vh;
    }

    .wrapper {
      width: vwApp(720px);
      margin: vwApp(138px) auto vwApp(100px);
      > .title {
        margin-bottom: vwApp(34px);
        margin-left: vwApp(40px);
        font-size: vwApp(60px);
      }
      .news-list {
        position: relative;
        min-height: vwApp(1000px);
        // border: none;
        border: vwApp(2px) solid #44799d;
        padding-bottom: vwApp(143px);
        .news-tab {
          width: vwApp(650px);
          .nav-box {
            height: auto;
            border-bottom: vwApp(2px) solid #2C77C9;
            margin-bottom: vwApp(7px);
            overflow: hidden;
          }
          .tab-nav {
            justify-content: flex-start;
            flex-wrap: wrap;
            font-size: vwApp(32px);
            margin-left: 0;
            padding: vwApp(33px) 0 vwApp(7px);
            li {
              min-width: vwApp(120px);
              height: vwApp(68px);
              padding: 0 vwApp(20px);
              margin-right: vwApp(24px);
              margin-bottom: vwApp(24px);
              border: vwApp(2px) solid #FFEE35;
              &:last-child {
                margin-right: vwApp(24px);
                margin-bottom: vwApp(24px);
              }
            }
          }
          .article-list {
            li {
              height: vwApp(85px);
              border-bottom: vwApp(2px) solid #2C77C9;
              padding: 0;
              a {
                .title {
                  font-size: vwApp(24px);
                  width: 60vw;
                }
                .time {
                  font-size: vwApp(20px);
                }
              }
            }
          }
        }
        .more-box {
          height: vwApp(78px);
          margin-top: vwApp(59px);
          .more {
            padding: 0;
            min-width: 0;
            width: vwApp(650px);
            height: vwApp(78px);
            line-height: vwApp(78px);
            border: vwApp(2px) solid #59AEE7;
            font-size: vwApp(28px);
          }
        }
        .toTop {
          width: vwApp(60px);
          height: vwApp(60px);
          border: vwApp(2px) solid #44799d;
          bottom: vwApp(-64px);
          right: vwApp(-2px);
          .icon {
            font-size: 5vw;
            margin: 2vw 0 0 2vw;
          }
        }
      }
    }
  }
}
</style>