<template>
  <listen-distance-from-top nav="features" :class="`in-${screen}`">
    <component
      v-if="thisComponent && showElementDelay"
      v-bind:is="thisComponent"
      :features="$lg(data_detail, 'features', [])"
      :showElement="showElementDelay"
    ></component>
  </listen-distance-from-top>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ListenDistanceFromTop from "@/components/listen-distance-from-top.vue";

export default {
  name: "SectionFeatures",
  components: {
    ListenDistanceFromTop,
    BlockPc: () => import("./features/features-pc"),
    BlockApp: () => import("./features/features-app"),
  },
  data() {
    return {
      thisComponent: "",
      screen: "",
      inViewed: false,
      showElementDelay: false
    };
  },
  watch: {
    data_size_listen: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.$lg(newVal, "screenWidth", 0) > 750) {
            this.screen = "pc";
            this.thisComponent = "BlockPc";
          } else {
            this.screen = "app";
            this.thisComponent = "BlockApp";
          }
        }
      },
      immediate: true,
      deep: true,
    },
    showElement: {
      handler(newVal, oldVal) {
        if(newVal && newVal != oldVal) {
          setTimeout(() => {
            this.showElementDelay = true;
          }, 600)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState([
      "data_detail",
      "data_scrollTop_listen",
      "data_size_listen",
      "navs"
    ]),
    showElement() {
      let thisNav = this.navs.find(item => item.id == 'features');
      if(thisNav['fromTop'] == 0) return false;

      let inView = thisNav.fromTop < this.data_scrollTop_listen + this.data_size_listen.screenHeight;
      if(inView) {
        this.inViewed = true
      }

      return (inView || this.inViewed);
    },
  },
};
</script>

<style lang="scss" scoped>
.in-pc {
  height: vwPc(1080px, $ratio: 1);
}
.in-app {
  height: vwApp(1400px);
}
</style>
