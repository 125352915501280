<template>
  <div class="section-roles section-5">
    <div v-if="heroes && heroes.length != 0" class="hero-introduce">
      <div class="bg"></div>
      <div
        class="hero-container"
        v-for="hero in heroes"
        :key="hero.name"
        :class="[hero.name]"
        v-show="hero.name == subHeroName"
      >
        <div class="name-white img-box">
          <img v-lazy="hero.name_bg" />
        </div>
        <div class="bg-role">
          <div
            class="specialty-box specialty-roleBgBox img-box"
            v-for="(item, key) in $lg(hero, 'specialty', [])"
            :key="key"
          >
            <img v-show="item.id == $lg(hero, 'subHeroID', '')" v-lazy="$lg(item, 'lie_bg', '')">
          </div>
        </div>
        <div class="role">
          <div
            class="specialty-box specialty-roleBox"
            v-for="(item, key) in $lg(hero, 'specialty', [])"
            :key="key"
          >
            <div v-show="item.id == $lg(hero, 'subHeroID', '')" class="specialty-role" :class="item.id">
              <div v-if="$lg(item, 'spine', '')" class="role-spine" :id="$lg(item, 'spine.appendEleID', '')"></div>
              <div v-else class="vertical img-box">
                <img v-lazy="$lg(item, 'lie', '')">
              </div>
            </div>
          </div>
        </div>
        <div class="name-box">
          <div class="name-en">{{ $t(hero.name) }}</div>
          <div class="line img-box">
            <img v-lazy="'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/line.png'" alt="">
          </div>
          <div class="name-ch">{{ $t(`${hero.name}_before`) }}</div>
        </div>
        <div class="skill">
          <div class="video-box">
            <div class="video">
              <xd-video
                 v-for="(item, i) in $lg(hero, 'specialty', [])"
                :key="i"
                :name="`video_${item.id}`"
                class="video-autoplay"
                :ref="`video_${$lg(item, 'id', '')}`"
                :options="{
                  controls: false,
                  loop: true,
                  autoplay: false,
                  video: $lg(item, 'video', '')
                }"
                v-show="item.id == $lg(hero, 'subHeroID', '')"
              />
            </div>
            <img class="video-frame" v-lazy="'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/video_frame.png'">
          </div>
          <div class="skill-container">
            <div
              v-for="(item, key) in $lg(hero, 'specialty', [])"
              :key="key"
              class="specialty-box specialty-skillBox"
              :class="{ active: item.id == $lg(hero, 'subHeroID', '') }"
              @click="switchHero(subHeroName, item.id)"
            >
              <div class="icon-box">
                <div class="icon img-box">
                  <img v-lazy="$lg(item, 'icon', '')" />
                </div>
                <img
                  class="default"
                  v-lazy="'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/skill_btn.png'"
                />
                <img
                  class="active"
                  v-lazy="'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/pc/section5/skill_active_btn.png'"
                />
              </div>
              <p>{{ $t(`${$lg(item, 'skill', '')}`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hero-switch">
      <div class="icon-left img-box" @click="prevHero">
        <img v-lazy="'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/icon-left.png'">
      </div>
      <div class="hero-switch-container">
        <ul
          class="btns"
          :style="{
            width: `${15.57292 * heroes.length}vw`,
            transform: `translate3d(${switchStep['step'] * switchStep['stepWidth']}vw, 0, 0)`
          }"
        >
          <li
            v-for="(hero, i) in heroes"
            :key="i"
            :name="hero.name"
            :class="{ active: subHeroName == hero.name }"
            @click="countGa(hero.name)"
          >
            <div
              v-for="(item, key) in $lg(hero, 'specialty', [])"
              :key="key"
              class="specialty-box specialty-switchBox"
            >
              <div v-show="item.id == $lg(hero, 'subHeroID', '')" class="img-box">
                <img class="default" v-lazy="$lg(item, 'switch_btn_default', '')"/>
                <img class="active" v-lazy="$lg(item, 'switch_btn_active', '')"/>
              </div>
            </div>
            <div class="btn" @click="switchHero(hero.name, $lg(hero, 'subHeroID', ''), i)"></div>
          </li>
        </ul>
      </div>
      <div class="icon-right img-box" @click="nextHero">
        <img v-lazy="'https://website.xdcdn.net/homepage/torchlight/imgs/pc/hero/icon-right.png'">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import createSpine from "@/assets/libs/spine/index";
import * as initAnime from "@/assets/libs/initAnimePc";
import XdVideo from "@/components/xdvideo.vue";

// let url_pre = "https://poster.xdcdn.net/xd-website/homepage/torchlight";

export default {
  props: {
    heroes: {
      type: Array,
      default: () => [],
    },
    subHeroName: {
      type: String,
      default: () => "",
    },
    subHeroID: {
      type: String,
      default: () => "",
    }
  },
  components: { XdVideo },
  data() {
    return {
      spineController: {},
      videoList: {},
      switchStep: {
        start: 2,
        length: 0,
        total: 5,
        step: 0,
        stepWidth: -13.7
      },
      switchTimer: '',
      inSwitch: false
      // subSkill: "erikaSkill_1",
    };
  },
  computed: {
    ...mapState(["data_detail", "data_scrollTop_listen", "navs"]),
    subHeroSpecialty() {
      if(!this.heroes) {
        return {};
      }else {
        let subHero = this.heroes.find(item => item.name == this.subHeroName);
        return subHero.specialty.find(item => item.id == this.subHeroID);
      }
    }
  },
  watch: {
    heroes: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal.length != 0) {
          this.$nextTick(() => {
            this.initTransitions();
            // this.initSpine();
          });
        }
      },
      immediate: true,
      deep: true,
    },
    data_scrollTop_listen(scrollTop) {
      const roleObj = this.navs.find((nav) => nav.id == "roles");
      const fromTop = this.$lg(roleObj, "fromTop", 0);
      if (fromTop && scrollTop > fromTop - window.innerHeight * 0.5) {
        this.playVideo();
      }
    },
    subHeroID: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal && newVal != oldVal) {
          this.$nextTick(() => {
            this.playVideo();
            this.playSpine(this.subHeroSpecialty);
          })
        }
      }
    },
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.initVideo();
  //   });
  // },
  methods: {
    // initVideo() {
    //   this.$refs[`video_${this.subHeroName}`][0].play();
    // },
    initTransitions() {
      let transitions = {};
      this.heroes.forEach((hero) => {
        transitions[hero.name] = initAnime.introduce('.section-roles', hero);
      });

      // 初始化完成，播放默认英雄动画
      transitions[this.subHeroName] && transitions[this.subHeroName].restart();
      this.$emit("init-transitions-end", transitions);
    },
    playVideo() {
      this.$refs[`video_${this.subHeroID}`][0].play();
    },
    playSpine(hero) {
      let spineControllerKeys = Object.keys(this.spineController);
      // 每次切换spine, 都要暂停其他spine，释放缓存
      spineControllerKeys.forEach(key => {
        this.spineController[key].stop();
      })

      if(spineControllerKeys.includes(hero.id)) {
        this.spineController[key].start();
      }else {
        // 如果有spine的配置，则初始化
        if(hero.spine) {
          this.initSpine(hero);
        }
      }
    },
    initSpine(hero) {
      if(!hero.spine) return;

      createSpine(hero.spine, (spine) => {
        this.$nextTick(() => {
          document.getElementById(hero.spine.appendEleID).appendChild(spine.view);
          this.spineController[hero.id] = spine;

          if (this.subHeroID == hero.id) {
            spine.start();
          }
        })
      });
    },
    switchHero(name, id='', i='') {
      if(!this.inSwitch) {
        this.inSwitch = true;

        this.$emit('switch-hero', { name, id })
        if(typeof i == 'number') {
          this.handleStep(i+1);
        };

        this.switchTimer = setTimeout(() => {
          this.inSwitch = false;
        }, 2000)
      }
    },
    prevHero() {
      let thisHero = this.heroes.find(item => item.name == this.subHeroName);
      let thisIndex = this.heroes.indexOf(thisHero);
      if(thisIndex >= 1) {
        let prevIndex = thisIndex - 1;
        let prevHero = this.heroes[prevIndex];
        this.switchHero(prevHero['name'], prevHero['subHeroID'], prevIndex);
      }
    },
    nextHero() {
      let thisHero = this.heroes.find(item => item.name == this.subHeroName);
      let thisIndex = this.heroes.indexOf(thisHero);
      if(thisIndex < this.heroes.length - 1) {
        let nextIndex = thisIndex + 1;
        let nextHero = this.heroes[nextIndex];
        this.switchHero(nextHero['name'], nextHero['subHeroID'], nextIndex);
      }
    },
    handleStep(i) {
      if(!this.switchStep['length']) {
        this.switchStep['length'] = this.heroes.length
      }

      let index_start = i - this.switchStep['start'];
      if(index_start > 0) {
        let length_total = this.switchStep['length'] - this.switchStep['total'];
        if(index_start <= length_total) {
          this.switchStep['step'] = index_start;
        }else {
          this.switchStep['step'] = length_total;
        }
      }else {
        this.switchStep['step'] = 0;
      }
    },
    countGa() {},
  },
};
</script>


<style lang="scss" scoped>
.img-box {
  display: flex;
  justify-content: center;
  img {
    // width: 100%;
    height: 100%;
  }
}

.section-roles {
  position: relative;
  overflow: hidden;
  height: vwPc(1057px, $ratio: 1);
  .hero-introduce {
    position: relative;
    width: vwPc(1920px, $ratio: 1);
    height: vwPc(970px, $ratio: 1);
    margin: 0 auto;

    .hero-container {
      position: relative;
      width: 100%;
      height: 100%;
      .name-white {
        height: 16.14583vw;
        position: absolute;
        top: vwPc(13px, $ratio: 1);
        left: vwPc(132px, $ratio: 1);
      }
      .bg-role {
        transform-origin: right bottom;
        width: 59.6875vw;
        height: 75.41667vw;
        position: absolute;
        top: vwPc(-93px, $ratio: 1);
        right: vwPc(-55px, $ratio: 1);
        .specialty-box {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
      .role {
        position: absolute;
        bottom: -1px;
        width: vwPc(1400px, $ratio: 1);
        height: vwPc(970px, $ratio: 1);
        transform-origin: center bottom;
        .specialty-box {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .specialty-role {
            width: 100%;
            height: 100%;
          }
        }
        canvas {
          width: 100% !important;
          height: 100% !important;
        }
        .role-spine {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        .vertical {
          width: 100%;
          height: 100%;
        }
      }
      .decorate-line {
        &[name="1"] {
          width: 16.35417vw;
          height: 27.96875vw;
          position: absolute;
          top: 0px;
          left: vwPc(235px, $ratio: 1);

          &.animete {
            animation: decorate_line1 linear 4s infinite;
          }

          @keyframes decorate_line1 {
            from {
              transform: translate(16vw, -27.71281vw);
            }
            to {
              transform: translate(-34vw, 58.88973vw);
            }
          }
        }
        &[name="2"] {
          width: 5.83333vw;
          height: 9.73958vw;
          position: absolute;
          top: 0px;
          left: vwPc(300px, $ratio: 1);

          &.animete {
            animation: decorate_line2 linear 6s infinite;
          }

          @keyframes decorate_line2 {
            from {
              transform: translate(-5vw, 8.66025vw);
            }
            to {
              transform: translate(30vw, -51.96152vw);
            }
          }
        }
      }
      .name-box {
        display: inline-block;
        position: absolute;
        top: vwPc(345px, $ratio: 1);
        right: vwPc(15px, $ratio: 1);
        display: flex;
        flex-direction: column;
        align-items: self-end;
        .name-en {
          font-size: vwPc(312px);
          color: #fff;
          font-weight: 700;
        }
        .line {
          width: 24.01042vw;
          height: 0.78125vw;
          margin: 0 0 vwPc(15px, $ratio: 1);
        }
        .name-ch {
          font-size: vwPc(74px);
          color: #fff;
          font-weight: 700;
          float: right;
          margin-right: vwPc(10px, $ratio: 1);
        }
      }
      .skill {
        position: absolute;
        top: vwPc(340px, $ratio: 1);
        left: vwPc(132px, $ratio: 1);
        .video-box {
          position: relative;
          width: 33.54167vw;
          height: 22.96875vw;
          .video {
            width: vwPc(613px, $ratio: 1);
            height: vwPc(342px, $ratio: 1);
            position: absolute;
            top: vwPc(47px, $ratio: 1);
            left: vwPc(10px, $ratio: 1);
            clip-path: polygon(
              0% 25%,
              8% 0%,
              100% 0%,
              100% 89%,
              97% 100%,
              0% 100%
            );
            background-color: black;
            padding-top: 0;
            .video-autoplay {
              width: 100%;
              height: 100%;
              padding: 0;
            }
          }
          .video-frame {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .skill-container {
          margin: 0 auto;
          padding-top: vwPc(20px, $ratio: 1);
          overflow: hidden;
          display: flex;
          justify-content: center;
          .specialty-box {
            width: vwPc(107px, $ratio: 1);
            margin: 0 vwPc(20px);
            -webkit-tap-highlight-color: transparent;
            .icon-box {
              position: relative;
              width: vwPc(80px, $ratio: 1);
              height: vwPc(80px, $ratio: 1);
              cursor: pointer;
              margin: 0 auto;
              >.default {
                width: 4.21875vw;
                height: 4.21875vw;
                position: absolute;
                top: vwPc(-10px, $ratio: 1);
                left: 0px;
                transition: all ease-in-out 200ms;
              }
              >.active {
                width: 4.32292vw;
                height: 4.53125vw;
                position: absolute;
                top: vwPc(-16px, $ratio: 1);
                left: 0px;
                opacity: 0;
                transition: all ease-in-out 200ms;
              }

              .icon {
                width: 3.125vw;
                height: 3.17708vw;
                position: absolute;
                left: 50%;
                margin-left: vwPc(-30.5px, $ratio: 1);
                .default {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                // .active {
                //   width: 100%;
                //   height: 100%;
                //   position: absolute;
                //   top: 0;
                //   left: 0;
                //   opacity: 0;
                //   transition: all ease-in-out 200ms;
                // }
              }
            }
            p {
              text-align: center;
              color: #fff;
              font-size: vwPc(14px, $ratio: 1);
            }

            &.active {
              .icon-box {
                .active {
                  opacity: 1;
                }
                .default {
                  opacity: 0;
                }
              }
            }

            // &:hover {
            //   .icon {
            //     .active {
            //       opacity: 1;
            //     }
            //   }
            // }
          }
        }
      }

      &.rehan {
        .role {
          width: vwPc(1300px, $ratio: 1);
          height: vwPc(970px, $ratio: 1);
          left: vwPc(450px, $ratio: 1);
        }
        &.rehan_1 {
          .bg-role {
            width: 56.875vw;
            height: 60.72917vw;
            // position: absolute;
            top: vwPc(-93px, $ratio: 1);
            right: 0px;
          }
        }
        .rehan_2 {
          .role-spine {
            left: vwPc(-80px, $ratio: 1);
          }
        }
      }
      &.carino {
        .role {
          width: vwPc(1600px, $ratio: 1);
          height: vwPc(970px, $ratio: 1);
          left: vwPc(-60px, $ratio: 1);
          .carino_2 {
            margin-left: vwPc(300px, $ratio: 1);
            ::v-deep {
              canvas {
                margin-left: 25.5%;
              }
            }
          }
        }
      }
      &.gemma {
        .role {
          left: vwPc(400px, $ratio: 1);
        }
      }
      &.moto {
        .role {
          left: vwPc(400px, $ratio: 1);
        }
        .bg-role {
          position: absolute;
          top: vwPc(-93px, $ratio: 1);
          right: vwPc(-55px, $ratio: 1);
        }
      }
      &.youga {
        .role {
          left: vwPc(400px, $ratio: 1);
        }
      }
      &.thea,
      &.erika {
        .role {
          left: vwPc(360px, $ratio: 1);
        }
      }
    }
  }

  .hero-switch {
    position: relative;
    height: get_vw(102px);
    top: get_vw(-14px);
    background: linear-gradient(180deg, rgba(37, 37, 37, 0) 0%, #252525 90%);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-left {
      width: vwPc(61.7px);
      height: vwPc(68px);
      cursor: pointer;
    }
    .icon-right {
      width: vwPc(61.7px);
      height: vwPc(68px);
      cursor: pointer;
    }
    .hero-switch-container {
      position: relative;
      width: vwPc(2706px);
      height: 100%;
      margin: 0 vwPc(36px);
      overflow: hidden;
      clip-path: polygon(3.5% 0%, 101% 0%, 96.5% 100%, -1% 100%);
      // -13.7vw
      .btns {
        display: flex;
        margin: 0 auto;
        padding: 0 get_vw(17px);
        margin-top: vwPc(33px);
        transition: all ease-in-out 100ms;

        li {
          position: relative;
          // float: left;
          cursor: pointer;
          width: get_vw(299px);
          height: get_vw(70px);
          -webkit-tap-highlight-color: transparent;
          .specialty-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .img-box {
              width: 100%;
              height: 100%;
            }
          }

          .default, .active {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .active {
            opacity: 0;
          }
          margin: 0 get_vw(-17.6px);

          .btn {
            position: relative;
            width: get_vw(240px);
            height: 100%;
            margin: 0 auto;
          }

          &.active {
            opacity: 1;
            .active {
              opacity: 1;
            }
          }

          transition: all ease-in-out 300ms;
          &:hover {
            transform: translate3d(get_vw(4.5px), get_vw(-7px), 0);
          }
        }
      }
    }
  }
}
</style>