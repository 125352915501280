<template>
  <div id="main">
    <the-header @signOut="signOut" />
    <the-decorate />
    <section-banner />
    <section-news />
    <section-features />
    <section-roles />
    <!-- <section-register /> -->
    <the-footerTW v-if="region == 'TW' || $i18n.locale == 'zh-tw'" />
    <the-footer v-else />
    <!-- <modal-guide /> -->
    <!-- <modal-loading-init /> -->
    <!-- <modal-progress /> -->
    <modal-video />
    <modal-register ref="google" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getRegion } from "@/utils/getLocale";

import TheHeader from "@/components/the-header.vue";
import TheFooter from "@/components/the-footer.vue";
import TheFooterTW from "@/components/the-footer-tw.vue";
import TheDecorate from "@/components/the-decorate.vue";

import SectionBanner from "@/views/overseas/sections/banner.vue";
import SectionNews from "@/views/overseas/sections/news.vue";
// import SectionIntroduce from "@/views/overseas/sections/introduce.vue";
import SectionFeatures from "@/views/overseas/sections/features.vue";
import SectionRoles from "@/views/overseas/sections/roles.vue";
// import SectionRegister from "@/views/overseas/sections/register.vue";

// import ModalLoadingInit from "@/components/modal-loading-init.vue";
// import ModalProgress from "@/components/modal-progress.vue";
import ModalRegister from "@/views/overseas/loginRegister.vue";
import ModalVideo from "@/components/modal-video.vue";
import languages from '@/utils/languages';

export default {
  components: {
    TheHeader,
    TheFooter,
    TheFooterTW,
    TheDecorate,
    SectionBanner,
    SectionNews,
    // SectionIntroduce,
    SectionFeatures,
    SectionRoles,
    // SectionRegister,
    // ModalLoadingInit,
    // ModalProgress,
    ModalRegister,
    ModalVideo,
    ModalGuide: () => import("@/components/modal-guide.vue"),
  },
  metaInfo() {
    let message = this.$i18n.messages[this.$i18n.locale];
    // console.log(message);
    return {
      title: message.title,
      titleTemplate: "%s - XD",
      htmlAttrs: {
        lang: this.filterLang()
      },
      meta: [
        {
          vmid: "lang",
          name: "lang",
          content: languages[this.$i18n.locale]?.code
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: message.keywords
        },
        {
          vmid: "description",
          name: "description",
          content: message.description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: message.title
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: message.title
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: message.description
        },
        {
          vmid: "twitter:title",
          property: "twitter:title",
          content: message.title
        },
        {
          vmid: "twitter:description",
          property: "twitter:description",
          content: message.description
        }
      ]
    };
  },
  data() {
    return {
      xdVideoAuth: {},
      videoList: [],
      reserveLink: "https://www.taptap.io/app/227017",
      region: getRegion(),
    };
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));

    this.initLoading();
    this.$nextTick(() => {
      // this.initVideo();
    });
  },
  async created() {
    await this.getCount({ app_id: 227017 });
  },
  methods: {
    ...mapMutations(["setInfo"]),
    ...mapActions(["getSubCountry", "getMsgEn", "getCount"]),
    initLoading() {
      setTimeout(() => {
        this.setInfo({
          key: "loading",
          val: {
            progress: 100,
            finished: true,
          },
        });
      }, 3000);
      // new ImagePreload("picture-count", (progress) => {
      //   // this.loading.progress = progress;
      //   this.setInfo({
      //     key: "loading",
      //     val: {
      //       progress: progress,
      //       finished: false,
      //     },
      //   });
      //   if (progress == 100) {
      //     // this.loading.finished = true;
      //     this.setInfo({
      //       key: "loading",
      //       val: {
      //         progress: 100,
      //         finished: true,
      //       },
      //     });

      //     window.scrollTo({
      //       top: 0,
      //     });
      //   }
      // });
    },
    signOut() {
      this.$refs.google.signOut();
    },
    filterLang() {
      let lang = this.$i18n.locale;
      let langList = lang.split('-');
      if(langList.length > 1) {
        lang = `${langList[0]}-${langList[1].toUpperCase()}`
      }
      return lang;
    }
  },
};
</script>

<style lang="scss" scoped>
body,
html {
  background-color: #2367ae;
  // font-family: "Montserrat-ExtraBoldItalic";
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    "PingFang SC", "Noto Sans", Roboto, "Microsoft Yahei", sans-serif,
    Apple Color Emoji, Segoe UI Emoji;
}

#main {
  position: relative;
  overflow: hidden;
  // font-family: "Montserrat-ExtraBoldItalic";
  min-height: 2000px;
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    @include section_bg_single("overseas_pc_bg");
    width: 100vw;
    height: 100vh;
  }
}


@media screen and (max-width: 750px) {
#main {
  &::before {
    @include section_bg_single("overseas_mobile_bg");
    width: 100vw;
    height: 100vh;
  }
}
}
</style>
