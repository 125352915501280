<template>
  <listen-distance-from-top nav="home" class="section-banner">
    <block-register site="main" />
    <!-- 黑色边框 -->
    <div class="frame"></div>
    <!-- 新赛季 -->
    <div class="ep2-logo" :style="{'background-image': `url(${$t('ep2_logo')})`}">
      <a :href="getHref()" target="_blank"></a>
    </div>
    <div
      class="player"
      @click="openVideoModal"
    ></div>
    <!-- 主按钮 -->
    <transition name="fade">
      <div class="reserve">
        <btn-entry type="main"></btn-entry>
        <!-- <div class="btn-gift" @click="openProgressModal"></div> -->
      </div>
    </transition>
    <div v-if="mainBtn.type == 'register'" class="tip">
      {{ $t("tip_comming") }}
    </div>
    <div v-if="mainBtn.type == 'download'" class="tip">
      {{ $t("tip_ep2_live") }}
    </div>
    <!-- <div class="btn-gift" @click="openProgressModal"></div> -->
    <!-- 平台列表 -->
    <list-platform
      class="platforms"
      :platform-list="platformList"
      type="solid"
    ></list-platform>
  </listen-distance-from-top>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import ListenDistanceFromTop from "@/components/listen-distance-from-top.vue";
import BlockRegister from "@/components/block-register.vue";
import ListPlatform from "@/components/list-platform.vue";
import BtnEntry from "@/components/btn-entry.vue";

export default {
  name: "SectionBanner",
  components: {
    ListenDistanceFromTop,
    BlockRegister,
    ListPlatform,
    BtnEntry,
  },
  data() {
    return {
      fullScreen: false,
      videoList: [],
    };
  },
  computed: {
    ...mapState([
      "loading",
      "data_detail",
      "guideShow",
      "navs",
      "data_size_listen",
      "seasonMap"
    ]),
    ...mapGetters(["platformList", "mainBtn"]),
  },
  methods: {
    ...mapMutations(["toggleModal"]),
    ...mapActions(["createModalVideo"]),
    showVideo() {
      this.fullScreen = true;
      this.videoList["mainVideo"].muted(false);

      this.countGa("mainVideo");
    },
    hideVideo() {
      this.fullScreen = false;
      this.videoList["mainVideo"].muted(true);
    },
    openProgressModal() {
      this.toggleModal({
        key: "modal_progress_show",
        bool: true,
      });
    },
    openVideoModal() {
      let video = this.$t("video_banner");
      let options = {
        autoplay: false,
        video: video,
      };
      if (this.data_size_listen.screenWidth > 750) {
        options.tip = "pc_banner";
      }

      this.createModalVideo(options);
    },
    getHref() {
      const preLink = 'https://torchlight.xd.com';
      const lang = this.$i18n.locale;
      return `${preLink}${this.seasonMap[lang] || this.seasonMap['en']}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.section-banner {
  position: relative;
  width: vwPc(1920px, $ratio: 1);
  height: vwPc(1080px, $ratio: 1);

  .roles-list {
    width: 100%;
    height: 100%;
    .role {
      @include bgSet;
      position: absolute;
      &[name="youga"] {
        @include bg("overseas_add2022_role_youga");
        top: vwPc(361px);
        left: vwPc(-70px);
        z-index: 1;
      }
      &[name="carino"] {
        @include bg("overseas_add2022_role_carino");
        top: vwPc(239px);
        right: vwPc(628px);
      }
      &[name="gemma"] {
        @include bg("overseas_add2022_role_gemma");
        top: vwPc(673px);
        right: vwPc(1142px);
      }
      &[name="m"] {
        @include bg("overseas_add2022_role_m");
        top: vwPc(480px);
        right: vwPc(230px);
      }
      &[name="moto"] {
        @include bg("overseas_add2022_role_moto");
        bottom: vwPc(21px);
        left: vwPc(182px);
      }
      &[name="rehan"] {
        @include bg("overseas_add2022_role_rehan");
        top: vwPc(15px);
        left: vwPc(-25px);
      }
    }

    &::after {
      content: "";
      @include bg("overseas_add2022_bg_gradient");
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .frame {
    @include bg("overseas_add2022_frame");
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    // @include fadeOpacity;
  }

  .player {
    @include bg("overseas_add2022_btn_player");
    position: absolute;
    top: vwPc(950px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    cursor: pointer;
  }

  .ep2-logo {
    position: absolute;
    top: vwPc(980px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: vwPc(1834px);
    height: vwPc(736px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .reserve {
    position: absolute;
    left: 50%;
    bottom: vwPc(500px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 1;

    .btn-gift {
      @include bg("overseas_add2022_icon_gift");
      position: absolute;
      right: vwPc(-111px);
      top: vwPc(7px);
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .tip {
    width: 90%;
    height: vwPc(145px);
    position: absolute;
    bottom: vwPc(310px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-size: vwPc(100px);
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  .platforms {
    bottom: vwPc(140px);
  }

  // &.fullScreen {
  //   z-index: 10;
  //   .banner {
  //     position: fixed;
  //     top: 0;
  //     z-index: 1;
  //     width: 100vw;
  //     height: 100vh;
  //     .close {
  //       display: block;
  //     }
  //   }
  // }
}

@media screen and (max-width: 750px) {
  .section-banner {
    width: 100vw;
    height: 216.53333vw;

    .frame {
      @include bg("mobile_section1_font_bg");
    }
    .player {
      width: vwApp(150px);
      height: vwApp(150px);
      top: vwApp(720px);
    }
    .reserve {
      bottom: 37.5vw;

      .btn-gift {
        display: none;
      }
    }

    .ep2-logo {
      top: 102vw;
      width: vwApp(970px);
      height: vwApp(389px);
    }

    .tip {
      width: 80%;
      height: vwApp(144px);
      bottom: vwApp(412px);
      font-size: vwApp(50px);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .btn-gift {
      display: block;
      position: absolute;
      @include bg("overseas_add2022_icon_gift_2x");
      width: vwApp(99px);
      height: vwApp(99px);
      right: vwApp(50px);
      bottom: vwApp(60px);
      top: unset;
      z-index: 1;
    }

    .platforms {
      display: none;
    }
  }
}
</style>