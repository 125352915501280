<template>
  <div
    v-if="Object.keys(platformList).length != 0"
    class="platform-list"
    :class="[`icon-${type}`, { 'filter-gray': gray }]"
  >
    <div
      class="platform"
      v-for="(link, name) in platformList"
      :key="name"
      :name="name"
      :style="{ 'background-image': `url(${$t(`platform_${stage}_${name}`)})` }"
    >
      <!-- <div v-if="type == 'solid'" class="title">
        <template v-if="stage == 'register'">
          {{ $t("btn_register") }}
        </template>
        <template v-if="stage == 'download'">
          {{ $t("btn_download") }}
        </template>
      </div> -->
      <a v-if="link" :href="link" target="_blank" @click="operate(name)"></a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { os } from "@/assets/libs/os.js";

export default {
  name: "ListPlatform",
  props: {
    type: {
      type: String,
      default: () => "opacity",
    },
    platformList: {
      type: Object,
      default: () => {},
    },
    gray: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      storeMap: {
        store: "AppAtore",
        google: "googlePlay",
        taptap: "TapTap",
      },
    };
  },
  computed: {
    ...mapState(["stage"]),
  },
  methods: {
    operate(name) {
      if (name == "store" && os.isWeChart) {
        alert(this.$t("tip_WeChart"));
      } else {
        this.countGa(this.storeMap[name]);
      }
    },
    countGa(type) {
      window.ga("send", "event", "torchlight", "click", type);
    },
  },
};
</script>

<style lang="scss" scoped>
.platform-list {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  .platform {
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      opacity: 0.85;
    }
  }

  &.icon-opacity {
    .platform {
      margin: 0 vwPc(50px);
      &[name="store"] {
        @include bg("overseas_add2022_icon_store_opacity");
      }
      &[name="google"] {
        @include bg("overseas_add2022_icon_google_opacity");
      }
      &[name="taptap"] {
        @include bg("overseas_add2022_icon_taptap_opacity");
      }
      &[name="steam"] {
        @include bg("overseas_add2022_icon_steam_opacity");
      }
      &[name="pc"] {
        @include bg("overseas_add2022_icon_pc_opacity");
      }
    }
  }

  &.icon-solid {
    .platform {
      margin: 0 vwPc(10px);
      width: vwPc(466px) !important;
      height: vwPc(148px) !important;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      // &[name="store"] {
      //   @include bg("overseas_add2022_icon_store_solid_2x");
      // }
      // &[name="google"] {
      //   @include bg("overseas_add2022_icon_google_solid_2x");
      // }
      // &[name="taptap"] {
      //   @include bg("overseas_add2022_icon_taptap_solid_2x");
      // }
      // &[name="steam"] {
      //   @include bg("overseas_add2022_icon_steam_solid_2x");
      // }

      // .title {
      //   // font-size: 9px;
      //   // top: 13.5px;
      //   // left: 60px;
      //   font-size: vwPc(18px);
      //   top: vwPc(23px);
      //   left: vwPc(170px);
      //   color: #fff;
      //   position: absolute;
      //   white-space: nowrap;
      //   letter-spacing: 0;
      //   transform-origin: center left;
      //   @media screen and (max-width: 1100px) {
      //     transform: scale(0.6);
      //   }
      //   @media screen and (max-width: 750px) {
      //     transform: scale(0.8);
      //   }
      // }
    }
  }

  &.filter-gray {
    filter: grayscale(1);
    opacity: 0.8;
  }
}

@media screen and (max-width: 750px) {
  .platform-list {
    flex-direction: column;
    &.icon-solid {
      .platform {
        margin: vwApp(10px) 0;
        width: vwApp(356px) !important;
        height: vwApp(130px) !important;
        .title {
          font-size: vwApp(18px);
          top: vwApp(27.5px);
          left: vwApp(120px);
        }
      }
    }
  }
}
</style>