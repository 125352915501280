<template>
  <div ref="xdvideo" class="web-video-xd" :data-options="optionsStr" />
</template>

<script>
export default {
  data() {
    return {
      pid: "",
      player: null,
      optionsStr: {},
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          controls: true,
          loop: false,
          autoplay: true,
          poster: "",
          video: "",
          preload: 'none'
        };
      },
    },
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal && Object.keys(newVal).length != 0) {
          let {
            controls = true,
            loop = false,
            autoplay = true,
            poster,
            video,
          } = newVal;

          let _options = {
            controls,
            loop,
            poster,
            muted: !controls || autoplay,
            autoplay,
            scrollAutoPlay: autoplay,
            playsinline: true,
            preload: 'none',
            sources: [{ label: "HD", src: video, type: "video/mp4" }],
          };

          this.optionsStr = JSON.stringify(_options);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (!window.__XDVIDEO__) {
      (function (w, d, t, r) {
        w["__WEB_VIDEO_OBJECT__"] = r;
        w[r] =
          w[r] ||
          function () {
            (w[r].cmd = w[r].cmd || []).push(arguments);
          };
        var n = d.createElement(t),
          m = d.getElementsByTagName('head')[0];
        var j = d.createElement("link");
        j.rel = "stylesheet";
        j.type = "text/css";
        j.href = "https://res.xdcdn.net/video/xdvideo-player.css";
        m.insertBefore(j, null);
        n.async = 1;
        n.src = "https://res.xdcdn.net/video/xdvideo-player.min.js";
        m.insertBefore(n, null);
      })(window, document, "script", "__XDVIDEO__");
    }
    window.__XDVIDEO__("register", {
      pick: this.$refs.xdvideo,
      afterCreate: this.handleAfterCreate,
      onError: (e) => {},
    });
  },
  methods: {
    handleAfterCreate(videojs) {
      let pid = videojs.getPid(this.$refs.xdvideo);
      if (pid === this.pid) return;
      this.pid = pid;
      this.player = videojs.getPlayer(pid);

      this.$emit("after-create", this.player, this.options.name);
    },
    play() {
      if (this.player && this.player.paused()) {
        this.player.play();
      }
    },
    pause() {
      if (this.player && !this.player.paused()) {
        this.player.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-video {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .web-video-xd {
    height: 100%;
    background: transparent;
    padding: 0;
  }
  .vjs-poster {
    background-size: cover;
  }
}

::v-deep .video-js {
  background-color: transparent;
  .vjs-modal-dialog {
    background: transparent;
  }
}

::v-deep .vjs-error-display {
  display: none;
}
</style>
