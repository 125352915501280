<template>
  <listen-distance-from-top nav="features" class="section-features">
    <div class="title">
      <img v-lazy="'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7QaizJ7I.png'" alt="游戏特色">
    </div>
    <div class="swiper-container features-swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, key) in features"
          :key="key"
        >
          <img class="img" v-lazy="item.img" :alt="item.des"/>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </listen-distance-from-top>
</template>

<script>
import Swiper from "swiper";
import { mapState } from 'vuex';
import ListenDistanceFromTop from "@/components/listen-distance-from-top.vue";

export default {
  components: {
    ListenDistanceFromTop
  },
  data() {
    return {
      imgSwiper: '',
      featureList: [
        {
          img_pc: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7fRtWktT.png',
          img_mobile: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7fRL74QP.png',
          des: '个性鲜明的英雄设计 经典角色已重新激活'
        },
        {
          img_pc: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7fRtWFbt.png',
          img_mobile: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7fRL7f54.png',
          des: '无体力无限制 尽情打宝无限掉落'
        },
        {
          img_pc: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7fRtWNAH.png',
          img_mobile: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7fRL7YVx.png',
          des: '地下城的刺激冒险 输出无CD的爽快战斗'
        },
        {
          img_pc: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7fRtWxjA.png',
          img_mobile: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7fRL7Cqn.png',
          des: '完全自由的交易行 让你的掉宝更有价值'
        },
        {
          img_pc: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7fRtWf4v.png',
          img_mobile: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7fRL7VPj.png',
          des: '硬核BD玩法 250+技能带来无限流派可能'
        },
        {
          img_pc: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7fRtWlSt.png',
          img_mobile: 'https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7fRL7UjO.png',
          des: '新内容不断的赛季制玩法 美季都有新体验'
        }
      ]
    }
  },
  computed: {
    ...mapState(['data_size_listen']),
    features() {
      let arr = [];
      if(this.data_size_listen.screenWidth >= 750) {
        arr = this.featureList.map(item => {
          return { img: item.img_pc, des: item.des }
        })
      }else {
        arr = this.featureList.map(item => {
          return { img: item.img_mobile, des: item.des }
        })
      }

      return arr;
    }
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      // let self = this
      this.imgSwiper = new Swiper(".swiper-container.features-swiper", {
        // effect: "fade",
        autoplay: true,
        loop: false,
        slideToClickedSlide: true,
        centeredSlides: true,
        pagination: {
          el: ".section-features .swiper-pagination",
          clickable: true,
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-features {
  display: flex;
  position: relative;
  width: 100%;
  height: 56.25vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .title {
    position: absolute;
    width: 17%;
    height: 6%;
    left: 41.5%;
    top: 11.28%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .swiper-container {
    width: vwPc(2600px);
    height: vwPc(1609px);
    position: relative;
    .swiper-slide {
      .img {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  ::v-deep .swiper-pagination {
    bottom: 5.5vw;
    .swiper-pagination-bullet {
      width: 2.5vw;
      height: 0.625vw;
      opacity: 1;
      cursor: pointer;
      background-color: #fff;
      margin: 0 0.6vw;
      border-radius: 0;
      &.swiper-pagination-bullet-active {
        background-color: #FFC221;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .section-features {
    height: 105.6vw;
    .title {
      width: 42.53%;
      height: 8.33%;
      left: 28.8%;
      top: 12.63%;
    }
    .swiper-container {
      width: 100vw;
      height: vwApp(465px);
      // overflow: visible;
    }

    ::v-deep .swiper-pagination {
      bottom: 16vw;
      .swiper-pagination-bullet {
        width: 6.4vw;
        height: 1.6vw;
        margin: 0 1.2vw;
      }
    }
  }
}
</style>