<template>
  <div id="main">
    <the-header-demestic>
      <template v-slot:nav>
        <list-nav :options="navOptions" />
      </template>
    </the-header-demestic>
    <the-decorate />
    <section-banner />
    <!-- <section-news /> -->
    <section-roles />
    <section-features />
    <modal-video />
    <modal-limit />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getRegion } from "@/utils/getLocale";

import TheHeaderDemestic from "@/components/domestic/the-header.vue";
import TheDecorate from "@/components/the-decorate.vue";
import SectionBanner from "@/views/domestic/sections/banner.vue";
import SectionNews from "@/views/domestic/sections/news.vue";
import SectionFeatures from "@/views/domestic/sections/features.vue";
import SectionRoles from "@/views/overseas/sections/roles.vue";
import ModalVideo from "@/components/modal-video.vue";
import ModalLimit from "@/components/domestic/modal-limit.vue";
import languages from '@/utils/languages';
import ListNav from "@/components/domestic/list-nav.vue";

export default {
  components: {
    TheHeaderDemestic,
    TheDecorate,
    SectionBanner,
    SectionNews,
    SectionFeatures,
    SectionRoles,
    ModalVideo,
    ModalLimit,
    ModalGuide: () => import("@/components/modal-guide.vue"),
    ListNav
  },
  metaInfo() {
    let message = this.$i18n.messages[this.$i18n.locale];
    // console.log(message);
    return {
      title: message.title,
      titleTemplate: "%s - 心动",
      htmlAttrs: {
        lang: this.filterLang()
      },
      meta: [
        {
          vmid: "lang",
          name: "lang",
          content: languages[this.$i18n.locale]?.code
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: message.keywords
        },
        {
          vmid: "description",
          name: "description",
          content: message.description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "《火炬之光：无限》官方网站"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: message.title
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: message.description
        },
        {
          vmid: "twitter:title",
          property: "twitter:title",
          content: message.title
        },
        {
          vmid: "twitter:description",
          property: "twitter:description",
          content: message.description
        }
      ]
    };
  },
  data() {
    return {
      xdVideoAuth: {},
      videoList: [],
      reserveLink: "https://www.taptap.io/app/227017",
      region: getRegion(),
      navOptions: {
        navs: [],
        listen: true,
        defaultID: ''
      }
    };
  },
  computed: {
    ...mapState(["navs"])
  },
  watch: {
    navs: {
      handler(newVal, oldVal) {
        if(newVal && newVal.length != 0) {
          this.initNav(newVal)
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  methods: {
    ...mapMutations(["setInfo"]),
    signOut() {
      this.$refs.google.signOut();
    },
    filterLang() {
      let lang = this.$i18n.locale;
      let langList = lang.split('-');
      if(langList.length > 1) {
        lang = `${langList[0]}-${langList[1].toUpperCase()}`
      }
      return lang;
    },
    initNav(navs) {
      let temp_navs = [];
      temp_navs.push(navs.find(item => item.id == 'home'));
      temp_navs.push(navs.find(item => item.id == 'news'));
      temp_navs.push(navs.find(item => item.id == 'roles'));
      temp_navs.push(navs.find(item => item.id == 'features'));
      this.navOptions['navs'] = temp_navs;
    }
  },
};
</script>

<style lang="scss" scoped>
#main {
  position: relative;
  overflow: hidden;
  // font-family: "Montserrat-ExtraBoldItalic";
  // min-height: 2000px;
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    @include section_bg_single("overseas_pc_bg");
    width: 100vw;
    height: 100vh;
  }
}


@media screen and (max-width: 750px) {
  #main {
    &::before {
      @include section_bg_single("overseas_mobile_bg");
      width: 100vw;
      height: 100vh;
    }
    ::v-deep .section-features {
      display: flex;
      align-items: center;
    }
  }
}
</style>
