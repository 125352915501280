<template>
  <div class="block-register" :name="site">
    <div class="roles-list">
      <div class="kv"></div>
      <xd-video
        class="banner-video"
        :options="initVideoOptions"
        @after-create="handleCreated"
      />
    </div>
  </div>
</template>

<script>
import XdVideo from "@/components/xdvideo.vue";

export default {
  name: "BlockRegister",
  components: { XdVideo },
  props: {
    site: {
      type: String,
      default: () => "main", // main bottom
    },
  },
  data() {
    return {
      roleList: ["m", "carino", "gemma", "rehan", "moto", "youga"],
      initVideoOptions: {
        controls: false,
        loop: true,
        autoplay: true,
        video:
          "https://website.xdcdn.net/poster/227017/epicycle/ep-2/video_bg.mp4",
      },
    };
  },
  methods: {
    handleCreated(player) {
      window["banner_video"] = player;
    },
  },
};
</script>


<style lang="scss" scoped>
.block-register {
  position: relative;
  width: 100%;
  height: 100%;

  .roles-list {
    width: 100%;
    height: 100%;
    .kv {
      @include bg("overseas_obt2022_kv");
    }

    .banner-video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 0;
    }
    ::v-deep .web-video-xd {
      background-color: transparent;
    }

    &::after {
      content: "";
      @include bg("overseas_add2022_bg_gradient");
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

@media screen and (max-width: 750px) {
  .block-register {
    .roles-list {
      // .role {
      //   &[name="youga"] {
      //     width: vwApp(571px);
      //     height: vwApp(624px);
      //     top: vwApp(296px);
      //     left: vwApp(-53px);
      //     z-index: 0;
      //   }
      //   &[name="carino"] {
      //     width: vwApp(376px);
      //     height: vwApp(229px);
      //     bottom: vwApp(33px);
      //     right: vwApp(25px);
      //     top: unset;
      //   }
      //   &[name="gemma"] {
      //     width: vwApp(459px);
      //     height: vwApp(357px);
      //     bottom: 0;
      //     left: 0;
      //     top: unset;
      //   }
      //   &[name="m"] {
      //     width: vwApp(717px);
      //     height: vwApp(463px);
      //     top: vwApp(30px);
      //     right: vwApp(-96px);
      //   }
      //   &[name="moto"] {
      //     width: vwApp(524px);
      //     height: vwApp(434px);
      //     bottom: vwApp(-22px);
      //     left: vwApp(-126px);
      //   }
      //   &[name="rehan"] {
      //     width: vwApp(606px);
      //     height: vwApp(421px);
      //     top: vwApp(37px);
      //     left: vwApp(-130px);
      //   }
      // }
      .kv {
        @include bg("overseas_obt2022_kv_mobile");
      }

      .banner-video {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
      }

      &::after {
        display: none;
      }
    }
  }
}
</style>