<template>
  <ui-modal :show="showModal">
    <div class="guide-box">
      <div class="guide-inner">
        <div class="guide" name="1">
          <span name="1">Welcome</span>
          <span name="2">Hunters</span>
          <span name="3">,</span>
        </div>
        <div class="guide" name="2">
          <span name="1">the</span>
          <span name="2">closed</span>
          <span name="3">beta</span>
          <span name="4">is</span>
          <span name="5">now</span>
          <span name="6">live</span>
          <span name="7">!</span>
        </div>
        <btn-entry class="guide-btn" location="main"></btn-entry>
      </div>
      <div class="close" @click="closeGuide">✕</div>
    </div>
  </ui-modal>
</template>

<script>
import { mapMutations } from "vuex";
import anime from "animejs";
import UiModal from "@/components/ui-modal.vue";
import BtnEntry from "@/components/btn-entry.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "ModalGuide",
  components: {
    BtnEntry,
    UiModal,
  },
  data() {
    return {
      guideAnime: null,
    };
  },
  computed: {
    ...mapState(["loading", "guideShow"]),
    showModal() {
      return this.loading.finished && this.guideShow;
    },
  },
  mounted() {
    this.initAnime();
    this.$nextTick(() => {
      setTimeout(() => {
        this.guideAnime.restart();
      }, 500);
    });
  },
  methods: {
    ...mapMutations(["setGuideShow"]),
    initAnime() {
      this.guideAnime = anime.timeline({
        autoplay: false,
        easing: "easeInOutSine",
        duration: 300,
      });
      this.guideAnime.add({
        targets: ".guide-box .guide span",
        translateY: ["0.9vw", "-0.2vw", 0],
        opacity: [0, 1, 1],
        delay: anime.stagger(60),
      });
      this.guideAnime.add({
        targets: ".guide-btn",
        opacity: [0, 1],
      });
      this.guideAnime.add({
        targets: ".guide-btn",
        opacity: [1, 0],
        translateY: [0, -20],
        delay: 4000,
        complete: () => {
          this.setGuideShow(false);
        },
      });
    },
    closeGuide() {
      this.setGuideShow(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.guide-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 2.4vw;
  color: #fff;
  z-index: 60;
  .guide-inner {
    position: absolute;
    top: 42%;
  }
  .guide {
    // position: absolute;
    margin-left: 32vw;
    span {
      display: inline-block;
      margin-right: 0.8vw;
      transform: translateY(0.8vw);
      opacity: 0;
    }
    &[name="2"] {
      margin-top: 0.2vw;
    }
  }
  .guide-btn {
    display: inline-block;
    margin: 4vw 0 0 41vw;
    opacity: 0;
  }
  .close {
    font-size: 24px;
    color: gray;
    position: absolute;
    top: 1vw;
    right: 1vw;
    cursor: pointer;
  }
}

@media screen and (max-width: 750px) {
  .guide-box {
    font-size: 5vw;
    .guide {
      margin-left: 12vw;
      &[name="2"] {
        margin-top: 2vw;
      }
    }
    .guide-btn {
      margin: 10vw 0 0 8vw;
    }
    .close {
      top: 3vw;
      right: 3vw;
    }
  }
}
</style>

