<template>
  <div class="footer-view">
    <!-- 媒体列表 -->
    <!-- <ul class="footer-media">
      <li
        v-for="(media, key) in medias"
        :key="key"
        :name="key"
        :style="{
          'background-image': `url(${media.icon})`,
        }"
      >
        <a :href="media.link" target="_blank"> </a>
      </li>
    </ul> -->
    <list-media class="footer-media" site="in-footer"></list-media>
    <!-- logo -->
    <div class="logo-box">
      <div class="xd"></div>
      <!-- <div class="so_funny"></div> -->
      <div class="stellar"></div>
    </div>
    <div class="age-box">
      <div class="age"></div>
      <div class="warning" v-html="warning"></div>
    </div>
    <!-- 用户协议/隐私协议 -->
    <div class="copyright" v-html="copyright"></div>
    <div class="footer-row txt">
      <a :href="userAgreementUrl" target="_blank" class="txt">
        {{ userAgreement }}
      </a>
      &
      <a :href="privacyPolicyUrl" target="_blank" class="txt">
        {{ privacyPolicy }}
      </a>
    </div>
  </div>
</template>
<script>
import ListMedia from "@/components/list-media.vue";

export default {
  name: "TheFooterTW",
  components: { ListMedia },
  data() {
    return {
      // medias: {
      //   Facebook: {
      //     link: "https://www.facebook.com/TorchlightInfiniteTW",
      //     icon: "https://website.xdcdn.net/remote-components/icons/facebook.svg",
      //   },
      //   YouTube: {
      //     link: "https://www.youtube.com/channel/UCGX0oaqa8NUCWHAyDncuUaQ",
      //     icon: "https://website.xdcdn.net/remote-components/icons/youtube.svg",
      //   },
      //   BaHaMute: {
      //     link: "https://forum.gamer.com.tw/A.php?bsn=74756",
      //     icon: "https://website.xdcdn.net/remote-components/icons/bahamute.svg",
      //   },
      // },
      userAgreement: "服務條款",
      userAgreementUrl: "https://protocol.xd.com/tw/agreement.html",
      privacyPolicy: "隱私條款",
      privacyPolicyUrl: "https://protocol.xd.com/tw/privacy.html",
      copyright: `版權宣告：copyright © 2023 Star Force co.,Ltd. All rights reserved
        聯繫客服：torchlight_cs@starforce.tw`,
      warning: `本遊戲涉及暴力(打鬥畫面)，依遊戲軟體分級管理辦法分類為輔12級。

        本遊戲為免費使用，遊戲內另提供購買虛擬貨幣、物品等付費服務。
        
        請注意遊戲時間，避免沈迷。`,
    };
  },
  created() {
    let thisYear = new Date().getFullYear();
    this.copyright = `版權宣告：copyright © ${thisYear} Star Force co.,Ltd. All rights reserved
        聯繫客服：torchlight_cs@starforce.tw`;
  }
};
</script>

<style lang="scss" scoped>
$pcAutoLayout: "only screen and (min-width: 1200px)";
$pcTransformLayout: "only screen and (max-width: 1199px)";
$footerBgColor: #2f2f2f;
$mediaIconBgColor: rgb(195, 191, 178);
$fontColor: #878787;

@mixin bg_base($url: auto) {
  background-image: url($url);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.footer-view {
  width: 100%;
  background: $footerBgColor;
  position: relative;
  height: 371px;
  overflow: hidden;
  @media #{$pcTransformLayout} {
    height: 452px;
  }
  .footer-media {
    @media #{$pcAutoLayout} {
      position: absolute;
      bottom: 221px;
      left: 75px;
    }
    @media #{$pcTransformLayout} {
      margin: 0 auto;
      margin-top: 50px;
    }
    transform-origin: bottom left;
    // width: 240px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // li {
    //   width: 40px;
    //   background-color: $mediaIconBgColor;
    //   height: 40px;
    //   border-radius: 50%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   margin-right: 10px;
    //   a {
    //     width: 100%;
    //     height: 100%;
    //   }

    //   &:last-child {
    //     margin-right: 0;
    //   }
    // }
  }
  .logo-box {
    display: flex;
    align-items: center;
    @media #{$pcAutoLayout} {
      justify-content: center;
      position: absolute;
      right: 75px;
      bottom: 225px;
    }
    @media #{$pcTransformLayout} {
      width: 296px;
      justify-content: center;
      margin: 0 auto;
      border-top: 1px solid #858585;
      margin-top: 32px;
      padding-top: 20px;
    }
    .xd {
      @include bg_base(
        "https://website.xdcdn.net/remote-components/icons/xd.svg"
      );
      @media #{$pcAutoLayout} {
        width: 132px;
        height: 55px;
        margin-right: 14px;
      }
      @media #{$pcTransformLayout} {
        width: 80px;
        height: 33px;
        margin-right: 8px;
      }
    }
    .so_funny {
      @include bg_base(
        "https://website.xdcdn.net/remote-components/icons/so_funny.svg"
      );
      @media #{$pcAutoLayout} {
        width: 132px;
        height: 55px;
        margin-right: 14px;
      }
      @media #{$pcTransformLayout} {
        width: 80px;
        height: 33px;
        margin-right: 8px;
      }
    }
    .stellar {
      @include bg_base(
        "https://website.xdcdn.net/remote-components/icons/stellar.svg"
      );
      width: 196px;
      height: 55px;
      @media #{$pcTransformLayout} {
        width: 119px;
        height: 33px;
      }
    }
  }
  .age-box {
    @media #{$pcTransformLayout} {
      width: 297px;
      height: 72.5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 37px;
    }
    .age {
      @include bg_base(
        "https://website.xdcdn.net/remote-components/icons/age.png"
      );
      @media #{$pcAutoLayout} {
        width: 90px;
        height: 90px;
        position: absolute;
        bottom: 100px;
        right: 474px;
      }
      @media #{$pcTransformLayout} {
        width: 72.5px;
        height: 72.5px;
      }
    }
    .warning {
      font-weight: 400;
      text-align: justify;
      white-space: pre-line;
      color: $fontColor;
      @media #{$pcAutoLayout} {
        font-size: 14px;
        width: 375px;
        position: absolute;
        bottom: 67px;
        right: 75px;
        line-height: 18px;
      }
      @media #{$pcTransformLayout} {
        font-size: 12px;
        line-height: 14px;
        width: 208px;
      }
    }
  }
  .copyright {
    font-weight: 400;
    white-space: pre-line;
    color: $fontColor;
    @media #{$pcAutoLayout} {
      font-size: 14px;
      line-height: 20px;

      position: absolute;
      bottom: 145px;
      left: 75px;
    }
    @media #{$pcTransformLayout} {
      margin: 0 auto;
      margin-top: 25px;
      font-size: 12px;
      line-height: 18px;
      width: 297px;
      text-align: justify;
    }
  }
  .footer-row {
    color: $fontColor;
    font-size: 14px;
    @media #{$pcAutoLayout} {
      font-weight: 400;
      line-height: 20px;
      position: absolute;
      bottom: 125px;
      left: 75px;
    }
    @media #{$pcTransformLayout} {
      margin: 0 auto;
      margin-top: 30px;
      width: 135px;
      line-height: 18px;
      display: flex;
      justify-content: space-between;
    }
    a {
      text-decoration: underline;
      color: $fontColor;
    }
  }
}
</style>