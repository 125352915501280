<template>
  <listen-distance-from-top nav="home" class="section-banner">
    <!-- <block-register site="main" /> -->
    <div class="roles-list">
      <div class="kv"></div>
      <xd-video
        class="banner-video"
        :options="initVideoOptions"
        @after-create="handleCreated"
      />
    </div>
    <!-- 黑色边框 -->
    <div class="frame"></div>
    <div class="limit" @click="openLimitModal"></div>
    <div class="slogan">
      <div class="player" @click="openVideoModal">
        <div class="p-frame"></div>
        <div class="p-icon"></div>
      </div>
    </div>
    <!-- 主按钮 -->
    <div class="reserve">
      <a :href="getReserveHref()" target="_blank" @click="operate">{{ $t("btn_register") }}</a>
      <div class="code-icon">
        <div class="qr-code"></div>
      </div>
    </div>
    <div class="tip">已有 <span>{{ amount }}</span> 位猎人加入火炬之光</div>
    <!-- 平台列表 -->
    <list-platform
      class="platforms"
      :platform-list="platformList_domestic"
      type="solid"
    ></list-platform>
    <div class="reward"></div>
  </listen-distance-from-top>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import * as api from '@/api/index';
import XdVideo from "@/components/xdvideo.vue";
import ListenDistanceFromTop from "@/components/listen-distance-from-top.vue";
// import BlockRegister from "@/components/block-register.vue";
import ListPlatform from "@/components/domestic/list-platform.vue";
import BtnEntry from "@/components/btn-entry.vue";
import UiButtonSlide from "@/components/ui-button-slide.vue";

export default {
  name: "SectionBanner",
  components: {
    XdVideo,
    ListenDistanceFromTop,
    // BlockRegister,
    ListPlatform,
    BtnEntry,
    UiButtonSlide
  },
  data() {
    return {
      fullScreen: false,
      videoList: [],
      initVideoOptions: {
        controls: false,
        loop: true,
        autoplay: true,
        video:
          "https://poster.xdcdn.net/xd-website/homepage/torchlight/videos/20230228-01.mp4",
      },
      amount: '-'
    };
  },
  computed: {
    ...mapState([
      "loading",
      "data_detail",
      "guideShow",
      "navs",
      "data_size_listen",
      "seasonMap"
    ]),
    ...mapGetters(["platformList_domestic", "mainBtn"]),
  },
  created() {
    this.reqDomesticReserveCount();
  },
  methods: {
    ...mapMutations(["toggleModal"]),
    ...mapActions(["createModalVideo"]),
    showVideo() {
      this.fullScreen = true;
      this.videoList["mainVideo"].muted(false);

      this.countGa("mainVideo");
    },
    hideVideo() {
      this.fullScreen = false;
      this.videoList["mainVideo"].muted(true);
    },
    openProgressModal() {
      this.toggleModal({
        key: "modal_progress_show",
        bool: true,
      });
    },
    openVideoModal() {
      let video = 'https://poster.xdcdn.net/xd-website/homepage/torchlight/videos/20220228-cg-01.mp4';
      let options = {
        autoplay: false,
        video: video,
      };
      if (this.data_size_listen.screenWidth > 750) {
        options.tip = "pc_banner";
      }

      this.createModalVideo(options);
    },
    openLimitModal() {
      this.toggleModal({
        key: "modal_limit_show",
        bool: true,
      });
    },
    getHref() {
      const preLink = 'https://torchlight.xd.com';
      const lang = this.$i18n.locale;
      return `${preLink}${this.seasonMap[lang] || this.seasonMap['en']}`;
    },
    handleCreated(player) {
      window["banner_video"] = player;
    },
    getReserveHref() {
      let os = this.$store.state.os;
      let href = ''
      if(os.isIpad || os.isIPhone || os.isMac) {
        href = 'https://apps.apple.com/cn/app/id1528917194';
      }else {
        href = 'https://l.tapdb.net/q3ze8FEZ';
      }

      return href;
    },
    reqDomesticReserveCount: async function() {
      let res = await api.getDomesticReserveCount({
        t: new Date().getTime()
      });
      if(res.status == 200) {
        this.amount = res.data.amount || '-'
      }
    },
    operate() {
      let os = this.$store.state.os;
      if((os.isIpad || os.isIPhone) && os.isWeChart) {
        alert(this.$t("tip_WeChart"));
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.section-banner {
  position: relative;
  width: vwPc(1920px, $ratio: 1);
  height: 100vh;

  .roles-list {
    position: relative;
    width: 100%;
    height: 100%;
    .kv {
      // @include bg("overseas_obt2022_kv");
      width: 100%;
      height: 100%;
      background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7ekPdbgu.jpg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .banner-video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 0;
      ::v-deep video {
        object-fit: cover;
      }
    }
    ::v-deep .web-video-xd {
      background-color: transparent;
    }

    &::after {
      content: "";
      @include bg("overseas_add2022_bg_gradient");
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .frame {
    @include bg("overseas_add2022_frame");
    height: 100%;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .limit {
    width: vwPc(175px);
    height: vwPc(228px);
    position: absolute;
    top: vwPc(250px);
    right: vwPc(211px);
    cursor: pointer;
    background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7VZq3hUv.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .slogan {
    position: absolute;
    bottom: vwPc(828px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: vwPc(1155px);
    height: vwPc(377px);
    background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7efojKtd.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .player {
      width: vwPc(196px);
      height: vwPc(196px);
      position: absolute;
      top: vwPc(3px);
      right: vwPc(-37px);
      cursor: pointer;
      @keyframes anime_1 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .p-frame {
        width: 100%;
        height: 100%;
        background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7TXAoE2d.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: anime_1 linear 10s infinite;
      }
      .p-icon {
        width: vwPc(64px);
        height: vwPc(74px);
        position: absolute;
        left: 38.09%;
        top: 31.43%;
        background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7QaizX0r.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  .reserve {
    width: vwPc(614px);
    height: vwPc(126px);
    position: absolute;
    left: 50%;
    bottom: vwPc(658px);
    transform: translate3d(-50%, 0, 0);
    background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/70DS3UtH.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    transition: all ease-in-out 200ms;
    &:hover {
      opacity: 0.85;
    }
    >a {
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: vwPc(130px);
      font-size: vwPc(58px);
      color: #222;
      font-weight: bolder;
      text-align: center
    }

    .code-icon {
      width: vwPc(96px);
      height: vwPc(96px);
      position: absolute;
      right: vwPc(-108px);
      top: vwPc(15px);
      cursor: pointer;
      background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7efojdsw.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &:hover {
        .qr-code {
          opacity: 1;
          visibility: visible;
        }
      }

      .qr-code {
        width: vwPc(243px);
        height: vwPc(274px);
        position: absolute;
        right: vwPc(-260px);
        top: vwPc(-45px);
        background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7ei16Xv4.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: vwPc(8px);
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out 200ms;
      }
    }
  }

  .tip {
    position: absolute;
    left: 0;
    bottom: vwPc(580px);
    width: 100%;
    font-size: vwPc(36px);
    color: #fff;
    text-align: center;
    span {
      font-size: vwPc(48px);
      color: #FFA21D;
    }
  }

  .platforms {
    bottom: vwPc(387px);
  }

  .reward {
    width: 100%;
    height: vwPc(382px);
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/2x/7ejT24ik.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 750px) {
  .section-banner {
    width: 100vw;

    .roles-list {
      .kv {
        background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7QhUWZgu.jpg');
        background-position: center 30%;
      }

      .banner-video {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
      }

      &::after {
        background-image: none;
        width: 100%;
        height: 65%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30.06%, #000000 100%);
      }
    }

    .frame {
      width: 100%;
      height: 100%;
      background-image: url(https://website.xdcdn.net/homepage/torchlight/imgs/mobile/section1/font_bg.png);
    }

    .limit {
      width: vwApp(85px);
      height: vwApp(110px);
      top: vwApp(117px);
      right: vwApp(43px);
      transition: all ease-in-out 200ms;
      &:hover {
        opacity: 0.85;
      }
    }

    .slogan {
      width: vwApp(657px);
      height: vwApp(252px);
      bottom: vwApp(515px);
      background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7enPo7o1.png');
      .player {
        width: vwApp(129px * 1.2);
        height: vwApp(129px * 1.2);
        top: 0vw;
        right: -2vw;
        -webkit-tap-highlight-color: transparent;
        .p-frame {
          background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7enPo9gP.png');
        }
        .p-icon {
          width: vwApp(42px * 1.2);
          height: vwApp(48px * 1.2);
          left: 40.09%;
          top: 35.43%;
          background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7enPoKh1.png');
        }
      }
    }

    .player {
      width: vwApp(150px);
      height: vwApp(150px);
      top: vwApp(720px);
    }
    .reserve {
      width: vwApp(461px);
      height: vwApp(106px);
      bottom: vwApp(417px);
      >a {
        line-height: vwApp(106px);
        font-size: vwApp(46px);
      }

      .code-icon {
        display: none;
      }
    }

    .tip {
      bottom: vwApp(370px);
      font-size: vwApp(28px);
      span {
        font-size: vwApp(32px);
      }
    }

    > .btn-gift {
      display: block;
      position: absolute;
      @include bg("overseas_add2022_icon_gift_2x");
      width: vwApp(99px);
      height: vwApp(99px);
      right: vwApp(50px);
      bottom: vwApp(60px);
      top: unset;
      z-index: 1;
    }

    .platforms {
      display: none;
    }

    .reward {
      height: vwApp(350px);
      background-image: url('https://website.xdcdn.net/poster/172664/deliver/2023_02_09/mobile/7emriuti.jpg');
    }
  }
}
</style>