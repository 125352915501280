var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"footerView",staticClass:"remoteConfigurableFooter_Simple",class:{
    'layout-normal': _vm.adaptiveType != 'rem',
    'layout-rem': _vm.layoutRem
  }},[(_vm.media && _vm.media.length != 0)?_c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"media-box"},_vm._l((_vm.media),function(item,key){return _c('a',{key:key,attrs:{"href":item.link || 'javascript:;',"target":item.link ? '_blank': '_self'}},[(item.icon)?_c('img',{style:({'height': item.height || '100%'}),attrs:{"src":item.icon,"alt":""}}):_c('span',{staticClass:"iconfont",class:item.media}),(item.media_qrCode)?_c('div',{staticClass:"media-popup"},[_c('img',{attrs:{"src":item.media_qrCode}})]):_vm._e()])}),0)]):_vm._e(),_c('div',{staticClass:"main-box"},[_c('div',{staticClass:"footer-row row-1"},[_c('div',{staticClass:"icon-box"},[_c('div',{staticClass:"footer-logo"},[_c('img',{attrs:{"src":"https://website.xdcdn.net/remote-components/icons/xd-sea.png","alt":""}}),(_vm.footer['logo_platform'] && _vm.footer['logo_platform'].length != 0)?_vm._l((_vm.footer['logo_platform']),function(item,i){return _c('img',{key:i,style:({height: item.height || '100%'}),attrs:{"src":item.icon}})}):_vm._e()],2)]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.copyright))])]),_c('div',{staticClass:"footer-row row-2"},[_vm._l((_vm.footer['list_link']),function(item,i){return [(true)?_c('a',{key:`a_${i}`,staticClass:"text",attrs:{"href":item.link ? item.link : 'javascript:;',"target":"_blank"}},[_vm._v(_vm._s(item.label))]):_vm._e(),(i != _vm.footer['list_link'].length - 1)?_c('span',{key:`line_${i}`,staticClass:"line"},[_vm._v("|")]):_vm._e()]})],2),(_vm.footer['icon_grading'] && _vm.footer['icon_grading']['pc'])?_c('img',{staticClass:"icon_grading in-pc",style:({ 
        height: _vm.footer['icon_grading']['pc_height'] || '56px',
        'max-height': _vm.footer['icon_grading']['pc_height'] || '56px'
      }),attrs:{"src":_vm.footer['icon_grading']['pc']}}):_vm._e(),(_vm.footer['icon_grading'] && _vm.footer['icon_grading']['app'])?_c('img',{staticClass:"icon_grading in-app",style:({ 
        height: _vm.footer['icon_grading']['app_height'] || '56px',
        'max-height': _vm.footer['icon_grading']['app_height'] || '56px'
      }),attrs:{"src":_vm.footer['icon_grading']['app']}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }